import {
  Box,
  Text,
  Button,
  Image,
  Card,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Link,
  Container,
  Wrap,
  Grid,
  Flex
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import images from "../assets/image-export-assets";
import { SpecialHeading } from "../components/individualTabs/PanelLayout";
import { EnterpriseCard } from "../components/Plancards/EntrepriseCard";
import { PaygoCard } from "../components/Plancards/PaygoCard";
import { URLS } from "../constans";
import { lawyerSelectors } from "../redux/lawyer/selectors";
import { FreemiumCard } from "../components/Plancards/FreemiumCard";
import { UpgradeRequestCard } from "./UpgradeRequestCard";

export const PlanPage = () => {
  const { subscription } = useSelector(lawyerSelectors.selectAll);
  const isFreemium = subscription?.status === "freemium";
  const isRequested = subscription?.request ?? false;
  return (
    <Container p={6} rounded="md">
      {/* Subscription */}
      <SpecialHeading title="Subscription" />

      {isRequested && <UpgradeRequestCard />}

      <Card
        display="flex"
        alignItems="center"
        justifyContent="center"
        className="h-80 relative"
        bgColor="text.display.light"
      >
        <Image
          src={images.frame}
          className="absolute left-0 top-[50%] translate-y-[-50%]"
        />
        <Image
          src={images.frame}
          className="absolute right-0 top-[50%] translate-y-[-50%]"
        />
        <Box className="w-96">
          <div className="flex flex-col items-center justify-center">
            <div className="flex   justify-around  items-center gap-2 relative  ">
              <Image src={images.premiumIcon} h={12} w={12} />
              <Text color="white" className="text-2xl">
                {isFreemium ? "Upgrade to Premium" : "Premium Account"}
              </Text>
            </div>
            {isFreemium && (
              <div className="">
                <Text color="white" fontWeight="400" mt={1}>
                  Sign up to learn more and gain access to <br /> CaseBlink
                  premium.
                </Text>
                <Link href={URLS.joinListForm} isExternal>
                  <Button
                    variant="primaryFilled"
                    mt={4}
                    className="animate-bounce hover:animate-smoothRevert "
                  >
                    Request Access
                  </Button>
                </Link>
              </div>
            )}
          </div>
        </Box>
      </Card>

      {/* Plan */}
      <section>
        <SpecialHeading title="Plan" />

        <Flex gap={4}>
          <FreemiumCard />
          <PaygoCard />
          <EnterpriseCard />
        </Flex>
      </section>
      {/* Subscription details */}
      {/* <section>
        <SpecialHeading title="Subscription details" />
        <TableContainer className="rounded-md opacity-50">
          <Table>
            <Thead>
              <Tr>
                <Th>Current plan</Th>
                <Th>upgraded cases this month</Th>
                <Th>Rate</Th>
                <Th>Total</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Pay as you go</Td>
                <Td>5</Td>
                <Td>
                  $ 300 <br />{" "}
                  <span className="text-gray-500 text-xs">
                    (discount bracket: 4-7 cases)
                  </span>
                </Td>
                <Td>$ 1500</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </section> */}
    </Container>
  );
};
