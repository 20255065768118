import { EditIcon, SettingsIcon } from "@chakra-ui/icons";
import {
  Box,
  Card,
  Container,
  Divider,
  Flex,
  Heading,
  IconButton,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from "@chakra-ui/react";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PremiumModal } from "../components/common/PremiumModal";
import TabNavigation from "../components/common/TabsNavigation";
import { CaseResearch } from "../components/individualTabs/CaseResearch";
import { EvidenceDocs } from "../components/individualTabs/EvidenceDocs";
import { IndividualDrafts } from "../components/individualTabs/IndividualDrafts";
import { Packet } from "../components/individualTabs/IndividualPacket";
import { PanelLayout } from "../components/individualTabs/PanelLayout";
import { StandardDocs } from "../components/individualTabs/StandardDocs";
import { EvidenceQuestions } from "../components/individualTabs/individualQuestionnaires/EvidenceQuestions";
import { getUserType } from "../helpers/helpers";
import { ClientsReducer } from "../redux/clients/clientsSlice";
import { clientsSelectors } from "../redux/clients/selectors";
import { lawyerSelectors } from "../redux/lawyer/selectors";
import { visaFromPath } from "../types/tables-data";
import { DocumentsInfoModal } from "../components/common/DocumentsInfoModal";
import { IndividualEditBox } from "../components/common/IndividualEditBox";
import { SectionWrapper } from "../components/common/SectionWrapper";
import { individualSelectors } from "../redux/individual/selectors";
import { useGetIndividualData } from "../hooks/useGetIndividualData";
import { shouldAccess } from "../helpers/firebase_helpers";

const IndividualCase = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, visaType } = useParams();

  const [currentMainTabIndex, setCurrentMainTabIndex] = useState(0);
  const [currentSubTabIndex, setCurrentSubTabIndex] = useState(0);
  const type = getUserType();
  const activeCase = useSelector((state: ClientsReducer) =>
    clientsSelectors.selectClientById(state, id ?? "")
  );
  const fullName = `${activeCase?.firstName} ${activeCase?.lastName}`;
  let mainTabsWithSubtabs: MainTab[] = [];

  interface Subtab {
    label: string;
    tabLabel?: string;
    content: React.ReactNode;
    asTab?: boolean;
  }

  interface MainTab {
    label: string;
    tabLabel?: string;
    subtabs?: Subtab[];
    content: React.ReactNode;
    asTab?: boolean;
    withInfo?: React.ReactNode;
  }
  let loginType = localStorage.getItem("loginType");
  let visa: string;
  if (visaType) visa = visaFromPath[visaType];

  const {
    onSubmitGetIndividualData,
    isLoadingGetIndividualData,
    individualData
  } = useGetIndividualData();

  const [premiumModalOpen, setPremiumModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  function getMainTabsWithSubtabs(loginType: string | null): MainTab[] {
    type ExcludesFalse = <T>(x: T | false) => x is T;

    if (!visaType) return [];
    const mainTabsWithSubtabs = [
      shouldAccess(individualData, loginType, "documents") && {
        tabLabel: "Documents",
        label: "Documents",
        subtabs: [
          {
            tabLabel: "Standard documents",
            label: "Standard documents",
            content: <StandardDocs />,
            asTab: true,
            withInfo: <DocumentsInfoModal title="Standard documents" />
          },
          {
            tabLabel: "Evidence documents",
            label: "Evidence documents",
            content: <EvidenceDocs />,
            asTab: true,
            withInfo: <DocumentsInfoModal title="Evidence documents" />
          }
        ],
        content: <div>Documents Content</div>
      },
      shouldAccess(individualData, loginType, "questionnaires") && {
        tabLabel: "Questionnaires",
        label: "Questionnaires",
        content: <EvidenceQuestions />,
        withInfo: <DocumentsInfoModal title="Questionnaires" />
      },
      shouldAccess(individualData, loginType, "case_research") && {
        tabLabel: "Case research",
        label: "Case research",
        content: <CaseResearch />,
        withInfo: <DocumentsInfoModal title="Case Research" />
      },
      shouldAccess(individualData, loginType, "drafts") && {
        tabLabel: "Drafts",
        label: "Drafts",
        content: <IndividualDrafts />,
        asTab: false
      },
      shouldAccess(individualData, loginType, "packet") && {
        label: "",
        tabLabel: "Packet",
        content: <Packet />
      }
    ]
      .filter(Boolean as any as ExcludesFalse)
      .map((e) => {
        const subTabsCopy = Object.freeze(e.subtabs);
        return {
          ...e,
          content: (
            <PanelLayout
              title={e.label}
              asTab={e?.asTab ?? true}
              withInfo={e?.withInfo}
            >
              {e.content}
            </PanelLayout>
          ),
          subtabs: subTabsCopy?.map((s) => {
            return {
              ...s,
              content: (
                <PanelLayout
                  title={s.tabLabel}
                  asTab={s?.asTab ?? true}
                  withInfo={s?.withInfo}
                >
                  {s.content}
                </PanelLayout>
              )
            };
          })
        };
      });

    return mainTabsWithSubtabs;
  }

  const labelToPath = (label: string) =>
    label.toLowerCase().replace(/\s+/g, "-");

  const resetScroll = () => {
    window.scrollTo(0, 0);
  };

  if (id && !isLoadingGetIndividualData && !loading) {
    mainTabsWithSubtabs = getMainTabsWithSubtabs(loginType);
  }

  useEffect(() => {
    if (id) {
      onSubmitGetIndividualData(id);
    }
  }, []);

  useEffect(() => {
    loginType = localStorage.getItem("loginType");

    if (id) {
      mainTabsWithSubtabs = getMainTabsWithSubtabs(loginType);

      resetScroll();
      const pathSegments = location.pathname
        .split("/")
        .filter((segment) => segment !== "");
      const mainTabLabel = pathSegments[3] || "";
      const subTabLabel = pathSegments[4] || "";

      const foundMainTabIndex = mainTabsWithSubtabs.findIndex(
        (tab) => labelToPath(tab.tabLabel!) === mainTabLabel
      );

      if (foundMainTabIndex !== -1) {
        setCurrentMainTabIndex(foundMainTabIndex);

        const mainTab = mainTabsWithSubtabs[foundMainTabIndex];
        const foundSubTabIndex = (mainTab.subtabs || []).findIndex(
          (subtab) => labelToPath(subtab.tabLabel!) === subTabLabel
        );

        setCurrentSubTabIndex(foundSubTabIndex !== -1 ? foundSubTabIndex : 0);
      } else {
        setCurrentMainTabIndex(0);
        setCurrentSubTabIndex(0);

        const firstMainTab = mainTabsWithSubtabs[0];
        const firstSubtab = (firstMainTab.subtabs || [])[0];

        if (firstMainTab && firstSubtab) {
          navigate(
            `/individual/${id}/${visaType}/${labelToPath(
              firstMainTab.tabLabel!
            )}/${labelToPath(firstSubtab.tabLabel!)}`
          );
        }
      }
      setLoading(false);
    }
  }, [location.pathname, id, visaType, isLoadingGetIndividualData]);

  const getCurrentMainTab = () => {
    if (
      currentMainTabIndex >= 0 &&
      currentMainTabIndex < mainTabsWithSubtabs.length
    ) {
      return mainTabsWithSubtabs[currentMainTabIndex];
    }
    return { tabLabel: "", content: <div />, subtabs: [] };
  };

  const mainTab = getCurrentMainTab();
  const subtabs = mainTab.subtabs || [];

  const handleMainTabChange = (index: number) => {
    setCurrentMainTabIndex(index);
    setCurrentSubTabIndex(0);
    const mainTab = mainTabsWithSubtabs[index];
    let newPath = `/individual/${id}/${visaType}/${labelToPath(
      mainTab.tabLabel!
    )}`;
    if (mainTab.subtabs && mainTab.subtabs.length > 0) {
      newPath += `/${labelToPath(mainTab.subtabs[0].tabLabel!)}`;
    }
    navigate(newPath);
  };

  const handleSubTabChange = (index: number, tab: Subtab) => {
    const newPath = `/individual/${id}/${visaType}/${labelToPath(
      mainTab.tabLabel!
    )}/${labelToPath(subtabs[index].tabLabel!)}`;
    navigate(newPath);
  };

  const handleBack = () => {
    if (subtabs.length > 0 && currentSubTabIndex > 0) {
      setCurrentSubTabIndex(currentSubTabIndex - 1);
      const newPath = `/individual/${id}/${visaType}/${labelToPath(
        mainTab.tabLabel!
      )}/${labelToPath(subtabs[currentSubTabIndex - 1].tabLabel!)}`;
      navigate(newPath);
    } else if (currentMainTabIndex > 0) {
      setCurrentMainTabIndex(currentMainTabIndex - 1);
      const mainTab = mainTabsWithSubtabs[currentMainTabIndex - 1];
      if (mainTab && mainTab.subtabs && mainTab.subtabs.length) {
        setCurrentSubTabIndex(mainTab.subtabs.length - 1);
        const newPath = `/individual/${id}/${visaType}/${labelToPath(
          mainTab.tabLabel!
        )}/${labelToPath(
          mainTab.subtabs[mainTab.subtabs.length - 1].tabLabel!
        )}`;
        navigate(newPath);
      } else {
        setCurrentSubTabIndex(0);
        const newPath = `/individual/${id}/${visaType}/${labelToPath(
          mainTabsWithSubtabs[currentMainTabIndex - 1].tabLabel!
        )}`;
        navigate(newPath);
      }
    }
  };

  const handleNext = () => {
    if (subtabs.length > 0 && currentSubTabIndex < subtabs.length - 1) {
      setCurrentSubTabIndex(currentSubTabIndex + 1);
      const newPath = `/individual/${id}/${visaType}/${labelToPath(
        mainTab.tabLabel!
      )}/${labelToPath(subtabs[currentSubTabIndex + 1].tabLabel!)}`;
      navigate(newPath);
    } else if (currentMainTabIndex < mainTabsWithSubtabs.length - 1) {
      setCurrentMainTabIndex(currentMainTabIndex + 1);
      setCurrentSubTabIndex(0);
      const newPath = `/individual/${id}/${visaType}/${labelToPath(
        mainTabsWithSubtabs[currentMainTabIndex + 1].tabLabel!
      )}`;
      navigate(newPath);
    }
  };

  let prevTabLabel = "";

  if (subtabs.length > 0 && currentSubTabIndex > 0) {
    prevTabLabel = subtabs[currentSubTabIndex - 1].tabLabel || "";
  } else if (subtabs.length > 0 && currentMainTabIndex > 0) {
    prevTabLabel = mainTabsWithSubtabs[currentMainTabIndex - 1].tabLabel || "";
  }

  let nextTabLabel = "";

  if (subtabs.length > 0 && currentSubTabIndex < subtabs.length - 1) {
    nextTabLabel = subtabs[currentSubTabIndex + 1].tabLabel || "";
  } else if (currentMainTabIndex < mainTabsWithSubtabs.length - 1) {
    nextTabLabel = mainTabsWithSubtabs[currentMainTabIndex + 1].tabLabel || "";
  }

  if (loading || mainTabsWithSubtabs.length === 0) {
    return <div>Loading...</div>;
  }
  return (
    <SectionWrapper withBackNav={false} padding={0}>
      <Flex flexDirection="column" className="relative" flex="1">
        <Tabs
          index={currentMainTabIndex}
          onChange={handleMainTabChange}
          p={0}
          m={0}
          bg="#EEF2F6"
        >
          <TabList>
            {mainTabsWithSubtabs.map((tab, index) => (
              <Tab key={index} fontWeight={600}>
                {tab.tabLabel!.toUpperCase()}
              </Tab>
            ))}
          </TabList>
          <TabIndicator mt="-1.5px" />
          <Divider opacity={0} my={2} />
          <TabPanels>
            {mainTabsWithSubtabs.map((tab, index) => (
              <TabPanel key={index} p={0}>
                {tab.subtabs && tab.subtabs.length > 0 ? (
                  <Tabs
                    variant="sub"
                    index={currentSubTabIndex}
                    onChange={(index) => {
                      const currentSubTab = tab.subtabs![index];

                      handleSubTabChange(index, currentSubTab);
                    }}
                  >
                    <TabList>
                      {tab.subtabs.map((subtab, subTabIndex) => (
                        <Tab
                          key={subTabIndex}
                          className="!cursor-pointer"
                          onClick={(_) => {}}
                        >
                          {subtab.tabLabel!}
                        </Tab>
                      ))}
                    </TabList>
                    <TabIndicator mt="-1.5px" />
                    <TabPanels>
                      {tab.subtabs.map((subtab, subTabIndex) => (
                        <TabPanel p={0} key={subTabIndex}>
                          {subtab.content}
                        </TabPanel>
                      ))}
                    </TabPanels>
                    <PremiumModal
                      isOpen={premiumModalOpen}
                      onClose={() => {
                        setPremiumModalOpen(false);
                      }}
                    />
                  </Tabs>
                ) : (
                  tab.content
                )}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>

        {/* Sticky TabNavigation */}
        <Box
          display="none"
          className="border"
          position="sticky"
          bottom="0"
          zIndex="10"
          bg="white"
          borderBottomRadius={12}
          py={2}
        >
          {/* comment until we decide to keep it */}
          <TabNavigation
            currentTabIndex={
              subtabs.length > 0 ? currentSubTabIndex : currentMainTabIndex
            }
            totalTabs={
              subtabs.length > 0 ? subtabs.length : mainTabsWithSubtabs.length
            }
            onBack={handleBack}
            onNext={handleNext}
            prevTabLabel={prevTabLabel || ""}
            nextTabLabel={nextTabLabel || ""}
          />
        </Box>
      </Flex>
    </SectionWrapper>
  );
};

export default IndividualCase;
