import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocumentData, DocumentReference, Timestamp } from "firebase/firestore";

// Define the job status enum
export enum EXTRACTIONSTATES {
  NotStarted = "NotStarted",
  Pending = "Pending",
  Processing = "Processing",
  Completed = "Completed",
  Failed = "Failed"
}

export const ACTIVE_STATUSES: EXTRACTIONSTATES[] = [
  EXTRACTIONSTATES.Processing,
  EXTRACTIONSTATES.Pending
];

export const isActiveStatus = (status: EXTRACTIONSTATES) => 
  ACTIVE_STATUSES.includes(status);

export enum ExtractionStatesDescription {
  NotStarted = "Not started",
  Pending = "Extracting",
  Processing = "Extracting",
  Completed = "Extracted",
  Failed = "Encountered an error"
}

export enum GenerationStatesDescription {
  NotStarted = "Not started",
  Pending = "Generating",
  Processing = "Generating",
  Completed = "Generated",
  Failed = "Encountered an error"
}

export enum JobType {
  PerformingOCR = "Performing OCR",
  SummarizingResume = "Summarizing resume",
  Extracting = "Extracting",
  GeneratingDraft = "Generating draft",
  MappingData = "Mapping data",
  GeneratingResearch = "Generating research",
  GeneratingCasePacket = "Generating Final Packet"
}

export enum JobId {
  StandardDocuments = "Standard Documents",
  LegalBrief = "Legal Brief",
  CasePacket = "Final Packet"
}

export type ExtractionStatus = {
  status: EXTRACTIONSTATES;
  errorMsg?: string | null;
  taskType?: string | null;
};

// Define the state structure for the extraction slice
export interface ExtractionState {
  jobs: ExtractionJob[];
  newJob: ExtractionJob | null;
  completedJob: ExtractionJob | null;
}

// Define the initial state
const initialState: ExtractionState = {
  jobs: [],
  newJob: null,
  completedJob: null
};

// Define the interface for the reducer
export interface ExtractionReducer {
  extraction: ExtractionState;
}

export type CustomCall = () => Promise<any>;
export interface ExtractionJob {
  id: string;
  type: string;
  status: ExtractionStatus;
  docRef: DocumentReference<DocumentData>;
  jobRef: DocumentReference<DocumentData>;
  customRequests?: (() => Promise<void>)[];
  toastTitle: string;
  toastDesc: string;
  docName?: string;
  fieldPath?: string;
  lastGeneratedAt?: number;
  customCalls?: CustomCall[];
  // Field to distinguish between client initiated/ auto initiated jobs
  isInitiated?: boolean;
  created_at?: number | null;
  updated_at?: number | null;
}

// Update the ExtractionJob action to handle previous status
export const extractionSlice = createSlice({
  name: "extraction",
  initialState,
  reducers: {
    updateExtractionJob: (state, action: PayloadAction<ExtractionJob>) => {
      const { id, status } = action.payload;
      const index = state.jobs.findIndex((job) => job.id === id);
      if (index !== -1) {
        // Save the current status as previous before updating to new status
        const currentStatus = state.jobs[index].status;
        state.jobs[index] = { ...state.jobs[index], ...action.payload };
      } else {
        // If the job is not found, just add it without a previous status
        state.jobs.push({ ...action.payload });
      }
    },
    clearAllExtractions: (state) => {
      state.jobs = [];
      console.log("tasks-redux: Cleared all extraction jobs");
    },
    setJobs: (state, action: PayloadAction<ExtractionJob[]>) => {
      state.jobs = action.payload;
      console.log("tasks-redux: Extraction jobs added");
    },
    setNewJob: (state, action: PayloadAction<ExtractionJob>) => {
      state.newJob = action.payload;
    },
    setCompletedJob: (state, action: PayloadAction<ExtractionJob>) => {
      state.completedJob = action.payload;
    },
    clearNewExtractionType: (state) => {
      state.newJob = null;
    },
    clearCompletedExtractionType: (state) => {
      state.completedJob = null;
    },
    deleteExtractionJob: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      state.jobs = state.jobs.filter((job) => job.id !== id);
    }
  }
});

// Export the actions
export const {
  updateExtractionJob,
  clearAllExtractions,
  setJobs,
  clearNewExtractionType,
  setNewJob,
  setCompletedJob,
  clearCompletedExtractionType,
  deleteExtractionJob
} = extractionSlice.actions;

// Export the reducer
export default extractionSlice.reducer;
