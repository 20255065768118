import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { TargetBox } from "./inputDnD/TargetBox";

import useFileActions from "../hooks/useFileActions";
import { useGetNewEvidenceDocs } from "../hooks/useGetNewEvidenceDocs";
import { documentSelectors } from "../redux/documents/selectors";
import { VisaDocumentType } from "../redux/documents/types";
import { DATABASE } from "../types/tables-data";
import { UploadFilesPlaceholder } from "./UploadFilesPlaceholder";
import { EvidenceTable } from "./common/SimpleTable";

export const EvidenceDocumentsTable = () => {
  const { id: uid, visaType } = useParams();
  const evidenceDocsCollectionPath = `documents/${uid}/evidence_docs`;
  const documentsPath = `documents/${uid}`;

  useGetNewEvidenceDocs(
    evidenceDocsCollectionPath,
    VisaDocumentType.Evidence,
    documentsPath
  );

  const docs = useSelector(documentSelectors.evidenceDocs);

  const evidenceDocumentsStoragePath = `${DATABASE.INDIVIDUALS}/documents/${uid}/Evidences`;

  const { handleFileActions } = useFileActions();

  return (
    <Flex flexDirection="column" justifyContent="start" w="100%">
      <DndProvider backend={HTML5Backend} key={6} context={window}>
        <TargetBox
          onDrop={(item) => {
            const { files } = item;
            handleFileActions({
              files: Array.from(files),
              path: evidenceDocumentsStoragePath,
              uid: uid!,
              visaDocumentType: VisaDocumentType.Evidence,
              superClass : "Evidence",
            });
          }}
        >
          <Flex flexDirection="column" textAlign="start" w="100%">
            <UploadFilesPlaceholder
            allowUploadByCategory
              tableRef="evidence"
              styleIndex={docs?.length === 0 ? 0 : 2}
              title="Drag and drop documents here"
              subTitle="OR"
              withImg
              onUpload={(files) => {
                if (files)
                  handleFileActions({
                    files: Array.from(files),
                    path: evidenceDocumentsStoragePath,
                    uid: uid!,
                    superClass : "Evidence",
                    visaDocumentType: VisaDocumentType.Evidence
                  });
              }}
              storagePath={evidenceDocumentsStoragePath}
              collectionPath={evidenceDocsCollectionPath}
              visaDocumentType={VisaDocumentType.Evidence}
              
            />
          </Flex>
        </TargetBox>
        {/* Evidence Documents Table */}
        <Divider my={2} mb={4} opacity={0} />
        {docs.length > 0 && <EvidenceTable />}
      </DndProvider>
    </Flex>
  );
};
