import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { FileIcon, defaultStyles } from "react-file-icon";
import { FaSave } from "react-icons/fa";

interface ExportMenuProps {
  isDownloading: boolean;
  handleDownload: (extension: string) => void;
  leftIcon?: React.ReactElement;
  text?: string;
}

const ExportMenu: React.FC<ExportMenuProps> = ({
  isDownloading,
  handleDownload,
  leftIcon = <FaSave />,
  text= "Export and save"
}) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        leftIcon={isDownloading ? <Spinner size="sm" /> : leftIcon}
        variant="secondaryOutline"
      >
        <Text fontSize={14}>{text}</Text>
      </MenuButton>
      <MenuList zIndex="dropdown">
        <MenuItem
          icon={
            <Box boxSize="20px">
              <FileIcon extension="docx" {...defaultStyles.docx} />
            </Box>
          }
          onClick={() => handleDownload("docx")}
        >
          DOCX
        </MenuItem>
        <MenuItem
          icon={
            <Box boxSize="20px">
              <FileIcon extension="pdf" {...defaultStyles.pdf} />
            </Box>
          }
          onClick={() => handleDownload("pdf")}
        >
          PDF
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ExportMenu;
