import {
  Flex,
  Box,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  Spinner,
  Heading
} from "@chakra-ui/react";
import { Timestamp } from "firebase/firestore";
import { Link, Link as LinkDom, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getFirebaseUsers } from "../../../api/OpenAIApi";
import HamburgerMenu from "../../../components/HamburgerMenu";
import { Header } from "../../../components/Header";
import { Sidebar } from "../../../components/Sidebar/Sidebar";
import { SpecialHeading } from "../../../components/individualTabs/PanelLayout";

export const AdminUsers = () => {
  interface User {
    uid: string;
    email: string;
    phoneNumber: string;
    role: string;
    firstName: string;
    lastName: string;
    lawFirmName: string;
    disabled: boolean;
    emailVerified: boolean;
    lastSignedInAt: Timestamp;
    createdAt: Timestamp;
  }

  const navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [tokens, setTokens] = useState<string[]>([]);
  const [tokenIndex, setTokenIndex] = useState<number>(0);

  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const perPage = 25;

  const getUserData = async (
    orderBy = "",
    uid = "",
    email = "",
    maxResults = perPage,
    pageToken = ""
  ) => {
    setIsLoading(true);
    try {
      if (pageToken === "") {
        setTokenIndex(0);
      }
      const usersData = await getFirebaseUsers(
        orderBy,
        uid,
        email,
        maxResults,
        pageToken
      );
      setUsers(usersData.users);
      if (
        usersData.nextPageToken &&
        !tokens.includes(usersData.nextPageToken)
      ) {
        setTokens([...tokens, usersData.nextPageToken]);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const changePage = (destination: string) => {
    if (destination === "previous") {
      setTokenIndex(tokenIndex - 1);
      getUserData("", "", searchQuery, perPage, tokens[tokenIndex]);
    }
    if (destination === "next") {
      setTokenIndex(tokenIndex + 1);
      getUserData("", "", searchQuery, perPage, tokens[tokenIndex]);
    }
  };
  useEffect(() => {
    getUserData();
  }, []);

  const handleSearch = () => {
    setTokens([]);
    getUserData("", "", searchQuery);
  };

  const pageSize = 25; // Number of users per page

  const renderUserContent = () => {
    const [currentPage, setCurrentPage] = useState(1);

    if (isLoading) {
      return (
        <Spinner
          alignSelf="center"
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="primary.blue"
          size="xl"
        />
      );
    }

    if (users && users.length > 0) {
      const lastIndex = currentPage * pageSize;
      const firstIndex = lastIndex - pageSize;
      const currentUsers = users.slice(firstIndex, lastIndex);

      return (
        <Box className="overflow-x-scroll overflow-y-scroll max-h-[70vh]">
          <table
            style={{
              width: "100%",
              borderCollapse: "separate",
              borderSpacing: "0 10px"
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    backgroundColor: "#4CAF50",
                    color: "white",
                    border: "1px solid #ddd"
                  }}
                >
                  Edit
                </th>
                <th
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    backgroundColor: "#4CAF50",
                    color: "white",
                    border: "1px solid #ddd"
                  }}
                >
                  Email
                </th>
                <th
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    backgroundColor: "#4CAF50",
                    color: "white",
                    border: "1px solid #ddd"
                  }}
                >
                  Role
                </th>
                <th
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    backgroundColor: "#4CAF50",
                    color: "white",
                    border: "1px solid #ddd"
                  }}
                >
                  FirstName
                </th>
                <th
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    backgroundColor: "#4CAF50",
                    color: "white",
                    border: "1px solid #ddd"
                  }}
                >
                  LastName
                </th>
                <th
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    backgroundColor: "#4CAF50",
                    color: "white",
                    border: "1px solid #ddd"
                  }}
                >
                  LawFirm
                </th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.map((user, index) => (
                <tr
                  key={user.uid}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#f2f2f2" : "#e9e9e9",
                    border: "1px solid #ddd",
                    cursor: "pointer"
                  }}
                >
                  <td style={{ padding: "8px", textAlign: "left" }}>
                    <Link
                      to={`/admin/users/${user.uid}/edit`}
                      key={user.uid}
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      Edit
                    </Link>
                  </td>
                  <td style={{ padding: "8px", textAlign: "left" }}>
                    {user.email}
                  </td>
                  <td style={{ padding: "8px", textAlign: "left" }}>
                    {user.role}
                  </td>
                  <td style={{ padding: "8px", textAlign: "left" }}>
                    {user.firstName}
                  </td>
                  <td style={{ padding: "8px", textAlign: "left" }}>
                    {user.lastName}
                  </td>
                  <td style={{ padding: "8px", textAlign: "left" }}>
                    {user.lawFirmName}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Box display="flex" justifyContent="center" mt="20px">
            <Button
              variant="secondaryOutline"
              type="button"
              mt="20px"
              mr="20px"
              isDisabled={tokenIndex === 0}
              onClick={() => changePage("previous")}
            >
              Previous
            </Button>
            <Button
              variant="primaryFilled"
              type="button"
              mt="20px"
              onClick={() => changePage("next")}
              isDisabled={tokenIndex === tokens.length}
            >
              Next
            </Button>
          </Box>
        </Box>
      );
    }
    return (
      <Heading size="sm" my="15px">
        No users found. <br /> Use the system to add users.
      </Heading>
    );
  };

  return (
    <Flex flexDirection="column" flex="1" className="p-4 overflow-scroll">
      <Box py="15px" width="100%">
        <SpecialHeading title="User Management" />
      </Box>
      <LinkDom
        to="new"
        style={{
          textDecoration: "underline",
          cursor: "pointer",
          display: "block",
          textAlign: "left"
        }}
      >
        Create a new user
      </LinkDom>
      <Flex mt="20px" alignItems="center">
        <Input
          placeholder="Search for user..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          mr="10px"
          width="20%"
        />
        <Button onClick={handleSearch} variant="primaryFilled">
          Search
        </Button>
      </Flex>

      {/* Users Table and Loading/Empty Messages */}
      {renderUserContent()}
    </Flex>
  );
};
