import { useSelector } from "react-redux";
import { lawyerSelectors } from "../redux/lawyer/selectors";
import { RootState } from "../redux/store";
import { individualSelectors } from "../redux/individual/selectors";

// Custom hook to encapsulate all role and subscription checks
export const useUserRoleAndSubscription = () => {
  // Extract the necessary data from Redux store using useSelector
  const role = useSelector(lawyerSelectors.selectRole);
  const hideQualityReview = useSelector(lawyerSelectors.selectHideQualityReview);
  const { subscription } = useSelector(lawyerSelectors.selectAll);
  const indivId = useSelector((state: RootState) =>
    individualSelectors.selectUid(state)
  );

  // Determine user role and subscription status
  const isSuperAdmin = role === "superadmin";
  const isFullUser = role === "full";
  const isStandardUser = role === "standard";
  const isFreemiumUser = subscription?.status === "freemium";
  const isPaidUser = subscription?.status === "paid";
  const isIndividual = indivId ?? false;
  const shouldHideQualityReview = hideQualityReview ?? false;

  // Return all boolean values for use in components
  return {
    isIndividual,
    isSuperAdmin,
    isFullUser,
    isStandardUser,
    isFreemiumUser,
    isPaidUser,
    shouldHideQualityReview
  };
};
