import { Button, IconButton } from "@chakra-ui/react";
import React from "react";
import { IoArrowBackSharp, IoArrowForwardSharp } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";

interface Props {
  onClick?: () => void;
  disabled?: boolean;
  isNext?: boolean;
  ariaLabel?: string;
  isContainerVisible?: boolean;
}

const CustomBackButton: React.FC<Props> = ({
  onClick,
  disabled = false,
  isNext = false,
  ariaLabel = "",
  isContainerVisible = true
}) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const handleNavigate = () => {
    if (onClick) {
      onClick();
    } else {
      // eslint-disable-next-line no-lonely-if
      if (isNext) {
        navigate(1);
      } else if (window.history.length > 2) {
        navigate(-1);
      } else {
        // eslint-disable-next-line no-lonely-if
        if (id) navigate(`/`);
      }
    }
  };

  if (isContainerVisible) {
    return (
      <Button
        fontSize="sm"
        color="primary.blue"
        variant="ghost"
        aria-label={ariaLabel}
        disabled={disabled}
        textAlign="left"
        border="none"
        padding={2}
        size="lg"
        onClick={handleNavigate}
        leftIcon={isNext ? <IoArrowForwardSharp /> : <IoArrowBackSharp />}
      >
        {ariaLabel}
      </Button>
    );
  }
  return <span>{isNext ? <IoArrowForwardSharp /> : <IoArrowBackSharp />}</span>;
};

export default CustomBackButton;
