import { useState } from "react";
import { downloadFile } from "../api/OpenAIApi";

export const useDownloadFile = () => {
  const [isLoadingDownloadFile, setIsLoadingDownloadFile] = useState(false);
  const [apiResponseDownloadFile, setApiResponseDownloadFile] = useState<any | null>(null);

  const onSubmitDownloadFile = async (storagePath: string): Promise<any> => {
    console.log("Extracting data from useExtractData");

    setIsLoadingDownloadFile(true);

    try {
        const fileData = await downloadFile(storagePath);
        if (fileData) {
          setApiResponseDownloadFile(fileData);
        }
        return fileData;
      } catch (error) {
        console.error("Something went wrong:", error);
      } finally {
        setIsLoadingDownloadFile(false);
      }
    return null;
  };
  return {
    isLoadingDownloadFile,
    onSubmitDownloadFile,
    apiResponseDownloadFile
  };
};
