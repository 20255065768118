import { Box, Icon, useToast } from "@chakra-ui/react";
import { ref } from "firebase/storage";
import { useEffect, useState } from "react";
import { MdInsertDriveFile } from "react-icons/md";
import { storage } from "../../../api/firebaseApi";
import { downloadFileAsBlob, getFileName } from "../../../helpers/file_helpers";
import {
  generateDocxThumbnail,
  generatePdfThumbnail,
  openInNewTab
} from "../../../helpers/helpers";

interface Props {
  filePath: string;
  documentSummary?: string | null | undefined;
  disableThumbnailClick?: boolean;
}

const Thumbnail: React.FC<Props> = ({
  filePath,
  documentSummary,
  disableThumbnailClick
}: Props) => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [fileUrl, setFileUrl] = useState("");
  const [originalFileUrl, setOriginalFileUrl] = useState("");

  const handleClick = () => {
    if (originalFileUrl && !disableThumbnailClick) {
      openInNewTab(originalFileUrl, getFileName(originalFileUrl));
    }
  };
  useEffect(() => {
    const fileRef = ref(storage, filePath ?? "");

    if (filePath) {
      // Check if the file is a PDF or an image
      downloadFileAsBlob(fileRef).then((url) => {
        if (filePath?.endsWith(".pdf")) {
          setOriginalFileUrl(url);
          generatePdfThumbnail(url).then((pdfThumbnailUrl) => {
            setFileUrl(pdfThumbnailUrl);
          });
        } else if (filePath.endsWith("docx")) {
          generateDocxThumbnail(url).then((docxThumbnail) => {
            setFileUrl(docxThumbnail);
          });
        } else {
          downloadFileAsBlob(fileRef).then((fileurl) => {
            setFileUrl(fileurl);
          });
        }
      });
    }
  }, [filePath]);
  return (
    <Box
      p={2}
      textAlign="center"
      h="auto"
      title="Document preview"
      className="relative"
    >
      {fileUrl ? (
        <img
          src={fileUrl}
          alt={`Thumbnail for ${getFileName(filePath)}`}
          onLoad={() => setLoading(false)}
          className={`thumbnail-image ${loading ? "hidden" : "block"}`}
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        />
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          w="full"
          h="full"
        >
          <Icon
            as={MdInsertDriveFile}
            w={8}
            h={8}
            color="primary.blue"
            className={loading ? "block" : "hidden"}
          />
        </Box>
      )}
    </Box>
  );
};

export default Thumbnail;
