import {
  Text,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/react";

type Props = {
  isOpen: boolean;
  onConfirm: () => void;
  setShowDeleteDialog: (value: boolean) => void;
  isLoading: boolean;
};

export const DeleteLegalBriefModal = ({
  isOpen,
  onConfirm,
  setShowDeleteDialog,
  isLoading
}: Props) => {
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={() => setShowDeleteDialog(false)}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Warning !</ModalHeader>
        <ModalCloseButton
          onClick={() => {
            setShowDeleteDialog(false);
          }}
        />
        <ModalBody pb={6}>
          <Text>
            Are you sure you want to delete the current generated statement of
            purpose?
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
          variant="secondaryOutline"
            onClick={() => {
              setShowDeleteDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="destructiveFilled"
            ml={3}
            onClick={onConfirm}
            isLoading={isLoading}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
