import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ExpertLetter } from "../clients/clientsSlice";

export interface FormsSliceData {
  expertLettersForms: ExpertLetter[] | null;
}

const formsInitialData: FormsSliceData = {
  expertLettersForms: null
};
export interface FormsReducer {
  forms: FormsSliceData;
}

export const formsSlice = createSlice({
  name: "forms",
  initialState: formsInitialData,
  reducers: {
    setExpertLetters: (state, action: PayloadAction<ExpertLetter[] | null>) => {
      state.expertLettersForms = action.payload;
    },
    addExpertLetter: (state, { payload }: PayloadAction<ExpertLetter>) => {
      state.expertLettersForms?.push(payload);
    },
    clearExpertLetters: (state) => {
      state.expertLettersForms = [];
    }
  }
});

export const { setExpertLetters, addExpertLetter, clearExpertLetters } =
  formsSlice.actions;

export default formsSlice.reducer;
