import React from "react";
import { Flex, Button, Text, Box } from "@chakra-ui/react";
import CustomBackButton from "./CustomBackButton";

interface TabNavigationProps {
  currentTabIndex: number;
  totalTabs: number;
  onBack: () => void;
  onNext: () => void;
  prevTabLabel?: string;
  nextTabLabel?: string;
}

const TabNavigation: React.FC<TabNavigationProps> = ({
  currentTabIndex,
  totalTabs,
  onBack,
  onNext,
  prevTabLabel,
  nextTabLabel
}) => {
  return (
    <Flex justifyContent="space-between" alignItems="center" mx="15px">
      <Flex alignItems="center">
        {prevTabLabel && (
          <Button
            onClick={onBack}
            disabled={currentTabIndex === 0}
            leftIcon={<CustomBackButton onClick={onBack} />}
            variant="ghost"
            display="flex"
            alignItems="center"
                  color="primary.blue"
          >
            <Text fontSize="sm" color="primary.blue">
              {prevTabLabel}
            </Text>
          </Button>
        )}
      </Flex>
      <Flex alignItems="center">
        {nextTabLabel && (
          <Button
            onClick={onNext}
            disabled={currentTabIndex === totalTabs - 1}
            rightIcon={
              <CustomBackButton
                onClick={onNext}
                isNext
                isContainerVisible={false}
              />
            }
            variant="ghost"
            display="flex"
            alignItems="center"
            color="primary.blue"
          >
            <Text fontSize="sm">
              {nextTabLabel}
            </Text>
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default TabNavigation;
