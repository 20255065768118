import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack
} from "@chakra-ui/react";
import { Dropdown } from "../../../common/Dropdown";
import { LegalBriefType } from "../../../../types/drafts.type";

type Props = {
  onCancel: () => void;
  onConfirm: () => void;
  isOpen: boolean;
  selectedType: LegalBriefType;
  setSelectedType: (value: LegalBriefType) => void;
};

const GenerateLegalBriefModal = ({
  onCancel,
  onConfirm,
  isOpen,
  selectedType,
  setSelectedType
}: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onCancel} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Generate Legal Brief</ModalHeader>
        <ModalBody>
          <VStack alignItems="start" spacing={4}>
            <Heading fontSize="18" textAlign="left">
              Select an Option
            </Heading>
            <Dropdown
              selectedValue={selectedType}
              title="Options"
              onItemSelect={(value: LegalBriefType) => setSelectedType(value)}
              list={Object.values(LegalBriefType)}
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onCancel} className="btn btn-tertiary">
            Cancel
          </Button>
          <Button
            className="btn btn-secondary"
            onClick={onConfirm}
            isDisabled={!selectedType}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default GenerateLegalBriefModal;
