import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";

type Props = {
  onConfirm: () => void;
  onClose: () => void;
  isOpen: boolean;
};

export const SaveAnswersDialog = ({ onConfirm, onClose, isOpen }: Props) => {
  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirmation</ModalHeader>
        <ModalCloseButton
          onClick={() => {
            onClose();
          }}
        />
        <ModalBody pb={6}>
          Are you sure you want to save changes to the questionnaire?
        </ModalBody>

        <ModalFooter>
          <Button
          variant="secondaryOutline"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primaryFilled"
            ml={3}
            onClick={() => {
              onConfirm();
            }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
