import { Button, Flex, Input } from "@chakra-ui/react";
import React from "react";

import { CountrySelector, usePhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

interface Props {
    value: string;
    onChange: (phone: string) => void;
    placeholder: string;
}

export const CustomPhoneInput: React.FC<Props> = ({ value, onChange, placeholder }) => {
    const phoneInput = usePhoneInput({
        value,
        defaultCountry: 'us',
        onChange: ({ phone }) => onChange(phone),
    });

    return (
        <Flex alignItems="center">
            <CountrySelector
                selectedCountry={phoneInput.country.iso2}
                onSelect={(country) => phoneInput.setCountry(country.iso2)}
                renderButtonWrapper={({ children, rootProps }) => (
                    <Button {...rootProps} variant="secondaryOutline" px="4px" mr="8px">
                        {children}
                    </Button>
                )}
            />
            <Input
                placeholder={placeholder}
                type="tel"
                color="primary"
                value={phoneInput.phone}
                onChange={(e) => phoneInput.handlePhoneValueChange(e)}
                width={200}
                ref={phoneInput.inputRef}
            />
        </Flex>
    );
};
