import { defineStyleConfig } from "@chakra-ui/react";

export const checkboxTheme = defineStyleConfig({
  baseStyle: {
    control: {
      _checked: {
        bg: "primary.blue", // Checked background color
        borderColor: "primary.blue", // Checked border color
        color: "white", // Checkmark color
        _hover: {
          bg: "primary.blue", // Hover state when checked
          borderColor: "primary.blue", // Border hover state when checked
        },
      },
      _hover: {
        borderColor: "primary.blue", // Hover state when not checked
      },
    },
  },
  defaultProps: {
    colorScheme: "blue", // Default color scheme
  },
});
