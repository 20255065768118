import {
  Button,
  Flex,
  Box,
  Text,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage
} from "@chakra-ui/react";
import { Link as LinkDom, useNavigate } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState, useRef, useEffect } from "react";
import { Header } from "../components/Header";
import { clickedStyles } from "../styles/common-styles";

export const ResetPassword = () => {
  const [isWrongEmail, setIsWrongEmail] = useState(false);
  const [wrongEmail, setWrongEmail] = useState("");
  const navigate = useNavigate();

  const auth = getAuth();

  const handleResetMail = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, `${email}`);
      alert(
        "Password reset submitted. Please check your email and follow instructions."
      );
      navigate("/login");
    } catch (e: any) {
      const errorCode = e.code;
      if (
        errorCode === "auth/user-not-found" ||
        errorCode === "auth/invalid-email"
      ) {
        setIsWrongEmail(true);
        setWrongEmail(`${email}`);
      }
    }
  };

  const { values, handleSubmit, touched, errors, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        email: ""
      },
      validationSchema: Yup.object({
        email: Yup.string()
          .email("Invalid email address")
          .required("Email is required")
      }),
      onSubmit: ({ email }) => handleResetMail(email),
      validateOnChange: false,
      validateOnBlur: false
    });

  const mailRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (!values.email && mailRef.current) {
      mailRef.current.focus();
    }
  }, []);
  useEffect(() => {
    if (isWrongEmail && wrongEmail !== values.email) {
      setIsWrongEmail(false);
      setWrongEmail("");
    }
  }, [isWrongEmail, values.email]);

  return (
    <>
      <Header />
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        my="111px"
      >
        <Box
          width="494px"
          border="1px"
          borderColor="rgba(0, 0, 0, 0.05)"
          borderRadius="10px"
          boxShadow="xl"
          bg="white"
        >
          <Flex alignItems="center" m="-1px -1px 64px">
            <Box flex={1} py="15px" bg="secondary.offWhite">
              <Text fontSize="lg" fontWeight="bold" color="primary.blue">
                Enter your email to reset password
              </Text>
            </Box>
          </Flex>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Box p="0 24px 15px">
              <FormControl
                my="25px"
                variant="floating"
                id="email"
                isInvalid={touched.email && !!errors.email}
              >
                <Input
                  ref={mailRef}
                  placeholder=" "
                  name="email"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="new-email"
                  bg="secondary.offWhite"
                />
                <FormLabel>Email</FormLabel>
                <FormErrorMessage>{errors.email}</FormErrorMessage>
                {isWrongEmail && (
                  <Box color="red.500" fontSize="sm">
                    User with this email was not found
                  </Box>
                )}
              </FormControl>
            </Box>
            <Flex
              justifyContent="center"
              alignItems="center"
              gap="12px"
              mb="12px"
            >
              <LinkDom to="/">
                <Button className="btn tertiary-btn">Cancel</Button>
              </LinkDom>
              <Button variant="primaryFilled" type="submit">
                Reset password
              </Button>
            </Flex>
          </form>
          {/* 
            In case we decide we need this back
          <Text
            mb="14px"
            fontSize="sm"
            color="tertiary.blue"
            textAlign="center"
          >
            Don't have an account?{" "}
            <LinkDom to="https://share.hsforms.com/17k5kCX6ES0eZaKDyGewkDAq9g78">
              <strong style={{ cursor: "pointer" }}>Sign up here!</strong>
            </LinkDom>
          </Text> */}
        </Box>
      </Flex>
    </>
  );
};
