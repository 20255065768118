import { WarningIcon } from "@chakra-ui/icons";
import { Box, BoxProps, ChakraProps, Flex } from "@chakra-ui/react";
import { ReactNode } from "react";

type CustomBoxType = "regular" | "info" | "warning";

interface MyComponentProps extends BoxProps {
  type: CustomBoxType;
  optionalContent?: ReactNode;
}

export const CustomBox = ({
  children,
  type = "regular",
  optionalContent,
  ...rest
}: MyComponentProps) => {
  const styles: Record<CustomBoxType, Partial<ChakraProps>> = {
    regular: {
      p: "15px",
      fontWeight: "400",
      textAlign: "left",
      bgColor: "#FFFCED",
      mb: "24px",
      borderRadius: "5px",
      border: "1px solid #ECE4E4"
    },
    info: {
      p: "15px",
      fontWeight: "400",
      textAlign: "left",
      bgColor: "gray.100",
      mb: "24px",
      borderRadius: "5px",
      border: "1px solid #ECE4E4",
      color: "primary.blue"
    },
    warning: {
      p: "15px",
      fontWeight: "400",
      textAlign: "left",
      bgColor: "rgba(255, 251, 229, 0.67)",
      mb: "24px",
      borderRadius: "5px",
      border: "1px solid #ECE4E4"
    }
  };

  return (
    <Box
      color="black"
      {...styles[type]}
      {...rest}
      className={`${type === "info" ? "flex items-center gap-2" : ""}`}
    >
      <Flex flexDirection="column" gap={4}>
        <Flex gap={4}>
          {type === "info" && <WarningIcon color="pimary.blue" mt={1}/>}
          {children}
        </Flex>
        {optionalContent && <Box mt="10px">{optionalContent}</Box>}
      </Flex>
    </Box>
  );
};
