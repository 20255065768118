

export type DraftDocument = {
  sop: { text: string };
  exhibit_list: { text: string; html: string; isProcessing?: boolean };
  coverLetter: { text: string };
  created_at: Date;
};

export interface GenerateDraftParams {
  individualId: string;
  draftType: string;
  expertId?: string | null;
  tone?: string | null;
  length?: string | null;
  creativity?: string | null;
  greeting?: string | null;
  useAsync?: boolean;
  referenceResume?: boolean;
  isSupportLetter?: boolean;
}

export interface GeneratePacketParams {
  individualId: string;
  useAsync?: boolean;
}

export enum LegalBriefType {
  LEGAL_BRIEF = "Legal brief",
  PETITIONER_SUPPORT_LETTER = "Petitioner support letter"
}
