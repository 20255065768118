import { tableAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tableAnatomy.keys
);

export const tablesTheme = defineMultiStyleConfig({
  // Define the parts you're going to style
  baseStyle: {
    table: {
      borderRadius: "md", // Apply rounded corners to the entire table
      border: "1px solid", // Consistent border around the table
      borderColor: "gray.200", // Adjust border color as per your theme
      overflow: "hidden"
    },
    thead: {
      bg: "primary.backgroundLight",
      borderTopRadius: "md" // Rounded top for the table head
    },

    tbody: {
      tr: {
        borderBottom: "1px solid", // Border between rows
        borderColor: "gray.200",
        background: "white",
        fontWeight: "500"
      },
      td: {
        borderBottom: "1px solid", // Bottom border for table cells
        borderColor: "gray.200",
        paddingInline: "4px",
        paddingBlock: "8px"
      },
      padding: 0
    }
  }
});
