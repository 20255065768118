import { CheckCircleIcon } from "@chakra-ui/icons";
import { Box, Card, Flex, Heading, Spinner, Text } from "@chakra-ui/react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { db } from "../../api/firebaseApi";
import { UpdateOverviewStep } from "../../redux/clients/clientsSlice";
import {
  addOverviewStepsToIndiv,
  updateOverviewStep
} from "../../redux/individual/individualSlice";
import { individualSelectors } from "../../redux/individual/selectors";
import { DATABASE } from "../../types/tables-data";

import { clickedStyles } from "../../styles/common-styles";
import styles from "../../styles/individual-overview.module.css";
import { overviewTab } from "../../testTableData";

export const IndividualOverview = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const indivSteps = useSelector(individualSelectors.selectOverview);
  const uid = id ?? useSelector(individualSelectors.selectUid);

  const [stepValue, setStepValue] = useState<UpdateOverviewStep | null>(null);

  const handleUpdateOverviewData = async () => {
    try {
      const indivRef = doc(db, DATABASE.INDIVIDUALS, `${uid}`);

      await updateDoc(indivRef, {
        "overview.steps": indivSteps.steps
      });
    } catch (e) {
      console.error("Error is:", e);
    }
  };

  const addOverViewSteps = async () => {
    try {
      const indivRef = doc(db, DATABASE.INDIVIDUALS, `${uid}`);

      await updateDoc(indivRef, {
        overview: overviewTab
      });
      dispatch(addOverviewStepsToIndiv(overviewTab));
    } catch (e) {
      console.error("Error is:", e);
    }
  };

  useEffect(() => {
    handleUpdateOverviewData();
  }, [indivSteps]);

  useEffect(() => {
    if (stepValue) {
      dispatch(updateOverviewStep(stepValue));
      setStepValue(null);
    }
  }, [stepValue]);

  useEffect(() => {
    const fetchStepsFromDb = async () => {
      try {
        const indivRef = doc(db, DATABASE.INDIVIDUALS, `${uid}`);
        const indivSnapshot = await getDoc(indivRef);
        const indivData = indivSnapshot.data() || {};

        if (
          Array.isArray(indivData.overview?.steps) &&
          indivData.overview.steps.length > 0
        ) {
          dispatch(addOverviewStepsToIndiv(indivData.overview));
        } else {
          addOverViewSteps();
        }
      } catch (e) {
        console.error("Error fetching steps from the database:", e);
      }
    };

    if (uid) {
      fetchStepsFromDb();
    }
  }, [uid]);

  const indexActiveStep = indivSteps?.steps?.findIndex(
    (step: any) => !step.isComplete
  );

  return (
    <Box ml="0">
      <Box py="15px" width="100%" my="60px">
        <Flex className={styles.steps_container}>
          {indivSteps?.steps?.map((step: any, i: number) => (
            <Card
              key={i}
              p="4" // using Chakra's sizing scale for padding
              alignItems="center"
              gap="4" // consistent spacing based on Chakra's scale
              className={`${styles.step_box} shadow-smooth`} // assuming shadow-smooth is a custom soft shadow style
              textAlign="center"
              justifyContent="center"
              bg={step.isComplete ? "primary.background" : "secondary.white"}
              border="1px solid"
              borderColor={
                step.isComplete ? "transparent" : "secondary.lightGray"
              }
              borderRadius="lg" // using Chakra's border radius scale
              transition="all 0.2s ease-in-out" // smooth transition for hover effects
              _hover={{
                transform: step.isComplete ? "translateY(-2px)" : "", // slight raise effect on hover
                boxShadow: step.isComplete ? "lg" : "" // using Chakra's shadow scale for larger shadow on hover
              }}
            >
              {step.isComplete ? (
                <CheckCircleIcon
                  color="primary.blue"
                  w="10" // using Chakra's sizing scale
                  h="10"
                  cursor="pointer"
                  onClick={() => {
                    setStepValue({
                      stepIndex: i,
                      value: !step.isComplete
                    });
                  }}
                />
              ) : (
                <Flex
                  w="10"
                  h="10"
                  borderRadius="full"
                  bgColor="secondary.offWhite"
                  justifyContent="center"
                  alignItems="center"
                  fontSize="xl" // larger font size for the number
                  color="primary.blue"
                  cursor="pointer"
                  onClick={() => {
                    setStepValue({
                      stepIndex: i,
                      value: !step.isComplete
                    });
                  }}
                >
                  {indexActiveStep === i ? (
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="secondary.lightGray"
                      color="primary.blue"
                      size="md" // medium spinner size
                    />
                  ) : (
                    `${i + 1}`
                  )}
                </Flex>
              )}
              <Text
                color="primary.blue"
                mb="1"
                fontSize="lg"
                fontWeight="bold"
              >
                Step {i + 1}
              </Text>
              <Text color="secondary.darkGray" fontSize="sm">
                {step.description}
              </Text>
            </Card>
          ))}
        </Flex>
      </Box>
    </Box>
  );
};