import { Button, ChakraProps, Image } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import images from "../../assets/image-export-assets";
import {
  getUserType,
  isSuperAdmin,
  showCTAButton
} from "../../helpers/helpers";
import { lawyerSelectors } from "../../redux/lawyer/selectors";
import { individualSelectors } from "../../redux/individual/selectors";

type CustomButtonProps = {
  title: string;
  type: "ai" | "standard";
  isLoading?: boolean;
  isDisabled?: boolean;
  isPremium?: boolean;
  onClick: () => void;
} & ChakraProps;
export const CustomButton = ({
  title,
  type,
  isLoading = false,
  isDisabled = false,
  isPremium = false,
  ...rest
}: CustomButtonProps) => {
  const loginType = getUserType();
  const { subscription } = useSelector(lawyerSelectors.selectAll);
  const isFreemium = subscription?.status === "freemium";

  const indivId = useSelector(individualSelectors.selectUid);
  const shouldShowCTAButton = showCTAButton(indivId);

  if (
    (type === "ai" && loginType === "individual" && !isSuperAdmin) ||
    !shouldShowCTAButton
  )
    return null;

  if (type === "ai")
    return (
      <Button
        isLoading={isLoading}
        isDisabled={isDisabled}
        {...rest}
        leftIcon={
          <Image
            className="group-hover:animate-pulse"
            boxSize="20px"
            objectFit="contain"
            src={
              isFreemium && isPremium
                ? images.crownIcon
                : images.caseBlinkWhiteIcon
            }
            alt="Extract information"
          />
        }
        variant="primaryFilled"
        my={4}
        width="fit-content"
        placeSelf="center"
      >
        {title}
      </Button>
    );

  return <Button {...rest}>{title}</Button>;
};
