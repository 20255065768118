import {
  Box,
  Button,
  HStack,
  Heading,
  Input,
  Select,
  Spinner,
  Text,
  Textarea,
  VStack,
  useToast
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useAdminUpdates from "../../../hooks/admin/useManageAdminUpdates";
import { lawyerSelectors } from "../../../redux/lawyer/selectors";
import { AdminUpdate } from "./AdminUpdate";
import { SpecialHeading } from "../../../components/individualTabs/PanelLayout";

const AdminUpdatesManager = () => {
  const toast = useToast();
  const {
    adminUpdates,
    createAdminUpdate,
    updateAdminUpdate,
    deleteAdminUpdate,
    isLoading,
    error,
    fetchAdminUpdates
  } = useAdminUpdates();

  useEffect(() => {
    fetchAdminUpdates({ isAdmin: true });
  }, []);

  const { firstName, lastName } = useSelector(lawyerSelectors.selectAll);

  const initialAdminUpdateState: AdminUpdate = {
    id: undefined,
    title: "",
    description: "",
    status: "active",
    priority: "medium",
    author: `${firstName} ${lastName}`
  };

  const [currentAdminUpdate, setCurrentAdminUpdate] = useState<
    Partial<AdminUpdate>
  >(initialAdminUpdateState);

  const handleCreateOrUpdateAdminUpdate = () => {
    const updateDetails: AdminUpdate = {
      ...initialAdminUpdateState,
      ...currentAdminUpdate,
      author: currentAdminUpdate.author || `${firstName} ${lastName}`
    };

    if (currentAdminUpdate.id) {
      updateAdminUpdate(currentAdminUpdate.id, updateDetails);
      toast({
        title: "Admin update updated.",
        status: "success",
        duration: 5000,
        isClosable: true
      });
    } else {
      createAdminUpdate(updateDetails);
      toast({
        title: "Admin update created.",
        status: "success",
        duration: 5000,
        isClosable: true
      });
    }

    setCurrentAdminUpdate(initialAdminUpdateState);
  };

  const handleEditAdminUpdate = (adminUpdate: AdminUpdate) => {
    setCurrentAdminUpdate(adminUpdate);
  };

  return (
    <Box
      width="100%"
      p={5}
      bg="white"
      borderRadius="lg"
      boxShadow="md"
      textAlign="left"
    >
      <SpecialHeading title="Admin Updates Manager" mb={5} textAlign="left" />

      {isLoading && <Spinner color="primary.blue" />}
      {error && <Text color="primary.red">Error: {error}</Text>}
      <VStack spacing={4} mb={5} textAlign="left">
        <Heading as="h2" size="lg" mb={3}>
          {currentAdminUpdate.id
            ? "Edit Admin Update"
            : "Create New Admin Update"}
        </Heading>
        <Input
          variant="filled"
          placeholder="Title"
          value={currentAdminUpdate.title || ""}
          onChange={(e) =>
            setCurrentAdminUpdate({
              ...currentAdminUpdate,
              title: e.target.value
            })
          }
        />
        <Textarea
          variant="filled"
          placeholder="Description"
          value={currentAdminUpdate.description || ""}
          onChange={(e) =>
            setCurrentAdminUpdate({
              ...currentAdminUpdate,
              description: e.target.value
            })
          }
        />
        <Select
          variant="filled"
          value={currentAdminUpdate.status || "active"}
          onChange={(e) =>
            setCurrentAdminUpdate({
              ...currentAdminUpdate,
              status: e.target.value as "active" | "inactive" | "resolved"
            })
          }
        >
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
          <option value="resolved">Resolved</option>
        </Select>
        <Select
          variant="filled"
          value={currentAdminUpdate.priority || "medium"}
          onChange={(e) =>
            setCurrentAdminUpdate({
              ...currentAdminUpdate,
              priority: e.target.value as "low" | "medium" | "high"
            })
          }
        >
          <option value="low">Low</option>
          <option value="medium">Medium</option>
          <option value="high">High</option>
        </Select>
        <Button
          variant="primaryFilled"
          onClick={handleCreateOrUpdateAdminUpdate}
        >
          {currentAdminUpdate.id
            ? "Update Admin Update"
            : "Create Admin Update"}
        </Button>
      </VStack>
      <Box>
        <Heading as="h2" size="lg" mb={3}>
          Existing Admin Updates
        </Heading>
        <VStack spacing={4}>
          {adminUpdates.map((adminUpdate) => (
            <Box
              key={adminUpdate.id}
              w="full"
              p={4}
              bg="white"
              borderRadius="md"
              boxShadow="xl"
            >
              <Heading as="h3" size="md">
                {adminUpdate.title}
              </Heading>
              <Text color="primary.darkGray">{adminUpdate.description}</Text>
              <HStack mt={2}>
                <Text>Status: {adminUpdate.status}</Text>
                <Text>Priority: {adminUpdate.priority}</Text>
              </HStack>
              <HStack mt={3}>
                <Button
                  variant="destructiveFilled"
                  size="sm"
                  onClick={() => deleteAdminUpdate(adminUpdate.id!)}
                >
                  Delete
                </Button>
                <Button
                  variant="secondaryFilled"
                  size="sm"
                  onClick={() => handleEditAdminUpdate(adminUpdate)}
                >
                  Edit
                </Button>
                <Button
                  variant="tertiaryText"
                  size="sm"
                  onClick={() =>
                    updateAdminUpdate(adminUpdate.id!, { status: "resolved" })
                  }
                >
                  Mark as Resolved
                </Button>
              </HStack>
            </Box>
          ))}
        </VStack>
      </Box>
    </Box>
  );
};

export default AdminUpdatesManager;
