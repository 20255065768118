import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormErrorMessage,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Textarea
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import images from "../../assets/image-export-assets";

type AIToolbarProps = {
  showToolbar: boolean;
  changingText: boolean;
  quillRef: any;
  setSelectedText: (value: React.SetStateAction<string | undefined>) => void;
  handleAIButtonClick: (
    rewriteOption?: string,
    toneOption?: string,
    customPrompt?: string
  ) => Promise<void>;
  quillToolbarHeight: any;
  selectedRange: any;
  bounds: any;
  setAllowOverflow: React.Dispatch<React.SetStateAction<boolean>>;
};
const toneOptions = [
  "Formal",
  "Persuasive",
  "Concise",
  "Objective",
  "Educational"
];
const rewriteOptions = ["Make Shorter", "Make Longer", "Paraphrase"];

const MIN_HEIGHT = 200;

export const AIToolbar = ({
  quillToolbarHeight,
  showToolbar,
  changingText,
  quillRef,
  handleAIButtonClick,
  setSelectedText,
  selectedRange,
  bounds,
  setAllowOverflow
}: AIToolbarProps) => {
  const [promptText, setPromptText] = useState("");
  const [promptError, setPromptError] = useState<string | null>(null);
  const [aiToolbarPosition, setAiToolbarPosition] = useState({
    left: 0,
    top: 0
  });
  const aiToolbarRef = useRef<HTMLDivElement>(null);
  const [menuMaxHeight, setMenuMaxHeight] = useState<number | undefined>(
    undefined
  );

  // Editor container height, used to check for overflow
  const editorHeight = quillRef.current?.getEditor().root.clientHeight;
  const editorWidth = quillRef.current?.getEditor().root.clientWidth;

  useEffect(() => {
    if (aiToolbarRef && aiToolbarRef.current) {
      const aitoolbarWidth = aiToolbarRef.current.clientWidth;
      const aitoolbarHeight = aiToolbarRef.current.clientHeight;

      // Determine toolbar position adjustments
      const isPositionOverlap = bounds.top < quillToolbarHeight;

      const isAiToolbarTopOverflowing = bounds.height > editorHeight;
      const isAiToolbarBottomOverflowing =
        bounds.height + aitoolbarHeight > editorHeight;

      const calculatedLeft =
        bounds.left + aitoolbarWidth > editorWidth
          ? bounds.left - (aitoolbarWidth - bounds.width)
          : bounds.left;

      // Calculate top position based on overlap and overflow
      const baseTopPosition = isPositionOverlap
        ? bounds.top + bounds.height + quillToolbarHeight
        : bounds.top - 12;

      const calculatedTop =
        isAiToolbarTopOverflowing || isAiToolbarBottomOverflowing
          ? baseTopPosition - quillToolbarHeight
          : baseTopPosition;

      // Case the toolbar is bigger than the editor
      if (aitoolbarHeight >= editorHeight) {
        setAllowOverflow(true);
      } else {
        setAllowOverflow(false);
      }

      setAiToolbarPosition({ left: calculatedLeft, top: calculatedTop });

      const toolbarBottomPosition = calculatedTop + aitoolbarHeight;
      const remainingHeight = editorHeight - toolbarBottomPosition - 10;
      setMenuMaxHeight(
        remainingHeight > MIN_HEIGHT ? remainingHeight : MIN_HEIGHT
      );
    }
  }, [showToolbar, bounds, quillToolbarHeight, editorHeight, editorWidth]);

  return showToolbar ? (
    <div
      ref={aiToolbarRef}
      className="ai-toolbar min-w-36"
      style={{
        position: "absolute",
        left: `${aiToolbarPosition.left}px`,
        top: `${aiToolbarPosition.top}px`,
        zIndex: 2,
        // padding: "5px 10px",
        backgroundColor: "#f0f0f0",
        borderRadius: "5px",
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)"
      }}
    >
      {changingText ? (
        <div className="inline-flex gap-4 items-center px-4  ">
          <div>Changing text...</div>
          <CircularProgress isIndeterminate />
        </div>
      ) : (
        <div className="flex max-w-md">
          <Menu key="1" variant="prompt">
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              variant="neutralStateButton"
              rounded="0px"
              borderRadius={0}
              px={2}
            >
              Rewrite
            </MenuButton>
            <MenuList>
              {rewriteOptions.map((option) => (
                <MenuItem
                  key={option}
                  value={option}
                  onClick={() => handleAIButtonClick(option)}
                >
                  {option}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
          <div className="flex-1">
            <Divider orientation="vertical" mx={1} />
          </div>
          <Menu key="2" variant="prompt">
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              variant="neutralStateButton"
              rounded="0px"
              borderRadius={0}
              px={2}
            >
              Change tone
            </MenuButton>
            <MenuList maxHeight={`${menuMaxHeight}px`} overflowY="auto">
              {toneOptions.map((option) => (
                <MenuItem
                  key={option}
                  value={option}
                  onClick={() => handleAIButtonClick(undefined, option)}
                >
                  {option}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
          <div className="flex-1">
            <Divider orientation="vertical" mx={1} />
          </div>
          <Menu key="3" closeOnBlur={false}>
            <MenuButton
              as={Button}
              variant="neutralStateButton"
              rounded="0px"
              borderRadius={0}
              px={2}
              onClick={() => {
                const quill = quillRef.current?.getEditor();
                if (selectedRange) {
                  const getText = quill?.getText(
                    selectedRange.index,
                    selectedRange.length
                  );
                  if (getText) setSelectedText(getText);
                }
              }}
            >
              Prompt
            </MenuButton>
            <MenuList p={0} m={0} borderWidth={0} bg="white">
              <Box className="flex flex-col p-4 gap-2">
                <FormControl isInvalid={promptError !== null}>
                  <Textarea
                    value={promptText}
                    onChange={(e) => {
                      const text = e.target.value;
                      setPromptText(text);
                      if (text === "") {
                        setPromptError("Prompt can't be empty");
                      } else {
                        setPromptError(null);
                      }
                    }}
                    placeholder="Ask AI to edit or generate... "
                  />
                  <FormErrorMessage>{promptError}</FormErrorMessage>
                </FormControl>
                <Button
                  placeSelf="end"
                  variant="primaryOutline"
                  px={4}
                  onClick={async () => {
                    if (promptText === "") {
                      setPromptError("Prompt can't be empty");
                      return;
                    }
                    await handleAIButtonClick(undefined, undefined, promptText);
                    setPromptText("");
                  }}
                  leftIcon={<Image src={images.aiGenerate} />}
                >
                  Generate
                </Button>
              </Box>
            </MenuList>
          </Menu>
        </div>
      )}
    </div>
  ) : null;
};
