import { DraftsReducer, DraftType } from "./draftsSlice";

const selectAll = (state: DraftsReducer) => state.drafts;
const selectLegalBrief = (state: DraftsReducer) =>
  state.drafts.legalBrief as DraftType;
const selectExpertLetters = (state: DraftsReducer) =>
  state.drafts.expertLetters;

export const draftsSelectors = {
  selectLegalBrief,
  selectAll,
  selectExpertLetters
};
