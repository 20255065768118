import { BellIcon, CheckCircleIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  VStack,
  useColorModeValue,
  useDisclosure,
  useOutsideClick
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { MdInsertDriveFile } from "react-icons/md";
import { LuFileSearch } from "react-icons/lu";
import {
  RiAiGenerate,
  RiTimerFill,
  RiCheckboxCircleFill,
  RiCloseCircleFill
} from "react-icons/ri";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ReactComponent as CaseBlinkIconSvg } from "../../assets/logos/CaseBlink-white-icon.svg";
import { useSubscribeToJob } from "../../hooks/useSubscribeToJob";
import {
  EXTRACTIONSTATES,
  ExtractionJob,
  clearAllExtractions,
  clearCompletedExtractionType,
  clearNewExtractionType
} from "../../redux/extraction-jobs/extractionJobsSlice";
import { extractionSelectors } from "../../redux/extraction-jobs/selectors";
import { individualSelectors } from "../../redux/individual/selectors";
import { RootState } from "../../redux/store";
import { formatDate } from "../../helpers/date_helpers";

interface TaskProps {
  jobs: ExtractionJob[];
  getJobLink: (jobType: ExtractionJob) => string;
}

const getIcon = (type: string) => {
  console.log("getIcon type : ", type);
  if (type.toLowerCase().includes("extraction")) {
    return LuFileSearch;
  }
  if (type.toLowerCase().includes("generation")) {
    return RiAiGenerate;
  }
  return MdInsertDriveFile;
};

const TaskList: React.FC<TaskProps> = ({ jobs, getJobLink }) => {
  const bgColor = useColorModeValue("background.cloud", "background.darkWhite");
  const hoverBgColor = useColorModeValue(
    "primary.focusOutline",
    "primary.focusOutline"
  );
  const dateColor = useColorModeValue(
    "text.graySecondary.smog",
    "text.display.dark"
  );

  return (
    <VStack alignItems="start" w="100%">
      {jobs.length === 0 && (
        <Box p={4}>
          <Text fontSize="lg" color="text.graySecondary.smog">
            No tasks here.
          </Text>
        </Box>
      )}
      {jobs.map((job) => {
        return (
          <React.Fragment key={job.id}>
            <Link
              href={getJobLink(job)}
              px={4}
              py={2}
              w="100%"
              minHeight="10vh"
              bg={bgColor}
              borderRadius="md"
              transition="background-color 0.2s"
              _hover={{ bg: hoverBgColor, textDecoration: "none" }}
            >
              <HStack alignItems="center" justifyContent="space-between">
                <HStack alignItems="center">
                  <Box
                    boxSize="40px"
                    borderRadius="full"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexShrink={0}
                  >
                    <Icon
                      as={getIcon(job.type)}
                      boxSize={7}
                      color="primary.blue"
                    />
                  </Box>
                  <VStack alignItems="start" textAlign="left" spacing={0}>
                    <Text fontSize="md" fontWeight="bold" opacity="0.6">
                      {job.type}
                    </Text>
                    <Text color="text.display.light">{job.docName || ""}</Text>
                    <Text fontSize="sm" color={dateColor}>
                      Created on: {formatDate(job.created_at)}
                    </Text>
                  </VStack>
                </HStack>
              </HStack>
            </Link>
            <Divider />
          </React.Fragment>
        );
      })}
    </VStack>
  );
};

const InProgressNotification: React.FC<{ newExtractionType: string }> = ({
  newExtractionType
}) => (
  <Flex
    columnGap={4}
    p={4}
    bg="white"
    boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
    borderRadius="lg"
    position="absolute"
    top="50px"
    right="8px"
    zIndex="tooltip"
    width="340px"
  >
    <Flex
      bg="primary.blue"
      w="50px"
      h="50px"
      borderRadius="12px"
      alignItems="center"
      justifyContent="center"
    >
      <CaseBlinkIconSvg style={{ width: "36px", height: "36px" }} />
    </Flex>
    <Flex flexDirection="column" alignItems="start" alignContent="start">
      <Text
        fontSize="md"
        fontWeight="bold"
        color="primary.blue"
        textAlign="start"
      >
        {newExtractionType}
      </Text>
      <Text fontSize="lg" fontWeight="lighter" color="primary.blue">
        In Progress
      </Text>
    </Flex>
  </Flex>
);

const CompletionNotification: React.FC<{
  completedExtraction: ExtractionJob;
  getJobLink: (jobType: ExtractionJob) => string;
}> = ({ completedExtraction, getJobLink }) => (
  <Link href={getJobLink(completedExtraction)}>
    <Flex
      cursor="pointer"
      columnGap={4}
      p={4}
      bg="white"
      boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
      borderRadius="lg"
      position="absolute"
      top="50px"
      right="8px"
      zIndex="tooltip"
      width="340px"
    >
      <Flex
        bg="primary.green"
        w="50px"
        h="50px"
        borderRadius="12px"
        alignItems="center"
        justifyContent="center"
      >
        <CheckCircleIcon
          style={{ width: "40px", height: "40px", color: "green" }}
        />
      </Flex>
      <Flex flexDirection="column" alignItems="start">
        <Text
          fontSize="lg"
          fontWeight="bold"
          color="primary.green"
          textAlign="start"
        >
          {completedExtraction.type}
        </Text>
        <Text fontSize="md" fontWeight="lighter" color="primary.green">
          Completed
        </Text>
      </Flex>
    </Flex>
  </Link>
);

interface TaskBoxProps {
  inProgressTasks: ExtractionJob[];
  completedTasks: ExtractionJob[];
  failedTasks: ExtractionJob[];
  getJobLink: (job: ExtractionJob) => string;
  closeTasksBox: () => void;
}

const TaskBox: React.FC<TaskBoxProps> = ({
  inProgressTasks,
  completedTasks,
  failedTasks,
  getJobLink,
  closeTasksBox
}) => {
  const unselectedColor = useColorModeValue(
    "primary.blue",
    "secondary.darkGray"
  );
  const selectedColor = useColorModeValue("secondary.darkGray", "primary.blue");

  // Disclosure hooks for modals
  const {
    isOpen: isCompletedOpen,
    onOpen: onCompletedOpen,
    onClose: onCompletedClose
  } = useDisclosure();
  const {
    isOpen: isInProgressOpen,
    onOpen: onInProgressOpen,
    onClose: onInProgressClose
  } = useDisclosure();
  const {
    isOpen: isFailedOpen,
    onOpen: onFailedOpen,
    onClose: onFailedClose
  } = useDisclosure();

  const renderTaskPanel = (
    title: string,
    jobs: ExtractionJob[],
    modalIsOpen: boolean,
    onOpenModal: () => void,
    onCloseModal: () => void
  ) => (
    <>
      <TaskList jobs={jobs.slice(0, 4)} getJobLink={getJobLink} />
      {jobs.length >= 4 && (
        <Button variant="secondaryOutline" onClick={onOpenModal} mt={4}>
          See All
        </Button>
      )}
      <Modal isOpen={modalIsOpen} onClose={onCloseModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{`All ${title} Jobs`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box maxHeight="400px" overflowY="scroll">
              <TaskList jobs={jobs} getJobLink={getJobLink} />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );

  return (
    <Box
      transition="1s ease-in-out"
      p="4"
      bg="white"
      boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
      borderRadius="lg"
      position="absolute"
      top="50px"
      right="8px"
      zIndex="modal"
      width="30vw"
    >
      <Tabs variant="soft-rounded" defaultIndex={1}>
        <TabList pb={2} p={4}>
          <Tab
            _selected={{ bg: "primary.blue", color: "white" }}
            bg="secondary.lightGray"
          >
            <RiTimerFill style={{ marginRight: 8 }} />
            <Text fontSize="12px">In Progress</Text>
          </Tab>
          <Tab
            _selected={{ bg: "primary.blue", color: "white" }}
            bg="secondary.lightGray"
          >
            <RiCheckboxCircleFill style={{ marginRight: 8 }} />
            <Text fontSize="12px">Completed</Text>
          </Tab>
          <Tab
            _selected={{ bg: "primary.blue", color: "white" }}
            bg="secondary.lightGray"
          >
            <RiCloseCircleFill style={{ marginRight: 8 }} />
            <Text fontSize="12px">Failed</Text>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {renderTaskPanel(
              "In Progress",
              inProgressTasks,
              isInProgressOpen,
              onInProgressOpen,
              onInProgressClose
            )}
          </TabPanel>
          <TabPanel>
            {renderTaskPanel(
              "Completed",
              completedTasks,
              isCompletedOpen,
              onCompletedOpen,
              onCompletedClose
            )}
          </TabPanel>
          <TabPanel>
            {renderTaskPanel(
              "Failed",
              failedTasks,
              isFailedOpen,
              onFailedOpen,
              onFailedClose
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

const ExtractionStatusDialog: React.FC = () => {
  const dispatch = useDispatch();
  const tasks: ExtractionJob[] = useSelector(extractionSelectors.jobs);
  const newJob: ExtractionJob | null = useSelector(extractionSelectors.newJob);
  const [seenTasks, setSeenTasks] = useState<Record<string, boolean>>({});

  const completedJob: ExtractionJob | null = useSelector(
    extractionSelectors.completedJob
  );
  const { fetchActiveJobs, subscribeToJob } = useSubscribeToJob();
  const { id, visaType } = useParams<{ id: string; visaType: string }>();

  const indivId = useSelector((state: RootState) =>
    individualSelectors.selectUid(state)
  );
  const isIndiviudal = indivId ?? false;

  if (isIndiviudal) return null;

  const isProcessingOrPending = (status: EXTRACTIONSTATES) =>
    status &&
    [EXTRACTIONSTATES.Processing, EXTRACTIONSTATES.Pending].includes(status);

  const inProgressTasks = useMemo(
    () => tasks.filter((task) => isProcessingOrPending(task.status?.status)),
    [tasks]
  );

  const completedTasks = useMemo(
    () =>
      tasks.filter(
        (task) => task.status?.status === EXTRACTIONSTATES.Completed
      ),
    [tasks]
  );

  const failedTasks = useMemo(
    () =>
      tasks.filter((task) => task.status?.status === EXTRACTIONSTATES.Failed),
    [tasks]
  );

  const [tasksBoxVisible, setTasksBoxVisible] = useState(false);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const completedJobsNotified = useRef(new Set<string>());

  const toggleTasksBox = () => {
    setTasksBoxVisible((prev) => !prev);
    if (!tasksBoxVisible && notificationVisible) {
      setNotificationVisible(false);
    }
  };
  const closeTasksBox = () => setTasksBoxVisible(false);

  const getJobLink = (job: ExtractionJob) => {
    if (!job.id) return "";

    switch (job.type) {
      case "Evidence extraction":
        return `/individual/${id}/${visaType}/documents/evidence-documents/extracted-information`;
      case "Standard Documents Extraction":
        return `/individual/${id}/${visaType}/documents/standard-documents/extracted-information`;
      case "Expert letter draft generation":
        return `/individual/${id}/${visaType}/drafts/expert-letters/${job.id}/edit`;
      case "Exhibit List":
        return `/individual/${id}/${visaType}/drafts/exhibit-list/edit`;
      case "Legal Brief Generation":
        return `/individual/${id}/${visaType}/drafts/legal-brief/edit`;
      case "Case research generation":
        return `/individual/${id}/${visaType}/case-research/extracted-information`;
      default:
        return `/${job.id}`;
    }
  };
  useEffect(() => {
    if (isIndiviudal) return;
    dispatch(clearAllExtractions());
    const initJobListening = async () => {
      if (id) {
        const activeJobs = await fetchActiveJobs(id);
        activeJobs.forEach((job) => subscribeToJob(job));
      }
    };
    initJobListening();
  }, [id]);

  useEffect(() => {
    if (newJob) {
      console.log(`New extraction type detected: ${newJob}`);
      setNotificationVisible(true);
      setTimeout(() => {
        console.log(`Clearing new extraction type: ${newJob}`);
        dispatch(clearNewExtractionType());
        setNotificationVisible(false);
      }, 4000);
    }
  }, [newJob]);

  useEffect(() => {
    if (completedJob) {
      console.log(`Completed extraction type detected: ${completedJob.type}`);

      if (
        !completedJobsNotified.current.has(completedJob.type) ||
        completedJob.type === "Standard Documents Extraction"
      ) {
        setNotificationVisible(true);
        completedJobsNotified.current.add(completedJob.type);

        setTimeout(() => {
          console.log(
            `Clearing completed extraction type: ${completedJob.type}`
          );
          dispatch(clearCompletedExtractionType());
          setNotificationVisible(false);
        }, 4000);
      }
    }
  }, [completedJob]);

  const tooltipRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: tooltipRef,
    handler: closeTasksBox
  });

  if (isIndiviudal) return null;

  return (
    <Box zIndex="tooltip">
      <Box className="relative" ref={tooltipRef}>
        <Box display="flex" alignItems="center">
          <Tooltip placement="bottom-end" isOpen={tasksBoxVisible}>
            <Box
              position="relative"
              display="inline-block"
              onClick={toggleTasksBox}
            >
              <IconButton
                variant="filledIconButton"
                icon={<BellIcon boxSize={8} />}
                aria-label="Notifications"
              />
              {inProgressTasks.length > 0 && (
                <Badge
                  bg="primary.red"
                  variant="solid"
                  position="absolute"
                  top="2"
                  right="1"
                  pb="1"
                  borderRadius="full"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="14px"
                  width="14px"
                  fontSize="10"
                >
                  ...
                </Badge>
              )}
            </Box>
          </Tooltip>
        </Box>
        {notificationVisible && !tasksBoxVisible && newJob && (
          <InProgressNotification newExtractionType={newJob.type} />
        )}
        {notificationVisible && !tasksBoxVisible && completedJob && (
          <CompletionNotification
            completedExtraction={completedJob}
            getJobLink={getJobLink}
          />
        )}
        {tasksBoxVisible && (
          <TaskBox
            inProgressTasks={inProgressTasks}
            completedTasks={completedTasks}
            failedTasks={failedTasks}
            getJobLink={getJobLink}
            closeTasksBox={closeTasksBox}
          />
        )}
      </Box>
    </Box>
  );
};

export default ExtractionStatusDialog;
