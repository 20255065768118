import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
  Textarea,
  Input,
  FormErrorMessage,
  Flex,
  Icon
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MdErrorOutline, MdDescription, MdLink } from "react-icons/md";

type Props = {
  onConfirm: (data: SourceFormData) => void;
  setShowSourceDialog: (value: boolean) => void;
  isLoading: boolean;
};

interface SourceFormData {
  description: string;
  link: string;
  exhibit: number;
  createdAt: string;
  type: string;
}

const validationSchema = Yup.object().shape({
  description: Yup.string().required("Description is required"),
  link: Yup.string().url("Invalid URL format").required("Link is required")
});

export const AddSourceDialog = ({
  onConfirm,
  setShowSourceDialog,
  isLoading
}: Props) => {
  const initialValues: SourceFormData = {
    description: "",
    link: "",
    exhibit: -1,
    type: "Web link",
    createdAt: ""
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      onConfirm(values);
      setShowSourceDialog(false);
    }
  });

  return (
    <Modal
      isOpen
      onClose={() => {
        setShowSourceDialog(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Source</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={formik.handleSubmit}>
          <ModalBody pb={6}>
            <FormControl
              isInvalid={
                formik.touched.description && !!formik.errors.description
              }
            >
              <Flex align="center">
                <Icon as={MdDescription} mr={2} />
                <FormLabel>Description</FormLabel>
              </Flex>
              <Textarea
                name="description"
                placeholder="Enter description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FormErrorMessage>
                <Flex align="center">
                  <Icon as={MdErrorOutline} mr={2} />{" "}
                  {formik.errors.description}
                </Flex>
              </FormErrorMessage>
            </FormControl>
            <FormControl
              mt={4}
              isInvalid={formik.touched.link && !!formik.errors.link}
            >
              <Flex align="center">
                <Icon as={MdLink} mr={2} />
                <FormLabel>Link</FormLabel>
              </Flex>
              <Input
                name="link"
                placeholder="Paste the link here"
                value={formik.values.link}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FormErrorMessage>
                <Flex align="center">
                  <Icon as={MdErrorOutline} mr={2} /> {formik.errors.link}
                </Flex>
              </FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="secondaryOutline"
              onClick={() => {
                setShowSourceDialog(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primaryFilled"
              type="submit"
              isLoading={isLoading}
              disabled={!formik.isValid}
            >
              Add Source
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
