import { accordionAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  button: {
    textColor: "text.color",
    fontWeight: "600"
  },
  container: {
    border: "1px solid #E2E8F0",
    borderRadius: "8px",
    borderColor: "#E2E8F0"
  }
});

export const accordionTheme = defineMultiStyleConfig({ baseStyle });
