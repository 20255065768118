import { ExternalLinkIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { Tooltip, IconButton, Skeleton, Flex } from "@chakra-ui/react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import { openFilePreview } from "../helpers/helpers";
import { DataDocs } from "../types/tables-data";

const columnHelper = createColumnHelper<DataDocs>();

type ActionsCallBack = {
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
};
export const generatedColumnsWithActions = ({
  onDelete,
  onEdit
}: ActionsCallBack) => {
  const standardDocumentsColumns: ColumnDef<DataDocs, any>[] = [
    {
      accessorFn: (row) =>
        (row?.type as any)?.title || row?.type || row?.documentTitle || "",
      header: "Type",
      id: "type",
      cell: (cell) => cell.getValue()
    },
    columnHelper.accessor("docNames", {
      cell: ({ row }) =>
        row.depth === 1 && (
          <Tooltip label={row.original.docNames}>
            <div>
              {row.original.docNames?.substring(0, 15).concat("...")}{" "}
              <ExternalLinkIcon
                className="cursor-pointer"
                onClick={() => openFilePreview(row.original.docUrl)}
                ml={2}
              />
            </div>
          </Tooltip>
        ),
      header: "Document Name"
    }),

    columnHelper.accessor("status.status", {
      cell: (info) => info.getValue(),
      header: "Status"
    }),

    {
      accessorFn: (row) => row.criterion,
      header: "Criterion",
      id: "criterion",
      cell: ({ row, getValue }) =>
        row.depth === 0 ? (
          <div className="flex justify-start items-center gap-1">
            <IconButton
              variant="filledIconButton"
              onClick={row.getToggleExpandedHandler()}
              aria-label=""
              icon={
                row.getIsExpanded() ? (
                  <MdOutlineExpandLess />
                ) : (
                  <MdOutlineExpandMore />
                )
              }
            />
            <div className="flex items-center">
              {(getValue() as string) || <Skeleton w={20} h={8} />}
            </div>
          </div>
        ) : null // Do not render anything for non-group rows
    },
    columnHelper.display({
      id: "delete",
      header: "Actions",
      cell: ({ row }) =>
        row.depth === 1 && (
          <Flex gap={2}>
            <IconButton
              variant="filledIconButton"
              icon={<DeleteIcon />}
              isDisabled={row.original.status?.status === "Processing"}
              onClick={() => {
                onDelete(row.original.id!);
              }}
              aria-label=""
            />
            <IconButton
              variant="filledIconButton"
              icon={<EditIcon />}
              isDisabled={row.original.status?.status === "Failed"}
              onClick={() => {
                onEdit(row.original.id!);
                // const link = `/individual/${id}/${visaType}/documents/standard-documents/${row.original.id}`;
                // navigate(link);
              }}
              aria-label=""
            />
          </Flex>
        )
    })
  ];

  return {
    standardDocumentsColumns
  };
};
