import { Flex, useToast } from "@chakra-ui/react";
import { doc } from "firebase/firestore";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { db } from "../../../api/firebaseApi";
import { generateDraft } from "../../../api/OpenAIApi";
import useFirestoreDocument from "../../../hooks/useFirestoreDocument";
import { useSubscribeToJob } from "../../../hooks/useSubscribeToJob";
import { useUserRoleAndSubscription } from "../../../hooks/useUserRoleAndSubscription";
import {
  ExtractionJob,
  ExtractionReducer,
  EXTRACTIONSTATES,
  GenerationStatesDescription
} from "../../../redux/extraction-jobs/extractionJobsSlice";
import { extractionSelectors } from "../../../redux/extraction-jobs/selectors";
import { individualSelectors } from "../../../redux/individual/selectors";
import { lawyerSelectors } from "../../../redux/lawyer/selectors";
import { DraftDocument } from "../../../types/drafts.type";
import { DATABASE } from "../../../types/tables-data";
import { CustomAlertDialog } from "../../common/CustomAlertDialog";
import { CustomButton } from "../../common/CustomButton";
import { ExtractionCard } from "../../common/ExtractionCard";
import { PremiumModal } from "../../common/PremiumModal";
import { SpecialHeading } from "../PanelLayout";
import { DocumentsInfoModal } from "../../common/DocumentsInfoModal";
import { ConfirmationDialog } from "../../common/ConfimationDialog";

export const ExhibitListSection = () => {
  const { id } = useParams();
  const uid = id ?? useSelector(individualSelectors.selectUid);
  const toast = useToast();

  const [showDeletionDialog, setShowDeletionDialog] = useState(false);
  const [showOverrideDialog, setShowOverrideDialog] = useState<boolean>(false);
  const [premiumModalOpen, setPremiumModalOpen] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const { document, isFetchCalled, deleteDocumentFields, listenAndFetch } =
    useFirestoreDocument<DraftDocument>(DATABASE.DRAFTS, uid, false);
  const { subscription } = useSelector(lawyerSelectors.selectAll);
  const isFreemium = subscription?.status === "freemium";

  const { initiateJob, resetFields } = useSubscribeToJob();
  const exhibitListJob = useSelector((state: ExtractionReducer) =>
    extractionSelectors.selectJobById(state, "Exhibit List" ?? "")
  );

  const {
    isSuperAdmin,
    isFullUser,
    isStandardUser,
    isPaidUser,
    isFreemiumUser
  } = useUserRoleAndSubscription();

  const statusMsg = useMemo(() => {
    const state = exhibitListJob?.status?.status as EXTRACTIONSTATES;
    return (
      GenerationStatesDescription[state] ??
      GenerationStatesDescription.NotStarted
    );
  }, [exhibitListJob]);

  const status = exhibitListJob?.status?.status;

  const isGenerated = useMemo(
    () => status === EXTRACTIONSTATES.Completed,
    [status]
  );

  const isGenerating = useMemo(
    () => status === EXTRACTIONSTATES.Processing,
    [status]
  );

  const handleExhibitListGeneration = async () => {
    // Initiating db real-time tracking
    const job: ExtractionJob = {
      id: "Exhibit List",
      type: "Exhibit List",
      status: {
        status: EXTRACTIONSTATES.Pending
      },
      docRef: doc(db, DATABASE.DRAFTS, `${uid}`),
      jobRef: doc(db, DATABASE.ACTIVE_JOBS, `${uid}`, "jobs", "Exhibit List"),
      toastTitle: "Job Completed",
      toastDesc: "The generation job has been completed.",
      docName: "",
      // Define firestore document "status" field path to get the nested status
      fieldPath: "exhibit_list"
    };

    await initiateJob(job);
    await generateDraft({
      individualId: uid,
      draftType: "exhibit_list",
      useAsync: true,
      expertId: null
    });

    listenAndFetch();
  };

  const onConfirmationDialogClose = () => {
    setShowConfirmationDialog(false);
  };

  const onConfirmationDialogConfirm = () => {
    onConfirmationDialogClose();
    handleExhibitListGeneration();
  };

  const openDialog = async () => {
    if (isFreemium) {
      setPremiumModalOpen(true);
      return;
    }
    if (isGenerated) {
      setShowOverrideDialog(true);
      return;
    }
    setShowConfirmationDialog(true);
  };

  const handleDelete = async () => {
    if (exhibitListJob) {
      await resetFields(exhibitListJob, EXTRACTIONSTATES.NotStarted);
    }
    deleteDocumentFields(["exhibit_list"]);
    setShowDeletionDialog(false);
  };

  return (
    <>
      <Flex flexDirection="column" justifyContent="start" rowGap="20px">
        <SpecialHeading
          title="Exhibit List"
          withInfo={<DocumentsInfoModal title="Exhibit list" />}
        />

        <ExtractionCard
          locked={
            !(isSuperAdmin || isFullUser || (isPaidUser && isStandardUser))
          }
          type="drafts/exhibit-list"
          id={id}
          statusMsg={statusMsg}
          extractedData={document}
          onExtractionDelete={() => {
            setShowDeletionDialog(true);
          }}
        />

        <CustomButton
          type="ai"
          title="Generate"
          isPremium
          isDisabled={
            !(
              isSuperAdmin ||
              isFullUser ||
              isFreemiumUser ||
              (isPaidUser && isStandardUser)
            )
          }
          isLoading={isGenerating}
          onClick={openDialog}
        />
      </Flex>

      <PremiumModal
        isOpen={premiumModalOpen}
        onClose={() => {
          setPremiumModalOpen(false);
        }}
      />
      {/* Confirmation Dialog */}
      <ConfirmationDialog
        isOpen={showConfirmationDialog}
        onClose={onConfirmationDialogClose}
        onConfirm={onConfirmationDialogConfirm}
        body="Please confirm that you have reviewed the document categories and summaries in the Evidence Documents tab."
      />
      {/* Delete Dialog */}
      <CustomAlertDialog
        disabled={isGenerating}
        key="delete"
        withButton={false}
        isOpen={showDeletionDialog}
        alertType="delete"
        alertTitle="Warning!"
        alertDescription="Are you sure you want to delete the current generated exhibit list?"
        confirm={handleDelete}
        close={() => {
          setShowDeletionDialog(false);
        }}
      />
      {/* Replace Dialog */}
      <CustomAlertDialog
        key="replace"
        withButton={false}
        isOpen={showOverrideDialog}
        alertType="replace"
        alertTitle="Warning!"
        alertDescription="You already generated an exhibit list. Would you like to replace it?"
        confirm={() => {
          setShowOverrideDialog(false);
          setShowConfirmationDialog(true);
        }}
        close={() => {
          setShowOverrideDialog(false);
        }}
      />
    </>
  );
};
