import { createSelector } from "@reduxjs/toolkit";
import { FormsReducer, FormsSliceData } from "./formsSlice";

const selectExpertLettersForms = (state: FormsReducer) =>
  state.forms.expertLettersForms;
const selectExpertLetterFormById = createSelector(
  [
    selectExpertLettersForms,
    (state: FormsReducer, expertId: string) => expertId
  ],
  (expertLettersForms, expertId) => {
    return expertLettersForms?.find((form) => form.uid === expertId);
  }
);

export const formsSelectors = {
  selectExpertLettersForms,
  selectExpertLetterFormById
};
