import { PhoneIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Card,
  Flex,
  FormLabel,
  HStack,
  IconButton,
  Skeleton,
  SkeletonText,
  Text
} from "@chakra-ui/react";

export const ExpertInfoSkeleton = () => {
  return (
    <Box py={5}>
      <Flex
        flexDirection="column"
        justifyContent="start"
        alignContent="start"
        rowGap={8}
      >
        {/* Full name field skeleton */}
        <Skeleton height="40px" borderRadius={4} width="40%" />

        {/* Organization field skeleton */}
        <Skeleton height="40px" borderRadius={4} width="40%" />

        {/* Current role field skeleton */}
        <Skeleton height="40px" borderRadius={4} width="40%" />

        {/* Email field skeleton */}
        <Skeleton height="40px" borderRadius={4} width="40%" />

        {/* Phone input field skeleton */}
        <HStack width="20%">
          <Skeleton height="40px" borderRadius={4} width="60px">
            <IconButton icon={<PhoneIcon />} aria-label="Country code" />
          </Skeleton>
          <Skeleton height="40px" width="20%" borderRadius={4} flex="1" />
        </HStack>
      </Flex>
    </Box>
  );
};

export const ProngsLoading = () => {
  return (
    <Card ml="0">
      <Accordion allowMultiple>
        {/* Prong 1 */}
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <Text> Prong 1 questions</Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel p={10}>
            <FormLabel mb={2} fontWeight={700}>
              What is the applicant's area of expertise?
            </FormLabel>
            <SkeletonText my="8" noOfLines={4} spacing="4" skeletonHeight="3" />

            <FormLabel mb={2} fontWeight={700}>
              Does the applicant have a specialization area within their area of
              expertise?
            </FormLabel>
            <SkeletonText my="8" noOfLines={4} spacing="4" skeletonHeight="3" />

            <FormLabel mb={2} fontWeight={700}>
              Summarize the applicant's proposed endeavor in 1-2 sentences
            </FormLabel>
            <SkeletonText my="8" noOfLines={4} spacing="4" skeletonHeight="3" />

            <FormLabel mb={2} fontWeight={700}>
              Describe the substantial merit and national importance of the
              proposed endeavor.
            </FormLabel>
            <SkeletonText my="8" noOfLines={4} spacing="4" skeletonHeight="3" />
          </AccordionPanel>
        </AccordionItem>

        {/* Prong 2 */}
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Prong 2 questions
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel p={10}>
            <FormLabel mb={2} fontWeight={700}>
              Describe the applicant's qualifications
            </FormLabel>
            <SkeletonText my="8" noOfLines={4} spacing="4" skeletonHeight="3" />

            <FormLabel mb={2} fontWeight={700}>
              How do the applicant's past achievements make them qualified to
              improve on the applicant's proposed work?
            </FormLabel>
            <SkeletonText my="8" noOfLines={4} spacing="4" skeletonHeight="3" />

            <FormLabel mb={2} fontWeight={700}>
              What impact has the applicant's past work already had in the
              field?
            </FormLabel>
            <SkeletonText my="8" noOfLines={4} spacing="4" skeletonHeight="3" />

            <FormLabel mb={2} fontWeight={700}>
              How are others using the applicant's work?
            </FormLabel>
            <SkeletonText my="8" noOfLines={4} spacing="4" skeletonHeight="3" />

            <FormLabel mb={2} fontWeight={700}>
              What are the applicant's future plans to advance their field of
              work?
            </FormLabel>
            <SkeletonText my="8" noOfLines={4} spacing="4" skeletonHeight="3" />
          </AccordionPanel>
        </AccordionItem>

        {/* Prong 3 */}
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Prong 3 questions
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel p={10}>
            <FormLabel mb={2} fontWeight={700}>
              Waiver of labor certification
            </FormLabel>
            <SkeletonText my="8" noOfLines={4} spacing="4" skeletonHeight="3" />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Card>
  );
};
