import { Button, Flex, Text, useToast } from "@chakra-ui/react";
import { doc } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../../api/firebaseApi";
import { formatDate } from "../../../helpers/date_helpers";
import { getUserType } from "../../../helpers/helpers";
import useFirestoreDocument from "../../../hooks/useFirestoreDocument";
import { useGenerateDrafts } from "../../../hooks/useGenerateDrafts";
import { useIndivSop } from "../../../hooks/useSopDrafts";
import { useSubscribeToJob } from "../../../hooks/useSubscribeToJob";
import { useUserRoleAndSubscription } from "../../../hooks/useUserRoleAndSubscription";
import { DocumentReducer } from "../../../redux/documents/documentSlice";
import { documentSelectors } from "../../../redux/documents/selectors";
import { draftsSelectors } from "../../../redux/drafts/selectors";
import {
  ExtractionJob,
  ExtractionReducer,
  EXTRACTIONSTATES,
  GenerationStatesDescription
} from "../../../redux/extraction-jobs/extractionJobsSlice";
import { extractionSelectors } from "../../../redux/extraction-jobs/selectors";
import { individualSelectors } from "../../../redux/individual/selectors";
import { lawyerSelectors } from "../../../redux/lawyer/selectors";
import { BriefGenerationRequest } from "../../../types/brief-generation-request";
import { LegalBriefType } from "../../../types/drafts.type";
import {
  DATABASE,
  DataDocs,
  visaFromPath,
  VISAVALUE
} from "../../../types/tables-data";
import { CustomAlertDialog } from "../../common/CustomAlertDialog";
import { CustomBox } from "../../common/CustomBox";
import { CustomButton } from "../../common/CustomButton";
import { ExtractionCard } from "../../common/ExtractionCard";
import { PremiumModal } from "../../common/PremiumModal";
import { SpecialHeading } from "../PanelLayout";
import { DeleteLegalBriefModal } from "./sopDrafts/DeleteSopDialog";
import { ReplaceDialog } from "./sopDrafts/ReplaceDialog";
import GenerateLegalBriefModal from "./sopDrafts/generateLegalBriefModal";
import { DocumentsInfoModal } from "../../common/DocumentsInfoModal";
import { ConfirmationDialog } from "../../common/ConfimationDialog";

export const SopDraftSection = () => {
  // Extract route parameters
  const { id, visaType } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  // Extract user information
  const uid = id ?? useSelector(individualSelectors.selectUid);
  const userEmail = useSelector(individualSelectors.selectEmail);
  const lawyeruid = useSelector(lawyerSelectors.selectUid);
  const lawyerEmail = useSelector(lawyerSelectors.selectEmail);

  // Local state for modal visibility
  const [showDeletionDialog, setShowDeletionDialog] = useState(false);
  const [showGenerationDialog, setShowGenerationDialog] = useState(false);
  const [showOverridingDialog, setShowOverridingDialog] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const [isPremiumModalOpen, setIsPremiumModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState<LegalBriefType>(
    LegalBriefType.LEGAL_BRIEF
  );
  const [missingRequirement, setMissingRequirement] = useState<
    string | undefined
  >(undefined);
  const [requirementMsg, setRequirementMsg] = useState<string | undefined>(
    undefined
  );

  const candidateResume = useSelector((state: DocumentReducer) =>
    documentSelectors.selectDocumentByType(state, "resume" ?? "")
  );

  // Determine the user's login type
  const loginType = getUserType();

  // Firestore document hooks
  const { document: legalBriefDocument, fetchDocument } = useFirestoreDocument(
    DATABASE.DRAFTS,
    uid
  );

  // Loading state for fetching documents
  const [isLoading, setIsLoading] = useState(true);

  // Custom hooks for SOP operations
  const { deleteSop, isLoadingDelete } = useIndivSop(uid);
  const legalBrief = useSelector(draftsSelectors.selectLegalBrief);

  // Extract visa information from route path
  const visa = visaFromPath[visaType!];

  // Custom hooks for job generation
  const { onSubmitGenerateDraft } = useGenerateDrafts(id);
  const { document, updateDocument, createDocument } =
    useFirestoreDocument<BriefGenerationRequest>(DATABASE.BRIEF_REQUESTS, uid);
  const { initiateJob, resetFields } = useSubscribeToJob();

  // User role and subscription checks
  const {
    isSuperAdmin,
    isFullUser,
    isStandardUser,
    isFreemiumUser,
    isPaidUser,
    shouldHideQualityReview
  } = useUserRoleAndSubscription();

  // Select the current job state from Redux store
  const legalBriefJob = useSelector((state: ExtractionReducer) =>
    extractionSelectors.selectJobById(state, "Legal Brief")
  );

  // Helper function to get the base domain of the current environment
  const getBaseDomain = (): string => {
    const { protocol, hostname, port } = window.location;
    return `${protocol}//${hostname}${port ? `:${port}` : ""}`;
  };

  // Memoized status message based on job state
  const statusMsg = useMemo(() => {
    const state = legalBriefJob?.status?.status as EXTRACTIONSTATES;
    return (
      GenerationStatesDescription[state] ??
      GenerationStatesDescription.NotStarted
    );
  }, [legalBriefJob]);

  const status = legalBriefJob?.status?.status;

  // Check if job generation is completed or in progress

  const isGenerated = useMemo(
    () => status === EXTRACTIONSTATES.Completed,
    [status]
  );

  const isGenerating = useMemo(
    () => status === EXTRACTIONSTATES.Processing,
    [status]
  );

  // Function to load document data from Firestore
  const loadDocument = () => {
    setIsLoading(true);
    fetchDocument().then(() => {
      setIsLoading(false);
    });
  };

  // Load document on component mount or when `uid` changes
  useEffect(() => {
    loadDocument();
  }, [uid]);

  // Confirm delete SOP operation
  const handleDeleteConfirm = async (): Promise<void> => {
    try {
      await deleteSop();
      await resetFields(legalBriefJob, EXTRACTIONSTATES.NotStarted);
      toast({
        title: "Success",
        description: "Draft deleted successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right"
      });
    } catch (e) {
      console.error(e);
    } finally {
      setShowDeletionDialog(false);
    }
  };

  const handleOverrideConfirm = async () => {
    setShowOverridingDialog(false);
    setShowConfirmationDialog(true);
  }

  // Confirm generation of legal brief
  const handleGenerateConfirm = async (): Promise<void> => {
    setShowConfirmationDialog(false);
    await fetchDocument();
    const job: ExtractionJob = {
      id: "Legal Brief",
      type: "Legal Brief Generation",
      status: {
        status: EXTRACTIONSTATES.Pending
      },
      docRef: doc(db, DATABASE.DRAFTS, `${uid}`),
      jobRef: doc(db, DATABASE.ACTIVE_JOBS, `${uid}`, "jobs", "Legal Brief"),
      toastTitle: "Job Completed",
      toastDesc: "The generation job has been completed.",
      docName: "",
      fieldPath: "sop"
    };

    // Initiate real-time db tracking
    await initiateJob(job);

    // Generate legal brief
    await onSubmitGenerateDraft({
      individualId: uid,
      draftType: "legal_brief",
      useAsync: true,
      isSupportLetter: selectedType === LegalBriefType.PETITIONER_SUPPORT_LETTER
    });

    setShowGenerationDialog(false);
  };

  // Handle generate modal confirmation
  const handleGenerateModalConfirm = async () => {
    setShowGenerationDialog(false);
    setShowConfirmationDialog(false);
    await handleGenerateConfirm();
  };

  const onConfirmationDialogClose = () => {
    setShowConfirmationDialog(false);
  };

  const onConfirmationDialogConfirm = () => {
    onConfirmationDialogClose();
    handleGenerateModalConfirm();
  };

  // Open dialog for generating a legal brief
  const openGenerateDialog = () => {
    if (isFreemiumUser) {
      setIsPremiumModalOpen(true);
      return;
    }
    if (isGenerated) {
      setShowOverridingDialog(true);
      return;
    }
    if (visa === VISAVALUE.O1A) {
      setShowGenerationDialog(true);
      return;
    }
    setShowConfirmationDialog(true);
  };

  // Check if exhibit list is empty
  const isExhibitListEmpty = () => {
    if (isLoading) {
      return false;
    }
    if (isFreemiumUser) {
      return false;
    }
    const text = (legalBriefDocument as any)?.exhibit_list?.html;
    return !text || text.trim() === "";
  };

  // Check if standard user has access
  const isStandardWithAccess = useMemo(() => {
    if (legalBrief?.value.accessEnabled === undefined) {
      return false;
    }
    const result =
      isPaidUser && isStandardUser && legalBrief?.value?.accessEnabled === true;
    return result;
  }, [legalBrief?.value?.accessEnabled]);

  // Check if the user is restricted
  const isRestrictedUser = useMemo(() => {
    const result = !isSuperAdmin && !isFullUser && !isStandardWithAccess;
    return result;
  }, [isSuperAdmin, isFullUser, isStandardWithAccess]);

  // Check if the document is pending and exhibit list is empty
  const isPendingWithEmptyExhibit = useMemo(() => {
    const result = isExhibitListEmpty() && document?.status === "pending";
    return result;
  }, [document]);

  // Determine if the section should be locked
  const locked = useMemo(() => {
    return isRestrictedUser || isPendingWithEmptyExhibit;
  }, [isRestrictedUser]);

  // TODO : refactor generation requirements to a seperate custom hook
  const isGenerateDisabled = useMemo(() => {
    const checkIfRequirementsExist = (
      foundDocument: DataDocs | undefined,
      requirement: string
    ) => {
      // Inexisting dependency
      if (!foundDocument) {
        setMissingRequirement(requirement);
        setRequirementMsg(
          "To generate the legal brief, please upload the necessary document(s):"
        );
        return true;
      }

      // Processing dependency
      if (foundDocument?.status?.status !== EXTRACTIONSTATES.Completed) {
        setMissingRequirement(requirement);
        setRequirementMsg(
          "Dependency document is processing. Please wait until it's complete:"
        );
        return true;
      }
      return false;
    };

    return checkIfRequirementsExist(candidateResume, "Candidate resume");
  }, [candidateResume]);

  return (
    <>
      <Flex flexDirection="column" rowGap="20px">
        {/* Heading */}
        <SpecialHeading
          title="Legal Brief"
          withInfo={<DocumentsInfoModal title="Legal Brief" />}
        />
        {/* Display exhibit list warning if necessary */}
        {isExhibitListEmpty() &&
          loginType === "lawyer" &&
          (isFullUser || isFreemiumUser || isSuperAdmin) && (
            <CustomBox
              type="info"
              onClick={() => loadDocument()}
              style={{ cursor: "pointer" }}
            >
              <Text fontSize="18px">
                You must generate the Exhibit List before you can generate a
                legal brief.
                <br />
                <b>CLICK HERE TO REFRESH</b>
              </Text>
            </CustomBox>
          )}

        {/* TODO: refactor link depending on missing requirement type */}
        {isGenerateDisabled && missingRequirement && (
          <CustomBox type="info">
            <Flex flexDirection="column">
              <Text>{requirementMsg}</Text>
              <Flex p={4} columnGap={2} alignItems="center">
                <li>
                  <Text as="span" fontWeight="bold">
                    {missingRequirement}
                  </Text>
                  {missingRequirement.includes("resume") &&
                    !candidateResume && (
                      <Button
                        m={2}
                        variant="secondaryOutline"
                        size="sm"
                        onClick={() => {
                          navigate("documents/standard-documents");
                        }}
                      >
                        Click here to upload
                      </Button>
                    )}
                </li>
              </Flex>
            </Flex>
          </CustomBox>
        )}

        {/* Display extraction card */}
        <ExtractionCard
          locked={locked}
          type="drafts/sop"
          id={id}
          statusMsg={statusMsg}
          extractedData={legalBrief ?? null}
          onExtractionDelete={() => setShowDeletionDialog(true)}
        />

        {/* Premium modal */}
        <PremiumModal
          isOpen={isPremiumModalOpen}
          onClose={() => setIsPremiumModalOpen(false)}
        />
      </Flex>

      {/* Buttons Container */}
      <Flex justifyContent="center" columnGap={2}>
        {/* Alert dialog for generating with quality review */}
        {loginType === "lawyer" &&
          !isSuperAdmin &&
          !isFreemiumUser &&
          !shouldHideQualityReview && (
            <CustomAlertDialog
              disabled={isGenerateDisabled}
              btnTitle="Generate with Quality Review"
              btnType="ai"
              alertType="confirm"
              alertTitle="Generate Legal Brief"
              alertDescription={
                document?.status !== "pending"
                  ? "This will send a notification to our team that you would like a quality check completed from one of our legal team members. Please confirm if you’d like to continue."
                  : "You've already requested a legal brief generation!"
              }
              isCentered
              confirm={() => {
                if (document)
                  updateDocument({
                    created_at: formatDate(Date.now(), "MM/DD/YYYY HH:mm"),
                    email: lawyerEmail,
                    status: "pending",
                    uid: lawyeruid,
                    individualId: uid,
                    visaType,
                    platformLink: `${getBaseDomain()}/individual/${uid}/${visaType}/`
                  });
                else {
                  createDocument({
                    created_at: formatDate(Date.now(), "MM/DD/YYYY HH:mm"),
                    email: lawyerEmail,
                    status: "pending",
                    uid: lawyeruid,
                    individualId: uid,
                    visaType,
                    platformLink: `${getBaseDomain()}/individual/${uid}/${visaType}/`
                  });
                }
              }}
            />
          )}
        {/* Display generate button if allowed */}
        {(loginType === "individual" ||
          isFullUser ||
          isFreemiumUser ||
          isSuperAdmin) && (
          <CustomButton
            type="ai"
            title="Generate"
            isPremium
            isLoading={isGenerating}
            onClick={openGenerateDialog}
          />
        )}
      </Flex>

      {/* Confirmation Dialog */}
      <ConfirmationDialog
        isOpen={showConfirmationDialog}
        onClose={onConfirmationDialogClose}
        onConfirm={onConfirmationDialogConfirm}
        body="Please confirm that you have reviewed the Documents, Questionnaires, and Case Research summaries before generating the legal brief."
      />
      {/* Generate legal brief modal */}
      <GenerateLegalBriefModal
        isOpen={showGenerationDialog}
        onConfirm={handleGenerateModalConfirm}
        onCancel={() => {
          setShowGenerationDialog(false);
          setSelectedType(LegalBriefType.LEGAL_BRIEF);
        }}
        setSelectedType={setSelectedType}
        selectedType={selectedType}
      />

      {/* Delete legal brief modal */}
      <DeleteLegalBriefModal
        isOpen={showDeletionDialog}
        onConfirm={handleDeleteConfirm}
        setShowDeleteDialog={setShowDeletionDialog}
        isLoading={false}
      />

      {/* Replace dialog */}
      <ReplaceDialog
        isOpen={showOverridingDialog}
        onConfirm={handleOverrideConfirm}
        setShowConfirmationDialog={setShowOverridingDialog}
        isLoading={false}
        jobType="generated"
        jobName="Legal Brief"
      />
    </>
  );
};
