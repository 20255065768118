import { DeleteIcon, EditIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Button,
  CircularProgress,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure
} from "@chakra-ui/react";
import {
  closestCenter,
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis
} from "@dnd-kit/modifiers";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { db } from "../../api/firebaseApi";
import { openFilePreview } from "../../helpers/helpers";
import { getShortString } from "../../helpers/string_helpers";
import useGroupedEvidenceDocuments from "../../hooks/useGroupedEvidenceDocuments";
import { DocumentReducer } from "../../redux/documents/documentSlice";
import { documentSelectors } from "../../redux/documents/selectors";
import { VisaDocumentType } from "../../redux/documents/types";
import { DataDocs } from "../../types/tables-data";
import EditMainDocModal from "../individualTabs/individualDocuments/EditMainDocModal";
import { RowDragHandleCell } from "./DraggableRow";
import { GroupedDiv, SubGroupDiv } from "./RowHelpers";

export const EvidenceTable = () => {
  const {
    groupedDocuments,
    setGroupedDocuments,
    updateCurrentOrder,
    uid,
    visaType,
    isLoadingGetEvidence
  } = useGroupedEvidenceDocuments();

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {})
  );

  const [expanded, setExpanded] = useState<Record<string, boolean>>({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose
  } = useDisclosure();
  const [isDeleting, setIsDeleting] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState<
    DataDocs | undefined
  >();
  const [documentToEdit, setDocumentToEdit] = useState<DataDocs | undefined>();

  const foundDocument = useSelector((state) => {
    return documentSelectors.selectDocumentById(
      state as DocumentReducer,
      documentToEdit?.id ?? ""
    );
  });

  const handleEditClick = (document: DataDocs) => {
    setDocumentToEdit({ ...document });
  };
  const handleDeleteClick = (document: DataDocs) => {
    setDocumentToDelete({ ...document });
  };
  const debouncedUpdate = useCallback(
    _.debounce((docs) => updateCurrentOrder(docs), 1000),
    []
  );

  useEffect(() => {
    if (groupedDocuments && groupedDocuments.length > 0) {
      debouncedUpdate(groupedDocuments);
    }
    return () => {
      debouncedUpdate.cancel();
    };
  }, [groupedDocuments]);

  // expanded logic
  useEffect(() => {
    if (
      !isLoadingGetEvidence &&
      groupedDocuments &&
      groupedDocuments.length > 0
    ) {
      const expandedMap: Record<string, boolean> = {};
      groupedDocuments.forEach((group) => {
        if (!expanded[group.type]) expandedMap[group.type] = false;
      });
      setExpanded((prevExpanded) => {
        return { ...prevExpanded, ...expandedMap };
      });
    }
  }, [groupedDocuments]);

  useEffect(() => {
    if (documentToEdit) {
      onEditOpen();
    }
  }, [documentToEdit, onEditOpen]);

  useEffect(() => {
    if (documentToDelete) {
      onOpen();
    }
  }, [documentToDelete, onOpen]);

  return (
    <div>
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        sensors={sensors}
        id="group"
        onDragEnd={(e) => {
          const { active, over } = e;
          if (active && over && groupedDocuments) {
            const isActiveCategory = active.id.toString().includes("cat");
            const isOverCategory = over.id.toString().includes("cat");

            // shuffling categories case
            if (isActiveCategory && isOverCategory) {
              const activeCategory = active.id.toString().replace("cat-", "");
              const overCategory = over.id.toString().replace("cat-", "");

              const activeCategoryIndex = groupedDocuments.findIndex(
                (group) => group.type === activeCategory
              );
              const overCategoryIndex = groupedDocuments.findIndex(
                (group) => group.type === overCategory
              );

              setGroupedDocuments(
                arrayMove(
                  groupedDocuments,
                  activeCategoryIndex,
                  overCategoryIndex
                )
              );
              updateCurrentOrder(
                arrayMove(
                  groupedDocuments,
                  activeCategoryIndex,
                  overCategoryIndex
                )
              );
            }
            // shuffling documents in same category case
            else if (!isActiveCategory && !isOverCategory) {
              const { category }: any = active.data.current;
              const categoryIndex = groupedDocuments.findIndex(
                (cat) => category === cat.type
              );

              const subRows = groupedDocuments[categoryIndex].subrows;
              const activeIndex = subRows.findIndex(
                (subrow) => subrow.id === active.id
              );
              const overIndex = subRows.findIndex(
                (subrow) => subrow.id === over.id
              );

              if (activeIndex !== -1 && overIndex !== -1) {
                const newSubRows = arrayMove(subRows, activeIndex, overIndex);
                const newDocuments = [...groupedDocuments];
                newDocuments[categoryIndex] = {
                  ...newDocuments[categoryIndex],
                  subrows: newSubRows
                };
                setGroupedDocuments(newDocuments);
                updateCurrentOrder(newDocuments);
              }
            }
          }
        }}
      >
        <div className="flex">
          <Table>
            <Thead>
              <Tr>
                <Th>Exhibit</Th>
                <Th>Type</Th>
                <Th>Document Title</Th>
                <Th>Status</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {/* Sortable groups */}
              {isLoadingGetEvidence || !groupedDocuments ? (
                <Tr key="placeholder">
                  <Td />
                  <Td />
                  <Td>
                    <CircularProgress
                      isIndeterminate
                      display="flex"
                      justifyContent="center"
                      mx="auto"
                    />
                  </Td>
                  <Td />
                  <Td />
                </Tr>
              ) : (
                <SortableContext
                  items={groupedDocuments.map((group) => ({
                    id: `cat-${group.type}`
                  }))}
                >
                  {/* categories */}
                  {groupedDocuments.map((group, indx) => (
                    <>
                      <GroupedDiv
                        id={`cat-${group.type}`}
                        key={`cat-${group.type}`}
                      >
                        <Td>
                          <RowDragHandleCell rowId={`cat-${group.type}`} />
                        </Td>
                        <Td>
                          <div className="flex items-center gap-2">
                            <IconButton
                              variant="filledIconButton"
                              onClick={() => {
                                setExpanded((prevExpanded) => {
                                  const newExpanded = { ...prevExpanded };
                                  newExpanded[group.type] =
                                    !newExpanded[group.type];

                                  return { ...newExpanded };
                                });
                              }}
                              aria-label=""
                              icon={
                                expanded[group.type] ? (
                                  <MdOutlineExpandLess />
                                ) : (
                                  <MdOutlineExpandMore />
                                )
                              }
                            />
                            <div className="flex flex-col ">
                              <p>
                                {group.type === "" ? (
                                  <Skeleton w={20} h={8} />
                                ) : (
                                  group.type
                                )}
                              </p>
                            </div>
                          </div>
                        </Td>
                      </GroupedDiv>

                      {/* subrows */}
                      <SortableContext
                        key={`sub-${group.type}`}
                        items={group.subrows.map((subrow) => ({
                          id: subrow.id!
                        }))}
                      >
                        {expanded[group.type] &&
                          group.subrows.map((document, i) => (
                            <SubGroupDiv
                              id={document.id}
                              key={document.id}
                              category={group.type}
                            >
                              <Td>
                                <div className="flex">
                                  <RowDragHandleCell
                                    rowId={document.id ?? ""}
                                  />
                                  {`${indx + 1}.${i + 1}`}
                                </div>
                              </Td>
                              <Td>
                                <div className="flex">
                                  <div className="flex flex-col">
                                    <p>{document.documentTitle}</p>
                                    <small className="text-black text-[10px]">
                                      {document?.general_class?.toString()}
                                    </small>
                                  </div>
                                </div>
                              </Td>
                              <Td>
                                <Tooltip label={document.autoTitle}>
                                  <div className="flex">
                                    {document.autoTitle ? (
                                      getShortString(document.autoTitle)
                                    ) : (
                                      <Skeleton w={20} h={8} />
                                    )}
                                    <ExternalLinkIcon
                                      className="cursor-pointer"
                                      onClick={() =>
                                        openFilePreview(document.docUrl)
                                      }
                                      ml={2}
                                    />
                                  </div>
                                </Tooltip>
                              </Td>
                              <Td>
                                {document.status && "status" in document.status
                                  ? document.status.status
                                  : document.status}
                              </Td>
                              <Td>
                                <Flex gap={2}>
                                  <IconButton
                                    variant="filledIconButton"
                                    icon={<DeleteIcon />}
                                    onClick={() => {
                                      handleDeleteClick(document);
                                    }}
                                    aria-label=""
                                  />
                                  <IconButton
                                    variant="filledIconButton"
                                    icon={<EditIcon />}
                                    onClick={() => handleEditClick(document)}
                                    aria-label=""
                                  />
                                </Flex>
                              </Td>
                            </SubGroupDiv>
                          ))}
                      </SortableContext>
                    </>
                  ))}
                </SortableContext>
              )}
            </Tbody>
          </Table>
        </div>
      </DndContext>
      {documentToEdit !== undefined && (
        <EditMainDocModal
          isDeleting={false}
          document={documentToEdit}
          headerText="Edit Main Document"
          isOpen={isEditOpen}
          onClose={onEditClose}
          visaDocumentType={VisaDocumentType.Evidence}
        />
      )}
      {/* delete modal */}
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              onClose();
            }}
          />
          <ModalBody pb={6}>
            Are you sure you want to delete the document?
          </ModalBody>

          <ModalFooter>
            <Button variant="secondaryOutline" onClick={onClose}>
              Cancel
            </Button>

            <Button
              variant="destructiveFilled"
              ml={3}
              isLoading={isDeleting}
              onClick={async () => {
                // edge case update documents to an empty array
                // before component gets unmounted

                if (groupedDocuments && groupedDocuments.length === 1) {
                  updateCurrentOrder([]);
                }

                if (documentToDelete?.docRef) {
                  setIsDeleting(true);

                  // soft delete
                  const docRef = doc(db, documentToDelete?.docRef ?? "");
                  await updateDoc(docRef, { isDeleted: true });
                  setIsDeleting(false);

                  onClose();
                }
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};
