import { ExtractionStatus } from "../redux/extraction-jobs/extractionJobsSlice";

export type ExtractedField = {
  variableName: string;
  fieldLabel: string;
  fieldType: string;
  value: string | number | ExtractedField[];
};

export type OverviewStep = {
  title: string;
  description: string;
  isComplete: boolean;
};

export type DataOverview = {
  steps: OverviewStep[];
};

export type DataNotes = {
  date: number;
  author: string;
  notes: string;
};
interface FormValues {
  evidenceName: string;
}

export interface DocumentType {
  title: string;
  desc?: string;
}
export type DocumentTypeTitle =
  | "I-94"
  | "Passport"
  | "Visa"
  | "Candidate resume"
  | string;

export type EvidenceFormValues = {
  selectedType: DocumentType | null;
  mainDoc?: DataDocs | null;
  description: string | null;
  customType: string | null;
};

export interface AddEvidence {
  type: string | undefined;
  criterion: string | undefined;
  documentTitle: string;
  description: string;
  autoTitle: string;
}

export type AddDocProps = {
  docNames?: string;
  docUrls?: string;
  firstName: string;
  lastName: string;
  uid?: string;
  docTitle?: string;
  autoTitle?: string;
  text?: string;
  otherDescription?: string;
  description?: string;
  evidenceId?: string;
  type?: any;
  prong?: number;
  subDocId?: string | null;
  customType?: string | number | readonly string[] | undefined;
  isMain?: boolean | null;
  exhibit?: string | number | null;
  filePath?: string | null;
  uploadDate?: number | null;
  uploadBy?: string | null;
  criterion?: string | null;
};

export type SubdocumentWithParentId = {
  id: string;
  document: DataDocs;
};
export type SubdocumenstWithParentId = {
  id: string;
  documents: DataDocs[];
};

export type DataDocs = TableData & {
  extracted_argument?: ExtractedArgument | null;
  id?: string;
  uid?: string;
  documentTitle?: DocumentTypeTitle | null;
  autoTitle?: string;
  uploadDate: number;
  uploadBy: string;
  isRequireForForms?: boolean;
  isRequireForDraft?: boolean;
  docNames?: string;
  docUrls?: string;
  text?: string;
  description?: string;
  type?: string;
  otherDescription?: string;
  exhibit?: number | string;
  isMain?: boolean;
  prong?: number;
  customType?: string;
  filePath?: string;
  autoOCRText?: string;
  summary?: string;
  summaryHtml?: string;
  criterion?: string | null;
  // state-only fields
  uploadId?: string | null;
  file?: File | null;
  thumbnail?: string;
  isDeleted: boolean;
  isModified?: boolean | null;
  isProcessing?: boolean | null;
  isExtracting?: boolean | null;
  docUrl?: string;
  status?: ExtractionStatus;
  sub_documents?: DataDocs[];
  docRef?: string;
  super_class?: string | undefined;
  general_class?: Record<number, string>;
  mainDoc?: DataDocs;
  created_at?: string;
  created_by?: string;
  orderedIndex?: string;
  extracted_fields?: ExtractedField[] | null;
};

const emptyMainDoc: DataDocs = {
  documentTitle: "Main document",
  uploadDate: 0,
  uploadBy: "",
  isMain: true,
  uid: "Main document",
  exhibit: "A",
  isDeleted: false
};

export const reqEvidenceDocs: Map<string, DataDocs> = new Map([
  [emptyMainDoc.uid!, emptyMainDoc]
]);

export const emptyEvidence: DataDocs = {
  uid: "",
  documentTitle: "",
  uploadBy: "",
  uploadDate: 0,
  exhibit: 0,
  autoTitle: "",
  isDeleted: false
};

export interface DocumentDetails {
  initDocumentTitle: string;
  initDescription: string;
  initAutoTitle: string;
  documentTitle: string;
  description: string;
  autoTitle: string;
}

export interface DeleteDocParams {
  docId?: string | null;
  uploadId?: string | null;
}

const emptyAddDocProps: AddDocProps = {
  firstName: "",
  lastName: "",
  docNames: "",
  docUrls: "",
  uid: "",
  docTitle: "",
  text: "",
  otherDescription: "",
  description: "",
  evidenceId: "",
  type: null,
  subDocId: null,
  customType: undefined,
  isMain: false,
  exhibit: null,
  filePath: null,
  uploadDate: null,
  uploadBy: null,
  criterion: null
};

export interface DocUrl {
  url?: string | null;
  storagePath?: string | null;
  thumbnail?: string | null;
}

export const docTypes: string[] = [
  "Proof of the endeavor’s national importance",
  "Published material written by others about the applicant's work",
  "Publication/article written by the applicant",
  "Award for excellence in the field of endeavor",
  "Patents, trademarks, copyrights, or licenses",
  "Citation records",
  "Google Scholar page",
  "Membership in professional associations",
  "Presentation at conferences",
  "Participation as a judge of others / peer-review",
  "Proof of commanding a high salary",
  "Proof of leading or critical role",
  "Plans for continuing work in the field: Offer letter",
  "Plans for continuing work in the field: Employment contract",
  "Plans for continuing work in the field: Paystubs",
  "Plans for continuing work in the field: Business plan",
  "Entrepreneur documents: Business model",
  "Entrepreneur documents: Contract agreement",
  "Entrepreneur documents: Financial support",
  "Other prong 1",
  "Other prong 2",
  "Other prong 3",
  "Client Statement",
  "Internal Questionnaire",
  "Entrepreneur documents: Support letters",
  "Entrepreneur documents: Investment",
  "Professional Certificates",
  "Expert Evaluation"
];

export interface Criterion {
  category: string;
  documentTypes: DocumentType[];
}

export type ExtractedArgument = {
  argument_text?: string | null;
  argument_html?: string | null;
  exhibit?: string | null;
  prongs?: string[] | null;
};

// Define a base type with common fields or methods
export type TableData = {
  id?: string;
  uid?: string;
  type?: string;
  // Add any other common fields that are required
};

export type DataPacket = {
  documentTitle: string;
  generationDate: string;
  generatedBy: string;
};

export type Expert = {
  uid: string;
  expertName: string;
};

export enum VISAVALUE {
  EMPTY = "",
  // O1 = "O-1",
  // EB1 = "EB-1",
  // EB2 = "EB-2",
  EB1A = "EB-1A",
  EB1B = "EB-1B",
  EB2NIW = "EB-2 NIW",
  O1A = "O-1 A",
  O1B = "O-1 B"
}

export interface DocumentSets {
  [key: string]: DataDocs[];
}

export const visaTypeToPath: Record<
  Exclude<VISAVALUE, VISAVALUE.EMPTY>,
  string
> = {
  [VISAVALUE.EB1A]: "EB-1A",
  [VISAVALUE.EB1B]: "EB-1B",
  [VISAVALUE.EB2NIW]: "EB-2-NIW",
  [VISAVALUE.O1A]: "O-1-A",
  [VISAVALUE.O1B]: "O-1-B"
};

export const visaFromPath: { [key: string]: VISAVALUE } = {
  "EB-1A": VISAVALUE.EB1A,
  "EB-1B": VISAVALUE.EB1B,
  "EB-2-NIW": VISAVALUE.EB2NIW,
  "O-1-A": VISAVALUE.O1A,
  "O-1-B": VISAVALUE.O1B
};

export type ClientData = {
  id: string;
  firstName: string;
  lastName: string;
  name?: string;
  birthDay: number;
  createdDate: number;
  created_at?: number;
  visa?: VISAVALUE;
  email: string;
  isActive: boolean;
  address?: string;
  practiceName?: string;
  actionNeeded?: boolean;
  caseId?: number;
  citizenship?: string;
  employer?: string;
  priority?: string;
  status?: number;
  overview?: DataOverview;
  notes?: DataNotes[];
  documents?: DataDocs[];
  packet?: DataPacket[];
  lawyerId?: string;
  isDeleted?: boolean;
};

export enum DATABASE {
  LAWYERS = "lawyers",
  FIRMS = "firms",
  CLIENTS = "clients",
  DOCUMENTS = "documents",
  FORMS = "forms",
  PROSPECTS = "prospects",
  EXTRACTED = "extracts",
  INDIVIDUALS = "individuals",
  DRAFTS = "drafts",
  QUESTIONNAIRES = "questionnaires",
  BRIEF_REQUESTS = "brief_generation_requests",
  ACTIVE_JOBS = "active_jobs",
  EVIDENCE_ARGUMENT = "evidence_argument",
  ADMIN_UPDATES = "admin_updates",
  SIGNED_LETTERS = "signed_expert_letters",
  CASE_RESEARCH = "case_research"
  // VISA_QUESTIONS = "visa_questions"
}
export enum FORMDATA {
  FORMS = "Forms",
  LETTER = "Letter",
  EXPERT = "Expert"
}

export type AddNewClient = {
  uid: string;
  userFirstName: string;
  userMiddleName: string;
  userLastName: string;
  email: string;
  birthDay: string;
  visaType: VISAVALUE;
  citizenship: string;
  countryob: string;
  employer: string;
  address: string;
  state: string;
  city: string;
  zipcode: string;
};
