import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Text
} from "@chakra-ui/react";
import { ForwardedRef, useState } from "react";

type ModalDeleteFileProps = {
  isOpen: boolean;
  docTitle: string;
  onClose: VoidFunction;
  onDelete: () => Promise<VoidFunction | any>;
};
export const ModalDeleteFile = ({
  docTitle,
  isOpen,
  onClose,
  onDelete
}: ModalDeleteFileProps) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Modal
      isCentered
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirmation</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Text>
            Are you sure you want to delete document{" "}
            <span className="font-semibold">{docTitle}</span>
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button variant="secondaryOutline" onClick={onClose}>Cancel</Button>
          <Button
            isLoading={isLoading}
            variant="destructiveFilled"
            ml={3}
            onClick={async () => {
              setIsLoading((loading) => !loading);
              await onDelete();
              setIsLoading((loading) => !loading);
              onClose();
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
