import { Divider, VStack } from "@chakra-ui/react";
import { SignedLettersSection } from "./individualPacket/SignedLettersSection";
import { PacketGenerateSection } from "./individualPacket/PacketGenerateSection";

interface Props {
  mainTabIndex?: number | null;
  subTabIndex?: number | null;
}

export const Packet = ({ mainTabIndex, subTabIndex }: Props) => {
  return (
    <div className="w-fulll h-full">
      <VStack
        alignItems="stretch"
        gap={8}
        divider={<Divider my={8} />}
        w="100%"
      >
        <SignedLettersSection />
        <PacketGenerateSection />
      </VStack>
    </div>
  );
};
