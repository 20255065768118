import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
    useDisclosure
  } from "@chakra-ui/react";
  import { useRef } from "react";
  
  interface ConfirmDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title?: string;
    body?: string;
  }
  
  export const ConfirmationDialog = ({
    isOpen,
    onClose,
    onConfirm,
    title = "Warning!",
    body = "Do you want to proceed with this action?"
  }: ConfirmDialogProps) => {
    const cancelRef = useRef(null);
  
    return (
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>{title}</AlertDialogHeader>
            <AlertDialogBody>{body}</AlertDialogBody>
            <AlertDialogFooter>
              <Button variant="secondaryOutline" ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button variant="primaryFilled" onClick={onConfirm} ml={3}>
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  };
  