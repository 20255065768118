import { VisaDocumentType } from "../redux/documents/types";
import { DataDocs } from "../types/tables-data";
import DynamicForm from "./FormFieldExtractor";

type Props = {
  document: DataDocs;
  documentType: VisaDocumentType;
};
const DocumentPreviewCard = ({ document, documentType }: Props) => {
  if (
    !document.extracted_fields ||
    Array.isArray(document.extracted_fields) === false
  ) {
    return <p>Data not found for the specified document type.</p>;
  }

  return (
    <div className="flex flex-col gap-16">
      {document && (
        <DynamicForm document={document} documentType={documentType} />
      )}
    </div>
  );
};

export default DocumentPreviewCard;
