import { doc, getDoc } from "firebase/firestore";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { db } from "../api/firebaseApi";
import {
  IndividualState
} from "../redux/individual/individualSlice";
import { DATABASE } from "../types/tables-data";
import { useLogOut } from "./useLogOut";

export const useGetIndividualData = () => {
  const dispatch = useDispatch();
  const [isLoadingGetIndividualData, setIsLoadingGetIndividualData] =
    useState(false);
  const [isNoIndividualData, setIsNoIndividualData] = useState(false);
  const [individualData, setIndividualData] = useState<IndividualState | null>(
    null
  );
  const { onSubmitLogOut } = useLogOut();

  const onSubmitGetIndividualData = async (individualId: string) => {
    const docRef = doc(db, DATABASE.INDIVIDUALS, individualId);
    setIsLoadingGetIndividualData(true);

    try {
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const individualData = {
          uid: docSnap.id,
          firstName: docSnap.data().firstName || "",
          middleName: docSnap.data().middleName || "",
          lastName: docSnap.data().lastName || "",
          email: docSnap.data().email || "",
          phone: docSnap.data().phone || "",
          dob: docSnap.data().dob || "2024-01-01",
          visaType: docSnap.data().visaType || docSnap.data().visa || "",
          company: docSnap.data().company || "",
          avatarName: docSnap.data().avatarName || "default.png",
          avatarSrc: docSnap.data().avatarSrc || "",
          actionNeeded: docSnap.data().actionNeeded || false,
          caseId: docSnap.data().caseId || 0,
          citizenship: docSnap.data().citizenship || "",
          countryob: docSnap.data().countryob || "",
          status: docSnap.data().status || 1,
          isDeleted : docSnap.data().isDeleted || false,
          notes: docSnap.data().notes || [],
          documents: [],
          overview: {
            steps: docSnap.data().overview?.steps || []
          },
          pronouns: docSnap.data().pronouns || "",
          otherPronouns: docSnap.data().otherPronouns || "",
          title: docSnap.data().title || "",
          addressLine1: docSnap.data().addressLine1 || "",
          city: docSnap.data().city || "",
          state: docSnap.data().state || "",
          zipcode: docSnap.data().zipcode || "",
          // employer info
          employer_name: docSnap.data().employer_name || "",
          employer_addressLine1: docSnap.data().employer_addressLine1 || "",
          employer_city: docSnap.data().employer_city || "",
          employer_state: docSnap.data().employer_state || "",
          employer_zipcode: docSnap.data().employer_zipcode || "",
          employer_contact_name: docSnap.data().employer_contact_name || "",
          employer_contact_email: docSnap.data().employer_contact_email || "",
          employer_contact_phone: docSnap.data().employer_contact_phone || "",

          usAddressInCareOf: docSnap.data().usAddressInCareOf || "",
          usAddressStreetNumber: docSnap.data().usAddressStreetNumber || "",
          usAddressStreetName: docSnap.data().usAddressStreetName || "",
          usAddressType: docSnap.data().usAddressType || "",
          usAddressAptSteFloor: docSnap.data().usAddressAptSteFloor || "",
          usAddressCityOrTown: docSnap.data().usAddressCityOrTown || "",
          usAddressState: docSnap.data().usAddressState || "",
          usAddressProvince: docSnap.data().usAddressProvince || "",
          usAddressZipCode: docSnap.data().usAddressZipCode || "",
          foreignAddressInCareOf: docSnap.data().foreignAddressInCareOf || "",
          foreignAddressStreetNumber:
            docSnap.data().foreignAddressStreetNumber || "",
          foreignAddressStreetName:
            docSnap.data().foreignAddressStreetName || "",
          foreignAddressType: docSnap.data().foreignAddressType || "",
          foreignAddressAptSteFloor:
            docSnap.data().foreignAddressAptSteFloor || "",
          foreignAddressCityOrTown:
            docSnap.data().foreignAddressCityOrTown || "",
          foreignAddressState: docSnap.data().foreignAddressState || "",
          foreignAddressProvince: docSnap.data().foreignAddressProvince || "",
          foreignAddressPostalCode:
            docSnap.data().foreignAddressPostalCode || "",
          hasAcceptedTerms: docSnap.data().hasAcceptedTerms || false,
          individualPortalAccessMap: docSnap.data().individualPortalAccessMap ? 
                new Map<string,boolean>(Object.entries(docSnap.data().individualPortalAccessMap)) : new Map<string, boolean>()
        };
        setIndividualData(individualData);
      } else {
        setIsNoIndividualData(true);
        onSubmitLogOut();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingGetIndividualData(false);
    }
  };

  return {
    isLoadingGetIndividualData,
    individualData,
    onSubmitGetIndividualData,
    isNoIndividualData
  };
};
