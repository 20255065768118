import dayjs from "dayjs";
import * as Yup from "yup";

export const toDate = (date: Date | number | string | null | undefined) => {
  if (date === null || date === undefined || date instanceof Date) return date;

  // better if we sate dates to null instead of empty string
  // in the future
  if (date === "") return null;

  if (typeof date === "number") {
    const newDate = dayjs(date * 1000).utc();
    return newDate.toDate();
  }

  return new Date(date);
};

export const validateDate = (date: string) => {
  const dates = date.split("-");
  if (Date.parse(date) > Date.now()) {
    return false;
  }
  if (+dates[0] > new Date().getFullYear() || +dates[0] < 1900) {
    return false;
  }
  if (+dates[1] > 12 || +dates[0] < 1) {
    return false;
  }
  if (+dates[3] > 31 || +dates[0] < 1) {
    return false;
  }
  return true;
};

const maxYear = new Date().getFullYear();
const maxMonth = new Date().getMonth() + 1;
const maxDate = new Date().getDate();

export const maxInputDateValue = `${maxYear}-${
  maxMonth.toString().length === 1 ? `0${maxMonth}` : maxMonth
}-${maxDate.toString().length === 1 ? `0${maxDate}` : maxDate}`;

export const formatDate = (
  date: number | undefined | null,
  formatString: string | null = null
): string => {
  if (date == null || date === 0) return "";

  if (formatString) {
    return dayjs(date).format(formatString);
  }
  return dayjs(date).format("MM/DD/YYYY");
};
