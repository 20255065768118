import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
  AlertDialogProps
} from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { CustomButton } from "./CustomButton";

type CustomAlertDialogProps = {
  btnTitle?: string;
  btnType?: "ai" | "standard";
  alertTitle: string;
  alertDescription?: string;
  alertType: "confirm" | "delete" | "replace";
  children?: React.ReactNode;
  withButton?: boolean;
  confirm: (e: any) => void;
  close?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
};

export const CustomAlertDialog = ({
  disabled = false,
  btnTitle,
  btnType = "standard",
  alertTitle,
  alertDescription,
  alertType = "confirm",
  children,
  withButton = true,
  confirm = () => {},
  close = () => {},
  isLoading,
  ...rest
}: CustomAlertDialogProps & Partial<AlertDialogProps>) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  const renderBtnText = () => {
    if (alertType === "delete") return "Delete";
    if (alertType === "replace") return "Replace";
    return "Confirm";
  };
  const btnConfirmText = renderBtnText();
  return (
    <>
      {withButton && (
        <CustomButton
          isDisabled={disabled}
          title={btnTitle ?? ""}
          onClick={onOpen}
          type={btnType}
        />
      )}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        {...rest}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {alertTitle}
            </AlertDialogHeader>

            <AlertDialogBody>
              {alertDescription}
              {children}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                variant="secondaryOutline"
                onClick={() => {
                  close();
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primaryFilled"
                bg={`${
                  alertType === "confirm" ? "primary.blue" : "primary.red"
                }`}
                color="white"
                isLoading={isLoading}
                onClick={(e) => {
                  confirm(e);
                  onClose();
                }}
                ml={3}
              >
                {btnConfirmText}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
