import { Box, ChakraProps, Container } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import CustomBackButton from "./CustomBackButton";
import { IndividualEditBox } from "./IndividualEditBox";

type Props = {
  withBackNav?: boolean;
  withIndividualEditBox?: boolean;
  backTargetPage?: string;
} & PropsWithChildren &
  ChakraProps;

export const SectionWrapper = ({
  withBackNav = true,
  withIndividualEditBox = true,
  children,
  backTargetPage= "Dashboard",
  ...rest
}: Props) => {
  const backAriaLabel = `Back to ${backTargetPage}`
  return (
    <Container padding={0} py={6} bg="primary.backgroundLight">
      {withIndividualEditBox && <IndividualEditBox />}
      <Box bg="primary.backgroundLight" padding={0}>
        {withBackNav && <CustomBackButton  ariaLabel={backAriaLabel} />}
      </Box>
      <Container {...rest}>{children}</Container>
    </Container>
  );
};
