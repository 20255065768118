import axiosInstance from "./axiosConfig";

interface ConversionResult {
  filename: string;
  data: ArrayBuffer;
}

export const convertFromHtml = async (
  format: string,
  html = "",
  firebase_path = "",
  document_field = "",
  html_field = ""
): Promise<ConversionResult> => {
  try {
    const requestBody: Record<string, any> = { format };

    if (html) {
      requestBody.html = html;
    }

    if (firebase_path) {
      requestBody.firebase_path = firebase_path;
    }

    if (document_field) {
      requestBody.document_field = document_field;
    }

    if (html_field) {
      requestBody.html_field = html_field;
    }

    const response = await axiosInstance.post(
      "/convert_from_html",
      requestBody,
      { responseType: "arraybuffer" }
    );

    // Extract Content-Disposition header
    const contentDisposition = response.headers["content-disposition"];
    console.log({ contentDisposition });
    let filename = "unknown";

    if (contentDisposition) {
      // Match the filename in the Content-Disposition header
      const filenameMatch = contentDisposition.match(/filename="?(.+?)"?$/);
      if (filenameMatch) {
        [, filename] = filenameMatch;
      }
    }

    const result = {
      filename,
      data: response.data as ArrayBuffer
    };
    return result;
  } catch (error) {
    console.error(`Error generating ${format} file:`, error);
    throw error;
  }
};
