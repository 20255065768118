import { Box, Button, Flex, Input, useToast } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { MdFileUpload } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { handleUnsupportedExtension } from "../../../helpers/file_helpers";
import { useAddFileToDB } from "../../../hooks/useAddFileToDB";
import { documentSelectors } from "../../../redux/documents/selectors";
import { individualSelectors } from "../../../redux/individual/selectors";
import { lawyerSelectors } from "../../../redux/lawyer/selectors";
import { DATABASE, DataDocs } from "../../../types/tables-data";
import { SpecialHeading } from "../PanelLayout";
import { SignedLettersTable } from "./SignedLettersTable";
import { CustomButton } from "../../common/CustomButton";
import { PremiumModal } from "../../common/PremiumModal";
import SupportedExtensionsMsg from "../../common/SupportedExtensionsMsg";
import { DocumentsInfoModal } from "../../common/DocumentsInfoModal";

export const SignedLettersSection = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { id, visaType } = useParams();
  const uid = id ?? useSelector(individualSelectors.selectUid);
  const { firstName, lastName } = useSelector(individualSelectors.selectAll);
  const { onSubmitAddFile, fileRef } = useAddFileToDB();
  const [draftNameToDelete, setDraftNameToDelete] = useState<string>("");
  const [drafIdToDelete, setDraftIdToDelete] = useState<string | null>(null);
  const [showDeletionDialog, setShowDeletionDialog] = useState<boolean>(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
  const signedLetters = useSelector(documentSelectors.signedLetters);
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const { subscription } = useSelector(lawyerSelectors.selectAll);

  const isFreemium = subscription?.status === "freemium";
  const [premiumModalOpen, setPremiumModalOpen] = useState(false);

  const deleteDocumentConfirm = (id: string, expertName: string) => {
    setDraftNameToDelete(expertName);
    setDraftIdToDelete(id);
    setShowDeletionDialog(true);
  };

  return (
    <Box p={0} m={0}>
      <Flex flexDirection="column" rowGap={4} w="100%">
        <Flex flexDirection="column" w="full">
          <SpecialHeading
            title="Signed expert letters"
            withInfo={<DocumentsInfoModal title="Signed expert letters" />}
          />
          {!isFreemium && <SignedLettersTable />}
          {isFreemium && (
            <CustomButton
              bgColor="primary.blue"
              color="white"
              type="ai"
              isPremium
              title="Sign Up Now"
              onClick={() => {
                setPremiumModalOpen(true);
              }}
            />
          )}

          <PremiumModal
            isOpen={premiumModalOpen}
            onClose={() => {
              setPremiumModalOpen(false);
            }}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
