// components/UploadList.tsx
import { CloseIcon, DeleteIcon } from "@chakra-ui/icons";
import { Box, Text, IconButton, VStack } from "@chakra-ui/react";
import { FiTrash } from "react-icons/fi";

interface UploadListProps {
  files: { name: string; type: string }[];
  onRemove: (index: number) => void;
}

const UploadList: React.FC<UploadListProps> = ({ files, onRemove }) => (
  <VStack align="stretch" spacing={2} overflowY="auto" maxHeight="60vh">
    {files.map((file, index) => (
      <Box
        key={index}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        border="1px solid"
        borderColor="stroke.stroke3"
        borderRadius="md"
        p={2}
      >
        <Text fontSize="sm" isTruncated>
          {file.name} ({file.type})
        </Text>
        <IconButton
          icon={<CloseIcon />}
          fontSize="10px"
          aria-label="Remove file"
          variant="outlineIconButton"
          onClick={() => onRemove(index)}
        />
      </Box>
    ))}
  </VStack>
);

export default UploadList;
