import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CustomBox } from "../components/common/CustomBox";
import { RichTextEditor } from "../components/common/RichTextEditor";
import { SectionWrapper } from "../components/common/SectionWrapper";
import { SpecialHeading } from "../components/individualTabs/PanelLayout";
import useManageCaseResearch from "../hooks/useManageCaseResearch";
import { caseResearchSelectors } from "../redux/case-research/selectors";
import { ToastManager } from "../services/toast/toastManager";
import styles from "../styles/extracted-info.module.css";
import { DATABASE } from "../types/tables-data";

export const IndividualExtractedCaseResearch = () => {
  const navigate = useNavigate();
  const { id, visaType } = useParams();
  const { onSaveCaseResearchTopic } = useManageCaseResearch(id);
  const { topics } = useSelector(caseResearchSelectors.selectAll);
  const [isLoadingSaveArray, setIsLoadingSaveArray] = useState<boolean[]>([]);
  const toast = useToast();

  const [currentArgumentTexts, setCurrentArgumentTexts] = useState<string[]>(
    []
  );
  const [currentArgumentHtmls, setCurrentArgumentHtmls] = useState<string[]>(
    []
  );
  const [isModifiedArray, setIsModifiedArray] = useState<boolean[]>([]);

  const handleSaveProcess = async (
    index: number,
    saveFunction: () => Promise<void>
  ) => {
    setIsLoadingSaveArray((prev) => {
      const newLoadingArray = [...prev];
      newLoadingArray[index] = true;
      return newLoadingArray;
    });
    try {
      await saveFunction();
      ToastManager.getInstance().showToast(
        DATABASE.CASE_RESEARCH,
        "info",
        "updated"
      );
    } catch (error) {
      console.error("Error saving case research argument: ", error);
      ToastManager.getInstance().showToast(
        DATABASE.CASE_RESEARCH,
        "warning",
        "updated"
      );
    } finally {
      setIsLoadingSaveArray((prev) => {
        const newLoadingArray = [...prev];
        newLoadingArray[index] = false;
        return newLoadingArray;
      });
    }
  };

  const handleSave = (index: number) => {
    handleSaveProcess(index, async () => {
      await onSaveCaseResearchTopic(
        topics[index].id,
        currentArgumentTexts[index],
        currentArgumentHtmls[index]
      );
      setIsModifiedArray((prev) => {
        const newModifiedArray = [...prev];
        newModifiedArray[index] = false;
        return newModifiedArray;
      });
    });
  };

  const handleSaveAll = () => {
    setIsLoadingSaveArray(topics.map(() => true));
    Promise.all(
      topics.map((doc, index) =>
        onSaveCaseResearchTopic(
          doc.id,
          currentArgumentTexts[index],
          currentArgumentHtmls[index]
        )
      )
    )
      .then(() => {
        ToastManager.getInstance().showToast(
          DATABASE.CASE_RESEARCH,
          "info",
          "updated"
        );
        setIsModifiedArray(topics.map(() => false));
      })
      .catch((error) => {
        console.error("Error saving all case_research arguments: ", error);
        ToastManager.getInstance().showToast(
          DATABASE.CASE_RESEARCH,
          "warning",
          "updated"
        );
      })
      .finally(() => {
        setIsLoadingSaveArray(topics.map(() => false));
        navigate(`/individual/${id}/${visaType}/case-research`);
      });
  };

  return (
    <SectionWrapper>
      <Flex
        justifyContent="space-between"
        flexDirection="row"
        width="100%"
        className={styles.section}
      >
        <Flex alignItems="center" flexDirection="column" flex="1" width="100%">
          <Box className="w-full">
            <SpecialHeading title="Extracted information" backNav />
            <CustomBox type="info">
              <Text fontSize="18px" fontStyle="italic">
                The following data was extracted from your uploaded documents.
                Please review, edit, and save. It is your responsibility to
                <b> double check the validity of the extracted data</b>.
              </Text>
            </CustomBox>
            <div className="">
              {topics && topics.length > 0 ? (
                <Flex flexDirection="column">
                  {topics.map((topic, index) => (
                    <React.Fragment key={topic.id}>
                      <Box
                        p={6}
                        key={topic.id}
                        className="border rounded-md my-2 w-full"
                      >
                        <div className="flex items-center">
                          <Flex flexDirection="column" mb="4">
                            <Text as="h2" fontWeight="700" textAlign="left">
                              Topic : {topic?.topicTitle}
                            </Text>
                          </Flex>
                        </div>

                        <RichTextEditor
                          editorId={topic.id}
                          key={topic.id}
                          customHeight="auto"
                          text={topic.summaryHtml ?? ""}
                          saveText={(html, plainText) => {
                            // Ensure both html and plainText are correctly set
                            setCurrentArgumentHtmls((prev) => {
                              const newHtmls = [...prev];
                              newHtmls[index] = html;
                              return newHtmls;
                            });
                            setCurrentArgumentTexts((prev) => {
                              const newTexts = [...prev];
                              newTexts[index] = plainText; // Ensure plainText is set
                              return newTexts;
                            });
                            setIsModifiedArray((prev) => {
                              const newModifiedArray = [...prev];
                              newModifiedArray[index] = false;
                              return newModifiedArray;
                            });
                            handleSave(index);
                          }}
                          updateText={(html, plainText) => {
                            setCurrentArgumentHtmls((prev) => {
                              const newHtmls = [...prev];
                              newHtmls[index] = html;
                              return newHtmls;
                            });
                            setCurrentArgumentTexts((prev) => {
                              const newTexts = [...prev];
                              newTexts[index] = plainText ?? "";
                              return newTexts;
                            });
                            setIsModifiedArray((prev) => {
                              const newModifiedArray = [...prev];
                              newModifiedArray[index] = true;
                              return newModifiedArray;
                            });
                          }}
                        />
                      </Box>
                    </React.Fragment>
                  ))}
                </Flex>
              ) : (
                <Box fontSize="18px" fontStyle="italic">
                  No Data
                </Box>
              )}
            </div>
            <div className="flex justify-center">
              <Button
                variant="secondaryOutline"
                type="button"
                mx="15px"
                my={4}
                onClick={() =>
                  navigate(`/individual/${id}/${visaType}/case-research`, {
                    replace: true
                  })
                }
              >
                Cancel
              </Button>
              <Button
                my={4}
                variant="primaryFilled"
                type="button"
                mx="15px"
                onClick={handleSaveAll}
                isLoading={isLoadingSaveArray.some((isLoading) => isLoading)}
              >
                Save All
              </Button>
            </div>
          </Box>
        </Flex>
      </Flex>
    </SectionWrapper>
  );
};
