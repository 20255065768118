import { defineStyleConfig } from "@chakra-ui/react";

// define the base component styles
const baseStyle = {
  bg: "white",
  maxW: "5xl",
  textAlign: "left",
  padding: "16px",
  borderRadius: "md"
};

// export the component theme
export const containerTheme = defineStyleConfig({ baseStyle });
