import { useEffect, useState } from "react";

import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Badge, Box, Button, Divider, Tooltip } from "@chakra-ui/react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Thumbnail from "../components/individualTabs/individualDocuments/Thumbnail";
import { getFileName } from "../helpers/file_helpers";
import { openInNewTab } from "../helpers/helpers";
import { getShortString } from "../helpers/string_helpers";
import { DataDocs } from "../types/tables-data";
import "./document_slider.css";
import ExtractedEvidenceItem from "./ExtractedEvidenceItem";
import { VisaDocumentType } from "../redux/documents/types";

type DocumentSlideComponentProps = {
  indx: string;
  document: DataDocs;
  visible: boolean;
  onClick: () => void;
};

const DocumentSlideComponent = ({
  indx,
  document,
  visible,
  onClick
}: DocumentSlideComponentProps) => {
  return (
    <div className="flex flex-col p-0 m-0 ">
      <Box
        borderColor={`${visible ? "primary.blue" : "#dadde0"}`}
        borderWidth="medium"
        className="w-48 h-64 flex flex-col rounded-lg relative"
        onClick={onClick}
      >
        <Badge
          border="1px solid #E2E8F0"
          bg="white"
          textColor="#757575"
          fontWeight={700}
          className="absolute right-1 top-1 z-10 !px-2 !py-0 !rounded-full"
        >
          {indx}
        </Badge>
        <div className="max-h-48 w-48 overflow-hidden ">
          <Thumbnail
            documentSummary={document.extracted_argument?.argument_text}
            filePath={document.filePath ?? ""}
            disableThumbnailClick
          />
        </div>
        <Box
          className="relative flex flex-col  py-1 justify-end mt-auto"
          bg={`${visible ? "primary.blue" : "gray"} `}
          color="white"
          p={1}
        >
          <Tooltip label={document.autoTitle}>
            {getShortString(document.autoTitle)}
          </Tooltip>
          <small className="flex justify-center font-bold">
            Exhibit: {indx}
          </small>
        </Box>
      </Box>
      <Button
        variant="primaryText"
        visibility={!visible ? "hidden" : "visible"}
        leftIcon={<ExternalLinkIcon />}
        className="self-end"
        key={indx}
        onClick={() => {
          openInNewTab(document?.docUrl ?? "", getFileName(document.filePath));
        }}
      >
        Open file
      </Button>
    </div>
  );
};

type DocumentsSliderProps = {
  categoryIndex: number;
  documents: DataDocs[];
  documentType: VisaDocumentType;
};

export const DocumentsSlider = ({
  categoryIndex,
  documents,
  documentType
}: DocumentsSliderProps) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const selectedDocument = documents[activeSlide];

  const settings: Settings = {
    speed: 250,
    arrows: true,
    slidesToScroll: 3,
    adaptiveHeight: true,
    infinite: false,
    variableWidth: true,
    vertical: false,
    centerMode: false,
    rows: 1
  };

  useEffect(() => {
    setActiveSlide(0);
  }, [categoryIndex]);

  return (
    <div className="overflow-hidden">
      <Slider {...settings}>
        {documents.map((document: DataDocs, indx: number) => (
          <DocumentSlideComponent
            onClick={() => {
              setActiveSlide(indx);
            }}
            key={document.id}
            indx={`${categoryIndex + 1}.${indx + 1}`}
            document={document}
            visible={activeSlide === indx}
          />
        ))}
      </Slider>
      <Divider my={12} />
      {/* EvidenceItem Preview */}
      {selectedDocument !== undefined && (
        <ExtractedEvidenceItem
          documentType={documentType}
          document={selectedDocument}
          key={selectedDocument.id}
          evidenceId={selectedDocument.id ?? ""}
          exhibit={`${categoryIndex + 1}.${activeSlide + 1}`}
          text={{
            current:
              selectedDocument?.extracted_argument?.argument_html ||
              selectedDocument?.extracted_argument?.argument_text ||
              "",
            original:
              selectedDocument?.extracted_argument?.argument_html ||
              selectedDocument?.extracted_argument?.argument_text ||
              ""
          }}
        />
      )}
    </div>
  );
};
