import { Box, Button, Divider, Stack, Text, VStack } from "@chakra-ui/react";
import { updateDoc } from "firebase/firestore";
import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { InputField } from "../forms/InputField";
import { getEntityReference } from "../helpers/firebase_helpers";
import { updateDocument } from "../redux/documents/documentSlice";
import { VisaDocumentType } from "../redux/documents/types";
import { ToastManager } from "../services/toast/toastManager";
import { DATABASE, DataDocs } from "../types/tables-data";

export type ExtractedField = {
  variableName: string;
  fieldLabel: string;
  fieldType: string;
  value: string | number | ExtractedField[];
};

type Props = {
  document: DataDocs;
  documentType: VisaDocumentType;
};
const DynamicForm = ({ document, documentType }: Props) => {
  const [isSaving, setIsSaving] = useState(false);

  const dispatch = useDispatch();
  const { id: uid } = useParams();

  const renderField = (field: ExtractedField, path: string) => {
    switch (field.fieldType) {
      case "date":
        return (
          <Box key={path} mb={4}>
            <InputField
              label={field.fieldLabel.replace(".", " ")}
              name={`${path}.value`}
            />
          </Box>
        );
      case "text":
        return (
          <Box key={path} mb={4}>
            <InputField
              label={field.fieldLabel.replace(".", " ")}
              name={`${path}.value`}
            />
          </Box>
        );
      case "object":
        return (
          <Stack key={path} mb={4} spacing={4} align="start" direction="row">
            {(field.value as ExtractedField[]).map((nestedField, index) =>
              renderField(nestedField, `${path}.value[${index}]`)
            )}
          </Stack>
        );
      case "array":
        return (
          <FieldArray
            key={path}
            name={`${path}.value`}
            render={() => (
              <VStack spacing={4} align="start">
                <Text fontWeight="bold">{field.fieldLabel}</Text>
                {(field.value as ExtractedField[]).map((nestedField, index) => (
                  <React.Fragment key={`${path}.value[${index}]`}>
                    {renderField(nestedField, `${path}.value[${index}]`)}
                    {index < (field.value as ExtractedField[]).length - 1 && (
                      <Divider />
                    )}
                  </React.Fragment>
                ))}
              </VStack>
            )}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Formik
      initialValues={{ fields: document?.extracted_fields ?? [] }}
      onSubmit={async (values) => {
        const isEvidence = documentType === VisaDocumentType.Evidence;
        const updatedExtractedFields = values.fields;
        const docRef = getEntityReference(
          isEvidence ? "evidenceDocument" : "standardDocument",
          {
            documentId: document?.id ?? "",
            individualId: uid!
          }
        );
        setIsSaving(true);
        await updateDoc(docRef, { extracted_fields: updatedExtractedFields });
        dispatch(
          updateDocument({
            data: { ...document, extracted_fields: updatedExtractedFields },
            type: documentType
          })
        );

        setIsSaving(false);
        ToastManager.getInstance().showToast(
          DATABASE.DOCUMENTS,
          "success",
          "updated"
        );
      }}
    >
      {({ handleSubmit, setValues, dirty }) => {
        useEffect(() => {
          const handleBeforeUnload = (e: any) => {
            if (dirty) {
              e.preventDefault();
              return true;
            }
            return false;
          };
          window.addEventListener("beforeunload", handleBeforeUnload);
          return () =>
            window.removeEventListener("beforeunload", handleBeforeUnload);
        }, [dirty]);
        return (
          <Form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4">
              {document.extracted_fields &&
                Array.isArray(document.extracted_fields) &&
                document.extracted_fields.length !== 0 &&
                document?.extracted_fields.map(
                  (field, index) =>
                    field.fieldType !== "object" &&
                    field.fieldType !== "array" &&
                    renderField(field, `fields[${index}]`)
                )}
            </div>
            <VStack spacing={4} align="start">
              {document.extracted_fields &&
                Array.isArray(document.extracted_fields) &&
                document.extracted_fields.length !== 0 &&
                document?.extracted_fields.map(
                  (field, index) =>
                    (field.fieldType === "array" ||
                      field.fieldType === "object") &&
                    renderField(field, `fields[${index}]`)
                )}
            </VStack>

            <div className="flex gap-2 justify-end">
              <Button
                className="flex justify-end"
                variant="secondaryOutline"
                onClick={() => {
                  setValues({ fields: document?.extracted_fields ?? [] });
                }}
              >
                Cancel
              </Button>{" "}
              <Button
                type="submit"
                className="flex justify-end"
                isDisabled={!dirty}
                isLoading={isSaving}
              >
                Save
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DynamicForm;
