import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue
} from "@chakra-ui/react";
import { useState } from "react";

interface Props {
  title: string;
  onItemSelect: (value: any) => void;
  list: any[];
  selectedValue: any;
  isDisabled?: boolean;
}

export const Dropdown = ({
  title,
  onItemSelect,
  list,
  selectedValue,
  isDisabled
}: Props) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredList = list.filter((item) => {
    if (title === "Expert" && item.expertName) {
      return item.expertName.toLowerCase().includes(searchTerm.toLowerCase());
    }
    if (typeof item === "object" && item.title) {
      return item.title.toLowerCase().includes(searchTerm.toLowerCase());
    }
    if (typeof item === "string") {
      return item.toLowerCase().includes(searchTerm.toLowerCase());
    }
    if (typeof item === "number") {
      return item.toString().includes(searchTerm);
    }
    return false;
  });

  const renderTitle = () => {
    if (typeof selectedValue === "object" && selectedValue !== null) {
      if (title === "Expert" && selectedValue.expertName) {
        return selectedValue.expertName;
      }
      return selectedValue.title || title;
    }
    return selectedValue || title;
  };

  const renderMenuItem = (item: any) => {
    if (typeof item === "object" && item !== null) {
      if (title === "Expert" && item.expertName) {
        return item.expertName;
      }
      return item.title;
    }
    return item;
  };

  return (
    <Menu>
      <MenuButton
        id="buttton22"
        isDisabled={isDisabled}
        as={Button}
        rightIcon={<ChevronDownIcon />}
        variant="add"
        width="fit-content"
        borderRadius={8}
      >
        {renderTitle()}
      </MenuButton>
      <MenuList py={2} px={0} maxHeight={300} overflowY="auto">
        <Flex flexDirection="column">
          <Box px={2}>
            <Input
              autoFocus
              placeholder="Search..."
              variant="filled"
              onChange={(e) => setSearchTerm(e.target.value)}
              my={2}
            />
          </Box>
          {filteredList.map((item, index) => (
            <MenuItem
              key={index}
              autoFocus={false}
              onClick={() => onItemSelect(item)}
            >
              <Box px={2}>{renderMenuItem(item)}</Box>
            </MenuItem>
          ))}
        </Flex>
      </MenuList>
    </Menu>
  );
};
