import { CheckCircleIcon } from "@chakra-ui/icons";
import { Box, Divider, List, ListItem, ListIcon, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { lawyerSelectors } from "../../redux/lawyer/selectors";

export const FreemiumCard = () => {
  const { subscription } = useSelector(lawyerSelectors.selectAll);
  const isFreemium = subscription?.status === "freemium";
  return (
    <Box
      background="white"
      boxShadow="md"
      borderRadius="md"
      padding={8}
      width="full"
      // height="400px"
      maxWidth="md"
      textColor="#4D5962"
      fontWeight={500}
    >
      <Text
        color="primary.red"
        fontWeight="bold"
        textTransform="uppercase"
        className="text-sm"
        visibility={!isFreemium ? "hidden" : "visible"}
      >
        Current Plan
      </Text>
      <Text
        fontSize="2xl"
        fontWeight="bold"
        marginBottom={4}
        color="primary.blue"
      >
        Freemium
      </Text>
      <Divider my={2} />

      <List
        whiteSpace="wrap"
        spacing={3}
        display="flex"
        flexDirection="column"
        justifyContent="start"
        alignItems="start"
        textAlign="left"
      >
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="primary.red" textAlign="left" />
          Free access to a restricted version of the CaseBlink platform
        </ListItem>
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="primary.red" textAlign="left" />
          Free expert letter generation for available workflows
        </ListItem>
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="primary.red" textAlign="left" />
          Option to upgrade any case to premium for additional features
        </ListItem>
      </List>
    </Box>
  );
};
