import { Box, CircularProgress } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { URLS } from "../../constans";
import { useGetUserDetails } from "../../hooks/useGetUserDetails";
import styles from "../../styles/sidebar.module.css";
import { DomainFilter } from "../DomainFilter";
import SidebarLinks from "./SiderbarLinks";
import { getLinksForUserType } from "../../helpers/helpers";
import { getMLVersionString } from "../../api/OpenAIApi";

interface SidebarProps {
  showSidebar: boolean;
  toggleSidebar?: () => void;
  closeSidebar?: () => void;
  isLoading?: boolean;
}

export const Sidebar = ({
  showSidebar,
  toggleSidebar,
  closeSidebar,
  isLoading
}: SidebarProps) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [scrolledY, setScrolledY] = useState(0);
  const [mlVersion, setMlVersion] = useState("");

  const {
    userDetails: { userType, role, visaType }
  } = useGetUserDetails();

  const userLinksByType = getLinksForUserType(userType, role, visaType);

  useEffect(() => {
    if (role === "superadmin") {
      const fetchMLVersion = async () => {
        try {
          const data = await getMLVersionString();
          setMlVersion(data);
        } catch (error) {
          console.error("Failed to fetch GH_PAT", error);
        }
      };

      fetchMLVersion();
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    const handleScroll = () => {
      // 85 is the height of the navbar
      const { scrollY } = window;
      if (scrollY > 85) return;

      const safeMargin = 85 - scrollY;
      setScrolledY(Number(safeMargin.toFixed(0)));
    };
    window.addEventListener("resize", handleResize);

    window.addEventListener("scroll", handleScroll);
    handleScroll();
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={`${styles.sidebar} ${
        showSidebar ? styles.show : styles.hide
      }  relative border-r-2 max-h-[100vh] `}
    >
      <div className="flex flex-1">
        <div className="flex flex-col">
          {isLoading ? (
            <CircularProgress isIndeterminate color="blue.300" />
          ) : (
            <div className="flex ">
              <SidebarLinks links={userLinksByType} onClick={toggleSidebar} />
            </div>
          )}
          <div className={`mt-auto  text-xs  p-2  z-20  `}>
            <p className=" text-xs flex gap-2">
              <a
                target="_blank"
                className="underline "
                href={URLS.tos}
                rel="noreferrer"
              >
                Terms of Service
              </a>{" "}
              <span>|</span>
              <a
                target="_blank"
                className="underline "
                href={URLS.privacy}
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </p>
            <p>{mlVersion}</p>
            <p className="text-xs text-center ">
              Copyright © 2024 <DomainFilter>CaseBlink, Inc.</DomainFilter>{" "}
            </p>
          </div>
        </div>
        {isMobile && (
          <div
            className="absolute left-0 w-[100vw] h-[100vh] z-[-5] bg-transparent"
            onClick={toggleSidebar}
          >
            <div className="flex-1 flex-grow w-max" />
          </div>
        )}
      </div>
    </div>
  );
};
