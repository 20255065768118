import { Box, Card, Icon } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { MdCloudUpload } from "react-icons/md";

type TargetBoxProps = {
  onDrop: (item: DataTransfer) => void;
} & PropsWithChildren;

export const TargetBox = ({ onDrop, children }: TargetBoxProps) => {
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: DataTransfer) {
        if (onDrop) {
          onDrop(item);
        }
      },
      canDrop(item) {
        return true;
      },
      collect: (monitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop()
        };
      }
    }),
    [onDrop]
  );
  const isActive = canDrop && isOver;

  return (
    <Box
      ref={drop}
      // variant={isActive ? "filled" : "elevated"}
      style={{
        width: "100%",
        textAlign: "center",
        margin: "0 auto",
        position: "relative"
      }}
      className="!relative"
    >
      {children}
      {isActive && (
        <div className="w-full h-full bg-blue-300 absolute top-0 z-10 opacity-40 flex justify-center items-center">
          {/* <MdCloudUpload size="25%" className="animate-bounce" /> */}
        </div>
      )}
    </Box>
  );
};
