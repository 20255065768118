import { useMemo, useState } from "react";
import { ExplanationForm } from "../components/ExplanationFormBlock";
import { visaFromPath, VISAVALUE } from "../types/tables-data";
import { ExpertLetter } from "../redux/clients/clientsSlice";

type Props = {
  visaType: any;
  expertLetter: ExpertLetter | null;
};

export const useGetQuestionsByVisaType = ({
  visaType,
  expertLetter
}: Props) => {
  // Initialize state variables from expertLetter prop
  const [expertiseSourceList, setExpertiseSourceList] = useState<string[]>(
    expertLetter?.expertiseSourceList ?? []
  );
  const [specificRoleList, setSpecificRoleList] = useState<string[]>(
    expertLetter?.specificRoleList ?? []
  );
  const [abilitiesList, setAbilitiesList] = useState<string[]>(
    expertLetter?.abilitiesList ?? []
  );
  const [roleImportanceList, setRoleImportanceList] = useState<string[]>(
    expertLetter?.roleImportanceList ?? []
  );

  const allBlocks: ExplanationForm[] = [
    {
      variableTitle: "expertiseSourceList",
      blockTitle:
        "Explain how the expert is familiar with the applicant's work. Incorporate details about collaborative projects, instances where the expert utilized the applicant's work, and any contributions that the expert can verify.",
      list: expertiseSourceList,
      setList: setExpertiseSourceList,
      isActive: false,
      index: 0,
      changeActiveBlock: (index: number) => {},
      visaTypes: [
        VISAVALUE.EB1A,
        VISAVALUE.EB1B,
        VISAVALUE.EB2NIW,
        VISAVALUE.O1A,
        VISAVALUE.O1B
      ]
    },
    {
      variableTitle: "specificRoleList",
      blockTitle:
        "Describe any specific work or contributions to the field that the expert should emphasize. Include any relevant examples or stories.",
      list: specificRoleList,
      setList: setSpecificRoleList,
      isActive: false,
      index: 1,
      changeActiveBlock: (index: number) => {},
      visaTypes: [
        VISAVALUE.EB1A,
        VISAVALUE.EB1B,
        VISAVALUE.EB2NIW,
        VISAVALUE.O1A,
        VISAVALUE.O1B
      ]
    },
    {
      variableTitle: "abilitiesList",
      blockTitle:
        "From the expert’s perspective, explain the importance of the applicant's work and the impact it had on the expert directly and/or the field in general. Describe if it is novel in comparison to what previously existed.",
      list: abilitiesList,
      setList: setAbilitiesList,
      isActive: false,
      index: 2,
      changeActiveBlock: (index: number) => {},
      visaTypes: [
        VISAVALUE.EB1A,
        VISAVALUE.EB1B,
        VISAVALUE.EB2NIW,
        VISAVALUE.O1A,
        VISAVALUE.O1B
      ]
    },
    {
      variableTitle: "roleImportanceList",
      blockTitle:
        "Explain why the applicant's work has national importance. Describe what will be the future impact of their work both for the field and the United States.",
      list: roleImportanceList,
      setList: setRoleImportanceList,
      isActive: false,
      index: 3,
      changeActiveBlock: (index: number) => {},
      visaTypes: [VISAVALUE.EB2NIW]
    }
  ];

  const filteredBlocks = useMemo(() => {
    if (visaType) {
      const filtered = allBlocks.filter((block: ExplanationForm) =>
        block.visaTypes?.includes(visaFromPath[visaType])
      );
      return filtered;
    }
    return [];
  }, [
    visaType,
    expertiseSourceList,
    specificRoleList,
    abilitiesList,
    roleImportanceList
  ]);

  return {
    filteredBlocks,
    expertiseSourceList,
    setExpertiseSourceList,
    specificRoleList,
    setSpecificRoleList,
    abilitiesList,
    setAbilitiesList,
    roleImportanceList,
    setRoleImportanceList
  };
};
