import { AddIcon } from "@chakra-ui/icons";
import {
  Text,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
  Tooltip,
  Tag,
  TagCloseButton,
  TagLabel,
  Wrap,
  WrapItem
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getShortString } from "../../helpers/string_helpers";

type CheckedItem<T> = {
  checked: boolean;
  value: string;
} & T;

type Props<T> = {
  isDisabled?: boolean;
  withToolTip?: boolean;
  list: T[];
  checkedItems: string[] | null | undefined;
  searchField?: keyof T;
  mergingField?: keyof T;
  getSelectedItems: (items: T[]) => void;
  withCheck?: boolean;
  withFilter?: boolean;
  groupBy?: keyof T; // Added groupBy prop
};

// Styling configurations
const tagStyles = {
  borderRadius: "md",
  border: "1px solid",
  borderColor: "stroke.stroke1Light",
  bg: "transparent",
  color: "primary.blue",
  _hover: { bg: "primary.focusOutline" },
  _focus: { boxShadow: "0 0 0 2px rgba(13, 89, 255, 0.5)" }
};

export const DropdownGeneric = <T,>({
  list,
  isDisabled,
  withToolTip = false,
  searchField,
  checkedItems,
  mergingField,
  getSelectedItems,
  withCheck = true,
  withFilter = true,
  groupBy // Added groupBy parameter
}: Props<T>) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [filter, setFilter] = useState("");

  const [derivedList, setDerivedList] = useState<CheckedItem<T>[]>(
    list.map<CheckedItem<T>>((element) => ({
      checked: false,
      value: getValue(element),
      ...element
    }))
  );

  function getValue(item: T): string {
    if (typeof item === "string") {
      return item;
    }
    if (searchField) {
      return String(item[searchField]);
    }
    return "";
  }

  function getMergeValue(item: T): string {
    if (typeof item === "string") {
      return item;
    }
    if (mergingField) {
      return String(item[mergingField]);
    }
    return "";
  }

  const groupItems = (list: CheckedItem<T>[]) => {
    if (!groupBy) return { "": list };

    return list.reduce<Record<string, CheckedItem<T>[]>>((groups, item) => {
      const groupKey = String(item[groupBy]) || "Uncategorized";
      if (!groups[groupKey]) {
        groups[groupKey] = [];
      }
      groups[groupKey].push(item);
      return groups;
    }, {});
  };

  useEffect(() => {
    if (checkedItems) {
      setDerivedList((prevList) => {
        const tempList = prevList.map((element) => {
          const itemValue = getMergeValue(element as T);
          const isChecked = checkedItems.includes(itemValue);
          return { ...element, checked: isChecked };
        });
        return tempList;
      });
    }
  }, [list, mergingField, checkedItems]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const handleSelect = (item: CheckedItem<T>) => {
    const updatedList = derivedList.map((_item) =>
      _item.value === item.value ? { ..._item, checked: !_item.checked } : _item
    );
    const numberOfSelectedItems = updatedList.filter((i) => i.checked).length;

    if (numberOfSelectedItems === updatedList.length) setSelectAll(true);
    else if (numberOfSelectedItems === 0) setSelectAll(false);
    else setSelectAll(false);

    setDerivedList(updatedList);
  };

  const handleSelectAll = () => {
    const updatedList = derivedList.map((item) => ({
      ...item,
      checked: !selectAll
    }));
    setDerivedList(updatedList);
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    getSelectedItems(derivedList.filter((item) => item.checked) as T[]);
  }, [derivedList]);

  const filteredList = derivedList.filter((item) =>
    item.value.toLowerCase().includes(filter.toLowerCase())
  );

  const numberOfSelectedItems = derivedList.filter(
    (item) => item.checked
  ).length;

  const groupedItems = groupItems(filteredList); // Group the filtered list by the groupBy field

  return (
    <>
      <Box mb={2} display="flex" justifyContent="flex-start">
        <Menu isOpen={menuIsOpen} onClose={() => setMenuIsOpen(false)}>
          <Flex flexDirection="column">
            <MenuButton
              mr={4}
              as={Button}
              isDisabled={isDisabled}
              leftIcon={<AddIcon />}
              variant="add"
              borderRadius="md"
              onClick={() => setMenuIsOpen((prev) => !prev)}
            >
              {withCheck && numberOfSelectedItems > 0
                ? null
                : "No documents selected"}
            </MenuButton>

            <MenuList
              width="30vw"
              maxHeight="50vh"
              overflow="hidden"
              display="flex"
              flexDirection="column"
              sx={{
                "&::-webkit-scrollbar": {
                  width: "10px"
                },
                "&::-webkit-scrollbar-track": {
                  background: "primary.lightGray"
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "accent.lapis",
                  borderRadius: "8px"
                }
              }}
            >
              {/* Scrollable content including search, select all, and items */}
              <Box flex="1" overflowY="auto" px={2}>
                {withFilter && (
                  <Input
                    placeholder="Search..."
                    variant="filled"
                    bg={useColorModeValue(
                      "background.white",
                      "background.darkWhite"
                    )}
                    _focus={{
                      borderColor: useColorModeValue(
                        "primary.focusOutline",
                        "primary.red"
                      )
                    }}
                    onChange={handleChange}
                    mb={2}
                  />
                )}
                {/* {withCheck && (
                  <>
                    <MenuItem
                      px={4}
                      onClick={handleSelectAll}
                      closeOnSelect={false}
                      _hover={{ bg: "primary.focusOutline" }}
                    >
                      <Checkbox pointerEvents="none" isChecked={selectAll}>
                        Select All
                      </Checkbox>
                    </MenuItem>
                    <Divider my={4} borderColor="stroke.stroke1Light" />
                  </>
                )} */}
                {Object.keys(groupedItems).map((groupKey) => (
                  <Box key={groupKey}>
                    {groupKey && (
                      <Text fontWeight="bold" px={4} py={2} color="black">
                        {groupKey}
                      </Text>
                    )}
                    {groupedItems[groupKey].map((item) => (
                      <MenuItem
                        px={4}
                        key={item.value}
                        closeOnSelect={false}
                        onClick={() => handleSelect(item)}
                        _hover={{ bg: "primary.focusOutline" }}
                      >
                        {withCheck && (
                          <Checkbox
                            isChecked={item.checked}
                            pointerEvents="none"
                          >
                            {withToolTip ? (
                              <Tooltip label={item.value}>
                                {getShortString(item.value)}
                              </Tooltip>
                            ) : (
                              <Text color="black">{item.value}</Text>
                            )}
                          </Checkbox>
                        )}
                        {!withCheck && <Text color="black">{item.value}</Text>}
                      </MenuItem>
                    ))}
                  </Box>
                ))}
              </Box>

              {/* Fixed footer for the buttons */}
              {withCheck && (
                <Box
                  px={4}
                  py={2}
                  borderTop="1px solid"
                  borderColor="stroke.stroke1Light"
                >
                  <Flex justifyContent="end" gap={2}>
                    <Button
                      variant="tertiaryText"
                      size="sm"
                      onClick={() => setMenuIsOpen(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="secondaryOutline"
                      size="sm"
                      onClick={() => setMenuIsOpen(false)}
                    >
                      Done
                    </Button>
                  </Flex>
                </Box>
              )}
            </MenuList>
          </Flex>
        </Menu>
      </Box>
      <Wrap>
        {derivedList
          .filter((item) => item.checked)
          .map((item) => (
            <WrapItem key={item.value}>
              <Tag size="lg" sx={tagStyles}>
                <TagLabel>{item.value}</TagLabel>
                <TagCloseButton onClick={() => handleSelect(item)} />
              </Tag>
            </WrapItem>
          ))}
      </Wrap>
    </>
  );
};
