import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  Container,
  Heading,
  Box
} from "@chakra-ui/react";
import { useParams, Link } from "react-router-dom";
import { useLayoutEffect } from "react";
import { Header } from "../components/Header";
import { Sidebar } from "../components/Sidebar/Sidebar";
import { LawyerProfile } from "./LawyerProfile";
import { PlanPage } from "./PlanPage";
import { SectionWrapper } from "../components/common/SectionWrapper";
import { SpecialHeading } from "../components/individualTabs/PanelLayout";

const LawyerAccountPage = () => {
  const { tabIndex } = useParams();
  const currentTabIndex = tabIndex !== undefined ? Number(tabIndex) : 0;

  return (
    <SectionWrapper withIndividualEditBox={false}>
      <SpecialHeading
        title="Account Settings"
        mt={4}
        pb={4}
        textAlign="left"
        bg="transparent"
      />
      <Tabs index={Number(currentTabIndex)}>
        <TabList>
          <Tab as={Link} to="/account">
            Manage Profile
          </Tab>
          {/* <Tab as={Link} to="/account/1">
            Payment & Subscription
          </Tab> */}
        </TabList>

        <TabPanels>
          <TabPanel>
            <LawyerProfile />
          </TabPanel>
          <TabPanel>
            <PlanPage />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </SectionWrapper>
  );
};

export default LawyerAccountPage;
