import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  IconButton
} from "@chakra-ui/react";
import {
  Form,
  Formik,
  FormikConfig,
  FormikHelpers,
  FormikProps,
  FormikValues
} from "formik";
import React, { ReactElement, useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { SpecialHeading } from "../components/individualTabs/PanelLayout";

interface Props extends FormikConfig<FormikValues> {
  children?: React.ReactNode;
  loading?: boolean;
}

export const MultiStepForm = ({
  children,
  initialValues,
  onSubmit,
  loading = false,
  ...props
}: Props) => {
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(0);
  const [expanded, setExpanded] = useState<number | null>(0);

  const steps = React.Children.toArray(children) as React.ReactElement[];

  const isLastStep = step === steps.length - 1;

  const currentStep = steps[step] as ReactElement;

  const next = () => {
    setStep(step + 1);
    setExpanded(step + 1);
  };
  const back = () => {
    setStep(step - 1);
    setExpanded(step - 1);
  };

  const handleSubmit = async (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => {
    if (currentStep.props.onSubmit) {
      try {
        await currentStep.props.onSubmit(values);
      } catch (error) {
        alert(error);
        return false;
      }
    }
    if (isLastStep) {
      return onSubmit(values, formikHelpers);
    }
    formikHelpers.setTouched({});
    return next();
  };

  const handleAccordionChange = async (
    index: number | null,
    formikProps: FormikProps<FormikValues>
  ) => {
    if (index === expanded) {
      setExpanded(null);
    } else {
      if (currentStep.props.onSubmit) {
        try {
          await currentStep.props.onSubmit(formikProps.values);
        } catch (error) {
          alert(error);
          return;
        }
      }
      formikProps.setTouched({});
      setStep(index as number);
      setExpanded(index);
    }
  };

  return (
    <div className="">
      <SpecialHeading title="New Case" />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={currentStep.props.validationSchema}
        {...props}
      >
        {(formikProps) =>
          steps.length === 1 ? (
            <Form>
              {steps[0]}
              <div className="actions flex gap-2 justify-end items-center py-2">
                {isLastStep && (
                  <div className="flex gap-2">
                    <Button
                    variant="secondaryOutline"
                      onClick={() => navigate("/cases")}
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      variant="primaryFilled"
                      isLoading={loading}
                    >
                      Submit
                    </Button>
                  </div>
                )}
              </div>
            </Form>
          ) : (
            <Accordion
              index={expanded!}
              onChange={(index) =>
                handleAccordionChange(index as number, formikProps)
              }
              allowToggle
            >
              {steps.map((e, indx) => (
                <AccordionItem key={indx}>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        {e.props.stepName}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    {step === indx && (
                      <Form>
                        {steps[step]}
                        <div className="actions flex gap-2 justify-end items-center py-2">
                          {step > 0 && (
                            <Button variant="secondaryOutline" onClick={back}>
                              back
                            </Button>
                          )}
                          {!isLastStep && (
                            <IconButton
                              aria-labelledby="Next"
                              variant="primaryFilled"
                              icon={<FaAngleDown />}
                              type="submit"
                              aria-label="Next"
                            />
                          )}
                          {isLastStep && (
                            <div className="flex">
                              <Button
                              variant="primaryFilled"
                                type="submit"
                                isLoading={loading}
                              >
                                submit
                              </Button>
                            </div>
                          )}
                        </div>
                      </Form>
                    )}
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          )
        }
      </Formik>
    </div>
  );
};

export const StepForm = ({ children }: any) => (
  <div className="flex flex-col gap-4">{children}</div>
);
