import {
  collection,
  onSnapshot,
  query,
  where
} from "firebase/firestore";
import { useEffect, useRef } from "react";
import { db } from "../api/firebaseApi";
import {
  addNewDocument,
  deleteDocument,
  setDocuments,
  updateDocument
} from "../redux/documents/documentSlice";
import {
  generateDocumentUrls,
  generateSingleDocumentUrl
} from "../redux/documents/documentsThunk";
import { VisaDocumentType } from "../redux/documents/types";
import { useTypedDispatch } from "../redux/store";
import { DataDocs } from "../types/tables-data";

export const useGetStandardDocs = (collectionPath: string) => {
  const dispatch = useTypedDispatch();
  const initialLoad = useRef(true); // Track the first load

  useEffect(() => {
    const collectionRef = collection(db, collectionPath);
    const filteredQuery = query(
      collectionRef,
      where("isDeleted", "in", [false, null])
    );

    const unsubscribe = onSnapshot(filteredQuery, (snapshot) => {
      const addedDocs: DataDocs[] = [];

      snapshot.docChanges().forEach((change) => {
        const document = {
          ...change.doc.data(),
          docRef: change.doc.ref.path,
          id: change.doc.id,
          isDeleted: change.doc.data().isDeleted ?? false
        } as DataDocs;

        if (change.type === "added") {
          if (initialLoad.current) {
            addedDocs.push(document);
          } else {
            // For subsequent additions after initial load
            dispatch(
              addNewDocument({
                data: document,
                type: VisaDocumentType.Standard
              })
            );
            dispatch(
              generateSingleDocumentUrl({
                id: document.id ?? "",
                filePath: document.filePath ?? "",
                type: "standard"
              })
            );
          }
        } else if (change.type === "modified") {
          dispatch(
            updateDocument({
              data: document,
              type: VisaDocumentType.Standard
            })
          );
        } else if (change.type === "removed") {
          dispatch(
            deleteDocument({
              data: document,
              type: VisaDocumentType.Standard
            })
          );
        }
      });

      if (initialLoad.current) {
        // Batch set documents and generate URLs on initial load
        if (addedDocs.length > 0) {
          dispatch(
            setDocuments({
              data: addedDocs,
              type: VisaDocumentType.Standard
            })
          );

          dispatch(
            generateDocumentUrls({
              documents: addedDocs.map((doc) => ({
                id: doc.id as string,
                filePath: doc.filePath as string
              })),
              type: VisaDocumentType.Standard
            })
          );
        }
        initialLoad.current = false; // Mark initial load as complete
      }
    });

    return () => unsubscribe();
  }, []);
};
