import { Box, Button, Flex, Spacer, Text } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link as LinkDom, useLocation, useNavigate } from "react-router-dom";
import { auth } from "../api/firebaseApi";
import { firmConfiguration } from "../constans";
import { getSubdomain, setupIntercom } from "../helpers/helpers";
import { useLogOut } from "../hooks/useLogOut";
import { useNavbar } from "../hooks/useNavbarContext";
import { activeClientSelectors } from "../redux/client-case/selectors";
import { individualSelectors } from "../redux/individual/selectors";
import { lawyerSelectors } from "../redux/lawyer/selectors";
import styles from "../styles/header.module.css";
import AdminUpdatesModal from "./common/AdminUpdatesModal";
import ExtractionStatusDialog from "./common/ExtractionStatusModal";
import { NavBarLogo } from "./NavbarLogo";
import { UserMenu } from "./UserMenu";

interface HeaderProps {
  link?: string;
}

export const Header = ({ link }: HeaderProps) => {
  const navigate = useNavigate();
  const { onSubmitLogOut } = useLogOut();
  const location = useLocation();
  const lawyerId = useSelector(lawyerSelectors.selectUid);

  const clientId = useSelector(activeClientSelectors.selectId);
  const individualId = useSelector(individualSelectors.selectUid);

  const lawyerEmail = useSelector(lawyerSelectors.selectEmail);
  const individualEmail = useSelector(individualSelectors.selectEmail);
  const lawyerUid = useSelector(lawyerSelectors.selectUid);
  const individualUid = useSelector(individualSelectors.selectUid);

  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const { position } = useNavbar();

  useEffect(() => {
    setupIntercom(lawyerEmail || individualEmail, lawyerUid || individualUid);
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user && location.pathname !== "/login") setIsAuthChecked(true);
    });

    return () => unsubscribe();
  }, []);

  const handleSignOut = async () => {
    if (auth.currentUser) {
      await onSubmitLogOut();
    } else {
      navigate("/login");
    }
  };

  const NavigateToLogin = async () => {
    navigate("/login");
  };

  const logoLink = useMemo(() => {
    if (link) {
      return link;
    }
    if (lawyerId) {
      return "/cases";
    }
    if (clientId) {
      return "/client";
    }
    if (individualId) {
      return "/";
    }
    return "/";
  }, [auth, lawyerId, clientId, individualId]);
  const subdomain = getSubdomain();
  const firmConfig = firmConfiguration[subdomain];
  return (
    <header className={styles.header} style={firmConfig?.cssForHeader}>
      <Flex alignItems="center">
        <Flex alignItems="center" ml="15px">
          <Text fontWeight={500}>
            <LinkDom to={logoLink} style={{ margin: "0", padding: "0" }} />
          </Text>
          <Box>
            <LinkDom
              to={firmConfig?.domainUrl || logoLink}
              target={firmConfig?.domainUrl ? "_blank" : "_self"}
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0",
                padding: "0"
              }}
            >
              <NavBarLogo />{" "}
            </LinkDom>
          </Box>
        </Flex>
        <Spacer />

        <Flex alignItems="center" px={4}>
          {isAuthChecked && (
            <Flex gap={4} className="max-h-10">
              <AdminUpdatesModal />
              <ExtractionStatusDialog />
              <UserMenu />
            </Flex>
          )}
        </Flex>
      </Flex>
    </header>
  );
};
