import { useDispatch } from "react-redux";
import { filesHasUnsupportedExtension } from "../helpers/file_helpers";
import { VisaDocumentType } from "../redux/documents/types";
import {
  setError,
  setExpanded as setFileWindowExpanded,
  setFiles,
  setUploadConfiguration,
  setVisibilty
} from "../redux/file-upload/fileUploadSlice";
import { uploadFiles } from "../redux/file-upload/fileUploadThunk";

export type FileActionsProps = {
  visaDocumentType?: VisaDocumentType;
  files: File[];
  uid: string;
  mainDocumentId?: string;
  path: string;
  collectionPath?: string;
  type?: string;
  category?: string;
  superClass?: string;
};

const useFileActions = () => {
  const dispatch = useDispatch();

  const handleFileActions = ({
    files,
    uid,
    mainDocumentId,
    visaDocumentType,
    path,
    collectionPath,
    superClass,
    category,
    type
  }: FileActionsProps) => {
    const filesAsArray = Array.from(files);
    const filesHasOtherExtension = filesHasUnsupportedExtension(filesAsArray);
    dispatch(setFiles(filesAsArray));
    const uploadConfig = {
      uid: uid ?? "",
      path,
      collectionPath,
      mainDocumentId,
      superClass,
      documentType: visaDocumentType as VisaDocumentType,
      category,
      type
    };

    console.log(
      "Dispatching setUploadConfiguration with:",
      JSON.stringify(uploadConfig, null, 2)
    );

    dispatch(setUploadConfiguration(uploadConfig));

    if (!filesHasOtherExtension) {
      dispatch(setVisibilty(true));
      dispatch(setFileWindowExpanded(true));

      dispatch(uploadFiles() as any);
    } else {
      dispatch(setError("extension-error"));
    }
  };

  return {
    handleFileActions
  };
};

export default useFileActions;
