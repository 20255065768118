import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  where
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { db } from "../api/firebaseApi";
import {
  ExpertLetter,
  FirstForm,
  SecondForm
} from "../redux/clients/clientsSlice";
import { DATABASE } from "../types/tables-data";
import { setExpertLetters } from "../redux/forms/formsSlice";

export interface FormsData {
  expertLetter: ExpertLetter[] | null;
  questionnaires?: {
    firstStep?: FirstForm;
    secondStep?: SecondForm;
  } | null;
}

export const useGetFormsData = () => {
  const dispatch = useDispatch();
  const [isLoadingGetFormsData, setIsLoadingGetFormsData] = useState(true);
  const [formsData, setFormsData] = useState<FormsData | null>(null);
  const [isFormsNotFound, setIsFormsNotFound] = useState(false);
  const params = useParams();

  useEffect(() => {
    if (!params.id) {
      return () => {}; // Return a no-op cleanup function if caseID is not present
    }
    // Reference to the main document
    const docRef = doc(db, DATABASE.FORMS, `${params.id ?? params.caseID}`);

    // Query to fetch expert letters where isDeleted is false
    const expertLetterQuery = query(
      collection(docRef, "expert_letters"),
      where("isDeleted", "in", [false, null]),
      orderBy("expertName")
    );

    // Listener to fetch expert letters
    const unsubscribe = onSnapshot(
      expertLetterQuery,
      (snapshot) => {
        const expertLetters = snapshot.docs.map(
          (doc) => doc.data() as ExpertLetter
        );

        // Update state with fetched expert letters, or set to null if empty
        setFormsData({
          expertLetter: expertLetters.length > 0 ? expertLetters : null
        });

        dispatch(setExpertLetters(expertLetters));

        setIsFormsNotFound(false);
        setIsLoadingGetFormsData(false);
      },
      (error) => {
        console.error("Error fetching expert letters: ", error);
        setIsLoadingGetFormsData(false);
      }
    );

    // Cleanup function to unsubscribe from the snapshot listener
    return () => {
      unsubscribe();
    };
  }, [params.caseID, params.id, db]);

  return {
    isLoadingGetFormsData,
    formsData,
    isFormsNotFound
  };
};
