import { combineReducers } from "redux";
import lawyerReducer from "./lawyer/lawyerSlice";
import clientsReducer from "./clients/clientsSlice";
import activeClientReducer from "./client-case/activeClientSlice";
import individualReducer from "./individual/individualSlice";
import documentReducer from "./documents/documentSlice";
import extractionReducer from "./extraction-jobs/extractionJobsSlice";
import fileUploadReducer from "./file-upload/fileUploadSlice";
import caseResearchReducer from "./case-research/caseResearchSlice";
import draftsReducer from "./drafts/draftsSlice";
import formsReducer from "./forms/formsSlice";

const rootReducer = combineReducers({
  lawyer: lawyerReducer,
  clients: clientsReducer,
  activeClient: activeClientReducer,
  individual: individualReducer,
  document: documentReducer,
  extraction: extractionReducer,
  fileUpload: fileUploadReducer,
  caseResearch: caseResearchReducer,
  drafts: draftsReducer,
  forms: formsReducer
});

export default rootReducer;

export type RootReducer = ReturnType<typeof rootReducer>;
