import {
  MenuList,
  MenuGroup,
  MenuItem,
  MenuDivider,
  Button,
  Divider
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { UserAvatar } from "./UserAvatar";
import { useLogOut } from "../hooks/useLogOut";
import { auth } from "../api/firebaseApi";
import { useGetUserDetails } from "../hooks/useGetUserDetails";

export const UserMenuItems = () => {
  const navigate = useNavigate();

  const { onSubmitLogOut } = useLogOut();
  const {
    userDetails: { userType, id }
  } = useGetUserDetails();

  const handleSignOut = async () => {
    if (auth.currentUser) {
      await onSubmitLogOut();
    } else {
      navigate("/login");
    }
  };

  return (
    <MenuList className="" fontWeight={600} height="auto">
      <MenuGroup>
        <MenuItem
          px={4}
          as={Link}
          to={`${
            userType === "Lawyer"
              ? "/account"
              : `/individual/${id}/account/profile`
          }`}
          mb={4}
        >
          <UserAvatar withInfo />
        </MenuItem>
      </MenuGroup>

      {userType === "Lawyer" && (
        <MenuGroup>
          <MenuItem as={Link} to="/account/0" px={4}>
            Profile Settings
          </MenuItem>
          {/* <MenuItem as={Link} to="/account/1" px={4}>
            Payment & Subscription
          </MenuItem> */}
        </MenuGroup>
      )}
      {userType === "Individual" && (
        <MenuGroup>
          <MenuItem as={Link} to={`/individual/${id}/account/profile`} px={4}>
            Profile Settings
          </MenuItem>
        </MenuGroup>
      )}
      <MenuDivider h={2} my={4} color="black" w="full" />

      <MenuGroup>
        <div className="text-left">
          <Button
            variant="secondaryOutline"
            textAlign="left"
            onClick={handleSignOut}
            ml={2}
          >
            Log out
          </Button>
        </div>
      </MenuGroup>
    </MenuList>
  );
};
