import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { UpdateAvatar } from "../lawyer/lawyerSlice";
import { VISAVALUE } from "../../types/tables-data";
import {
  AddOverviewStepsData,
  ExpertLetter,
  FirstForm,
  SecondForm,
  UpdateOverviewStep
} from "../clients/clientsSlice";
import { ExpertLetterDraft } from "../../types/expert-letter-draft";

interface EmployerInformations {
  name: string | null;
  addressLine1: string | null;
  city: string | null;
  state: string | null;
  zipcode: string | null;
  contact_name: string | null;
  contact_email: string | null;
  contact_phone: string | null;
}
export interface IndividualState {
  // Personal Information
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: string;
  dob: string;
  pronouns: string | null;
  otherPronouns: string | null;
  title: string | null;
  // Employment Information
  company: string;
  // Visa Information
  visaType: VISAVALUE;
  // Avatar Information
  avatarSrc: string;
  avatarName: string;
  // User Identification
  uid: string;
  // Action and Case Information
  actionNeeded: boolean;
  caseId: number;
  // Citizenship Information
  citizenship: string;
  countryob: string;
  // Status Information
  status: number;
  isDeleted: boolean;
  // Notes and Documents
  notes: string[];
  documents: any[];
  // Overview
  overview: {
    steps: any[];
  };

  employer_addressLine1: string | null;
  employer_city: string | null;
  employer_state: string | null;
  employer_zipcode: string | null;
  employer_name: string | null;
  employer_contact_email: string | null;
  employer_contact_name: string | null;
  employer_contact_phone: string | null;

  addressLine1: string | null;
  city: string | null;
  state: string | null;
  zipcode: string | null;

  // U.S. Address
  usAddressInCareOf: string | null;
  usAddressStreetNumber: string | null;
  usAddressStreetName: string | null;
  usAddressType: string | null;
  usAddressAptSteFloor: string | null;
  usAddressCityOrTown: string | null;
  usAddressState: string | null;
  usAddressProvince: string | null;
  usAddressZipCode: string | null;
  // Foreign Address
  foreignAddressInCareOf: string | null;
  foreignAddressStreetNumber: string | null;
  foreignAddressStreetName: string | null;
  foreignAddressType: string | null;
  foreignAddressAptSteFloor: string | null;
  foreignAddressCityOrTown: string | null;
  foreignAddressState: string | null;
  foreignAddressProvince: string | null;
  foreignAddressPostalCode: string | null;
  // Questionnaire Fields (if applicable)
  questionnaireFirst?: FirstForm;
  questionnaireSecond?: SecondForm;
  questionnaireLetter?: ExpertLetter[] | null;
  activeExpertLetter?: ExpertLetter | null;
  expertsDrafts?: Map<string, ExpertLetterDraft> | null;

  hasAcceptedTerms: boolean | false;
  individualPortalAccessMap?: Map<string, boolean> | null;
}

export interface UpdateIndividual {
  firstName: string;
  middleName: string;
  lastName: string;
  company: string;
  dob: string;
  countryob: string;
  email: string;
  pronouns: string | null;
  title: string | null;
  otherPronouns: string | null;
  usAddressInCareOf: string | null;
  usAddressStreetNumber: string | null;
  usAddressStreetName: string | null;
  usAddressType: string | null;
  usAddressAptSteFloor: string | null;
  usAddressCityOrTown: string | null;
  usAddressState: string | null;
  usAddressProvince: string | null;
  usAddressZipCode: string | null;
  foreignAddressInCareOf: string | null;
  foreignAddressStreetNumber: string | null;
  foreignAddressStreetName: string | null;
  foreignAddressType: string | null;
  foreignAddressAptSteFloor: string | null;
  foreignAddressCityOrTown: string | null;
  foreignAddressState: string | null;
  foreignAddressProvince: string | null;
  foreignAddressPostalCode: string | null;
  visaType: string | null;
}

const initialState: IndividualState = {
  firstName: "",
  middleName: "",
  lastName: "",
  dob: "",
  email: "",
  phone: "",
  pronouns: "",
  otherPronouns: "",
  title: "",
  company: "",
  visaType: VISAVALUE.EMPTY,
  avatarSrc: "",
  avatarName: "",
  uid: "",
  actionNeeded: false,
  caseId: 0,
  citizenship: "",
  countryob: "",
  status: 1,
  isDeleted: false,
  notes: [],
  documents: [],
  overview: {
    steps: []
  },
  employer_addressLine1: "",
  employer_city: "",
  employer_state: "",
  employer_zipcode: "",
  employer_contact_email: "",
  employer_contact_name: "",
  employer_contact_phone: "",
  employer_name: "",
  addressLine1: "",
  city: "",
  state: "",
  zipcode: "",
  usAddressInCareOf: "",
  usAddressStreetNumber: "",
  usAddressStreetName: "",
  usAddressType: "",
  usAddressAptSteFloor: "",
  usAddressCityOrTown: "",
  usAddressState: "",
  usAddressProvince: "",
  usAddressZipCode: "",
  foreignAddressInCareOf: "",
  foreignAddressStreetNumber: "",
  foreignAddressStreetName: "",
  foreignAddressType: "",
  foreignAddressAptSteFloor: "",
  foreignAddressCityOrTown: "",
  foreignAddressState: "",
  foreignAddressProvince: "",
  foreignAddressPostalCode: "",
  hasAcceptedTerms: false,
  individualPortalAccessMap: new Map<string, boolean>()
};

export interface IndividualReducer {
  individual: IndividualState;
}

export const individualSlice = createSlice({
  name: "individual",
  initialState,
  reducers: {
    setIndividualData: (state, action: PayloadAction<IndividualState>) =>
      action.payload,
    resetIndividualData: () => initialState,
    setIndividualUID: (state, action: PayloadAction<string>) => {
      state.uid = action.payload;
    },
    updateIndividualEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    updateIndividualAvatar: (state, action: PayloadAction<UpdateAvatar>) => {
      state.avatarSrc = action.payload.avatarSrc;
      state.avatarName = action.payload.avatarName;
    },
    updateIndividualData: (
      state,
      { payload }: PayloadAction<UpdateIndividual>
    ) => {
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.company = payload.company;
      state.dob = payload.dob;
      state.pronouns = payload.pronouns;
      state.otherPronouns = payload.otherPronouns;
      state.title = payload.title;
      state.usAddressInCareOf = payload.usAddressInCareOf;
      state.usAddressStreetNumber = payload.usAddressStreetNumber;
      state.usAddressStreetName = payload.usAddressStreetName;
      state.usAddressType = payload.usAddressType;
      state.usAddressAptSteFloor = payload.usAddressAptSteFloor;
      state.usAddressCityOrTown = payload.usAddressCityOrTown;
      state.usAddressState = payload.usAddressState;
      state.usAddressProvince = payload.usAddressProvince;
      state.usAddressZipCode = payload.usAddressZipCode;
      state.foreignAddressInCareOf = payload.foreignAddressInCareOf;
      state.foreignAddressStreetNumber = payload.foreignAddressStreetNumber;
      state.foreignAddressStreetName = payload.foreignAddressStreetName;
      state.foreignAddressType = payload.foreignAddressType;
      state.foreignAddressAptSteFloor = payload.foreignAddressAptSteFloor;
      state.foreignAddressCityOrTown = payload.foreignAddressCityOrTown;
      state.foreignAddressState = payload.foreignAddressState;
      state.foreignAddressProvince = payload.foreignAddressProvince;
      state.foreignAddressPostalCode = payload.foreignAddressPostalCode;
    },
    addOverviewStepsToIndiv: (
      state,
      { payload }: PayloadAction<AddOverviewStepsData>
    ) => {
      state.overview.steps = payload.steps;
    },
    updateOverviewStep: (
      state,
      { payload }: PayloadAction<UpdateOverviewStep>
    ) => {
      if (payload.stepIndex !== undefined) {
        state.overview.steps[payload.stepIndex].isComplete = payload.value;
        state.status = state.overview.steps.reduce((acc, step) => {
          if (step.isComplete) {
            acc += 1;
          }
          return acc;
        }, 1);
      }
    },
    // TODO : Refactor expert letters to the drafts reducer
    addExpertLetter: (state, { payload }: PayloadAction<ExpertLetter>) => {
      state.questionnaireLetter?.push(payload);
    },
    setExpertLetters: (state, { payload }: PayloadAction<ExpertLetter[]>) => {
      state.questionnaireLetter = payload;
    },
    setExpertLettersDrafts: (
      state,
      { payload }: PayloadAction<Map<any, any>>
    ) => {
      state.expertsDrafts = payload;
    },
    clearExpertLettersDrafts: (state) => {
      state.expertsDrafts = null;
    },
    clearExpertLetters: (state) => {
      state.questionnaireLetter = [];
    },
    addQuestionnaireFirst: (state, { payload }: PayloadAction<FirstForm>) => {
      state.questionnaireFirst = payload;
    },
    addQuestionnaireSecond: (state, { payload }: PayloadAction<SecondForm>) => {
      state.questionnaireSecond = payload;
    },
    deleteQuestionnairesForms: (state) => {
      delete state.questionnaireFirst;
      delete state.questionnaireSecond;
    },
    deleteExpertLetter: (state) => {
      delete state.questionnaireLetter;
    },
    setActiveExpertLetter: (
      state,
      { payload }: PayloadAction<ExpertLetter | string | null>
    ) => {
      if (typeof payload === "string") {
        console.log(`Searching for ExpertLetter with uid: ${payload}`);
        const foundExpertLetter = state.questionnaireLetter?.find(
          (letter) => letter.uid === payload
        );
        if (foundExpertLetter) {
          console.log(`ExpertLetter found:`, foundExpertLetter);
        } else {
          console.warn(`No ExpertLetter found with uid: ${payload}`);
          console.log("experts : ", state.questionnaireLetter);
        }
        state.activeExpertLetter = foundExpertLetter || null;
      } else {
        console.log(`Setting active ExpertLetter directly:`, payload);
        state.activeExpertLetter = payload;
      }
      console.log("Active ExpertLetter updated:", state.activeExpertLetter);
    },

    removeResume: (state) => {
      if (state.activeExpertLetter) {
        state.activeExpertLetter = {
          ...state.activeExpertLetter,
          filePath: undefined,
          uploadDate: 0
        };
      }
    }
  }
});

export const {
  setIndividualData,
  setIndividualUID,
  setActiveExpertLetter,
  resetIndividualData,
  updateIndividualData,
  updateIndividualEmail,
  updateIndividualAvatar,
  addOverviewStepsToIndiv,
  updateOverviewStep,
  addExpertLetter,
  addQuestionnaireFirst,
  addQuestionnaireSecond,
  deleteQuestionnairesForms,
  deleteExpertLetter,
  setExpertLetters,
  removeResume,
  clearExpertLetters,
  clearExpertLettersDrafts
} = individualSlice.actions;

export default individualSlice.reducer;
