// theme/heading.style.ts

import { defineStyleConfig } from "@chakra-ui/react";

// Define the heading styles
export const headingTheme = defineStyleConfig({
  baseStyle: {
    color: "#00004D",
    fontWeight: "bold"
  },
  sizes: {
    "2xl": {
      fontSize: "3xl", // Adjust as needed
      lineHeight: "shorter"
    },
    xl: {
      fontSize: "2xl", // Adjust as needed
      lineHeight: "shorter"
    },
    lg: {
      fontSize: "xl", // Adjust as needed
      lineHeight: "shorter"
    },
    md: {
      fontSize: "lg", // Adjust as needed
      lineHeight: "shorter"
    },
    sm: {
      fontSize: "md", // Adjust as needed
      lineHeight: "shorter"
    },
    xs: {
      fontSize: "sm", // Adjust as needed
      lineHeight: "shorter"
    }
  },
  variants: {
    primary: {
      color: "text.display.light"
    },
    secondary: {
      color: "text.display.grey" // Add any secondary color variant
    }
  },
  defaultProps: {
    size: "md",
    variant: "primary"
  }
});
