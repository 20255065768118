import { Flex, Text, useToast } from "@chakra-ui/react";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../../api/firebaseApi";
import { formatDate } from "../../../helpers/date_helpers";
import { getUserType } from "../../../helpers/helpers";
import useFirestoreDocument from "../../../hooks/useFirestoreDocument";
import { useGenerateDrafts } from "../../../hooks/useGenerateDrafts";
import { useDownloadFile } from "../../../hooks/useDownloadFile";
import { useSubscribeToJob } from "../../../hooks/useSubscribeToJob";
import {
  ExtractionJob,
  ExtractionReducer,
  EXTRACTIONSTATES,
  GenerationStatesDescription
} from "../../../redux/extraction-jobs/extractionJobsSlice";
import { extractionSelectors } from "../../../redux/extraction-jobs/selectors";
import { individualSelectors } from "../../../redux/individual/selectors";
import { lawyerSelectors } from "../../../redux/lawyer/selectors";
import { BriefGenerationRequest } from "../../../types/brief-generation-request";
import { DATABASE, visaFromPath, VISAVALUE } from "../../../types/tables-data";
import { CustomAlertDialog } from "../../common/CustomAlertDialog";
import { CustomBox } from "../../common/CustomBox";
import { CustomButton } from "../../common/CustomButton";
import { ExtractionCard } from "../../common/ExtractionCard";
import { PremiumModal } from "../../common/PremiumModal";
import { SpecialHeading } from "../PanelLayout";
import { ReplaceDialog } from "../individualDrafts/sopDrafts/ReplaceDialog";
import GenerateLegalBriefModal from "../individualDrafts/sopDrafts/generateLegalBriefModal";
import { LegalBriefType } from "../../../types/drafts.type";
import { getFileName, triggerDownload } from "../../../helpers/file_helpers";
import GenerateCasePacketModal from "./generateCasePacketModal";
import { useUserRoleAndSubscription } from "../../../hooks/useUserRoleAndSubscription";
import { DocumentsInfoModal } from "../../common/DocumentsInfoModal";

export const PacketGenerateSection = () => {
  const { id, visaType } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const uid = id ?? useSelector(individualSelectors.selectUid);
  const userEmail = useSelector(individualSelectors.selectEmail);
  const lawyeruid = useSelector(lawyerSelectors.selectUid);
  const lawyerEmail = useSelector(lawyerSelectors.selectEmail);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);
  const [isOverrideModalOpen, setIsOverrideModalOpen] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [isPremiumModalOpen, setIsPremiumModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState<LegalBriefType>(
    LegalBriefType.LEGAL_BRIEF
  );

  const {
    isSuperAdmin,
    isFullUser,
    isStandardUser,
    isFreemiumUser,
    isPaidUser
  } = useUserRoleAndSubscription();

  const loginType = getUserType();
  const { subscription } = useSelector(lawyerSelectors.selectAll);

  const { document: draftDocument, fetchDocument } = useFirestoreDocument(
    DATABASE.DRAFTS,
    uid
  );

  const {
    isLoadingDownloadFile,
    onSubmitDownloadFile,
    apiResponseDownloadFile
  } = useDownloadFile();

  const [isLoading, setIsLoading] = useState(true);

  const { onSubmitGeneratePacket } = useGenerateDrafts(id);
  const { initiateJob, resetFields } = useSubscribeToJob();

  const packetJob = useSelector((state: ExtractionReducer) =>
    extractionSelectors.selectJobById(state, "Final Packet")
  );

  const statusMsg = useMemo(() => {
    const state = packetJob?.status?.status as EXTRACTIONSTATES;
    return (
      GenerationStatesDescription[state] ??
      GenerationStatesDescription.NotStarted
    );
  }, [packetJob]);

  const status = packetJob?.status?.status;

  const isGenerated = useMemo(
    () => status === EXTRACTIONSTATES.Completed,
    [status]
  );

  const isGenerating = useMemo(
    () => status === EXTRACTIONSTATES.Processing,
    [status]
  );

  const loadDocument = () => {
    setIsLoading(true);
    fetchDocument().then(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    loadDocument();
  }, [uid]);

  const handleDeleteConfirm = async (): Promise<void> => {
    try {
      await resetFields(packetJob, EXTRACTIONSTATES.NotStarted);
      toast({
        title: "Success",
        description: "Draft deleted successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right"
      });
    } catch (e) {
      console.error(e);
    } finally {
      setIsDeleteDialogOpen(false);
    }
  };

  const handleGenerateConfirm = async (): Promise<void> => {
    setIsConfirmationDialogOpen(false);
    await fetchDocument();
    const job: ExtractionJob = {
      id: "Final Packet",
      type: "Generating Final Packet",
      status: {
        status: EXTRACTIONSTATES.Pending
      },
      docRef: doc(db, DATABASE.DRAFTS, `${uid}`),
      jobRef: doc(db, DATABASE.ACTIVE_JOBS, `${uid}`, "jobs", "Final Packet"),
      toastTitle: "Job Completed",
      toastDesc: "The generation job has been completed.",
      docName: "",
      fieldPath: "packet"
    };

    await initiateJob(job);

    await onSubmitGeneratePacket({
      individualId: uid,
      useAsync: true
    });

    setIsGenerateModalOpen(false);
  };

  const handleClick = async () => {
    const documentReference = doc(db, `${DATABASE.DRAFTS}/${uid}`);
    const documentSnapshot = await getDoc(documentReference);
    if (documentSnapshot) {
      const docData = documentSnapshot.data();
      if (docData && "packet" in docData) {
        const packetData = docData.packet;
        if ("filePath" in packetData) {
          const data = await onSubmitDownloadFile(packetData.filePath);
          const fileName = getFileName(packetData.filePath);
          triggerDownload(data, fileName, "pdf");
        }
      }
    }
  };

  const handleGenerateModalConfirm = async () => {
    setIsGenerateModalOpen(false);

    if (isGenerated) {
      setIsConfirmationDialogOpen(true);
    } else {
      // setIsGenerateModalOpen(true);
      await handleGenerateConfirm();
    }
  };

  const openGenerateDialog = () => {
    if (isFreemiumUser) {
      setIsPremiumModalOpen(true);
      return;
    }
    setIsGenerateModalOpen(true);
    // handleGenerateModalConfirm();
  };

  const isExhibitListEmpty = () => {
    if (isLoading) {
      return false;
    }
    if (subscription?.status === "freemium") {
      return false;
    }
    const text = (draftDocument as any)?.exhibit_list?.html;
    return !text || text.trim() === "";
  };

  return (
    <>
      <Flex flexDirection="column" rowGap="20px">
        <SpecialHeading
          title="Final Packet"
          withInfo={<DocumentsInfoModal title="Final packet" />}
        />

        {isExhibitListEmpty() &&
          loginType === "lawyer" &&
          (isFullUser || isFreemiumUser || isSuperAdmin) && (
            <CustomBox
              type="info"
              onClick={() => loadDocument()}
              style={{ cursor: "pointer" }}
            >
              <Text fontSize="18px">
                You must generate the Exhibit List before you can generate the
                full packet.
                <br />
                <b>CLICK HERE TO REFRESH</b>
              </Text>
            </CustomBox>
          )}
        <ExtractionCard
          locked={!isSuperAdmin && !isFullUser}
          type="packet"
          id={id}
          statusMsg={statusMsg}
          extractedData={null}
          onView={handleClick}
          showIcons={statusMsg === "Generated"}
          hideEdit
          hideDelete
          onExtractionDelete={() => setIsDeleteDialogOpen(true)}
          onClick={() => {
            handleClick();
          }}
        />

        {(loginType === "individual" ||
          isFullUser ||
          isFreemiumUser ||
          isSuperAdmin) && (
          <CustomButton
            type="ai"
            title="Generate"
            isPremium
            isDisabled={isExhibitListEmpty()}
            isLoading={isGenerating}
            // onClick={openGenerateDialog}
            onClick={handleGenerateModalConfirm}
          />
        )}

        <PremiumModal
          isOpen={isPremiumModalOpen}
          onClose={() => setIsPremiumModalOpen(false)}
        />
      </Flex>

      {/* <GenerateCasePacketModal
       isOpen={isGenerateModalOpen}
       onConfirm={handleGenerateModalConfirm}
       onCancel={() => {
         setIsGenerateModalOpen(false);
       }}
      /> */}

      <ReplaceDialog
        isOpen={isConfirmationDialogOpen}
        onConfirm={handleGenerateConfirm}
        setShowConfirmationDialog={setIsConfirmationDialogOpen}
        isLoading={false}
        jobType="generated"
        jobName="Case Packet"
      />
    </>
  );
};
