import {
  deleteField,
  doc,
  getDoc,
  onSnapshot,
  updateDoc
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../api/firebaseApi";
import { DATABASE } from "../types/tables-data";

import { LegalBriefSection, setLegalBrief } from "../redux/drafts/draftsSlice";
import { individualSelectors } from "../redux/individual/selectors";

export const useIndivSop = (indivId: string) => {
  const dispatch = useDispatch();
  const email = useSelector(individualSelectors.selectEmail);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingSop, setIsLoadingSop] = useState(false);
  const [deleteError, setDeleteError] = useState<any>(null);
  const [getError, setGetError] = useState<any>(null);
  const [updateError, setUpdateError] = useState<any>(null);

  const deleteSop = async () => {
    if (!indivId) return;

    const docRef = doc(db, `${DATABASE.DRAFTS}/${indivId}`);
    setIsLoadingDelete(true);

    try {
      await updateDoc(docRef, {
        sop: deleteField(),
        last_updated_by: email
      });
    } catch (e) {
      console.error("DELETE_SOP: Error deleting SOP:", e);
      setDeleteError(e);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const updateSop = async (newSectionData: any, newAccessEnabled: any) => {
    if (!indivId) return;

    const docRef = doc(db, `${DATABASE.DRAFTS}/${indivId}`);
    setIsLoadingUpdate(true);

    try {
      await updateDoc(docRef, {
        "sop.sections": newSectionData,
        "sop.accessEnabled": newAccessEnabled,
        last_updated_by: email
      });
    } catch (e) {
      console.error("UPDATE_SOP: Error updating SOP:", e);
      setUpdateError(e);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  useEffect(() => {
    if (!indivId) return undefined;

    const documentReference = doc(db, `${DATABASE.DRAFTS}/${indivId}`);

    const unsubscribe = onSnapshot(
      documentReference,
      (documentSnapshot) => {
        if (!documentSnapshot.exists()) {
          setGetError(new Error("No such document"));
          return;
        }
        setIsLoadingSop(true);

        const sopData = documentSnapshot.data()?.sop ?? {};

        const sections: LegalBriefSection[] = Array.isArray(sopData?.sections)
          ? sopData?.sections?.map((section: LegalBriefSection) => ({
              html: section?.html || "",
              text: section?.text || "",
              section_name: section?.section_name || "",
              display_name: section?.display_name || ""
            }))
          : [];

        const sopDraft = {
          value: {
            generated_text: sopData?.generated_text ?? "",
            generated_html: sopData?.generated_html ?? "",
            accessEnabled: sopData?.accessEnabled ?? false,
            sections
          }
        };

        dispatch(setLegalBrief(sopDraft));
        setIsLoadingSop(false);
      },
      (error) => {
        console.error("SOP_SNAPSHOT: Error fetching SOP:", error);
        setGetError(error);
        setIsLoadingSop(false);
      }
    );

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [indivId]);

  return {
    getError,
    isLoadingSop,
    updateError,
    isLoadingUpdate,
    updateSop,
    deleteSop,
    isLoadingDelete,
    deleteError
  };
};
