import {
  ArrowDownIcon,
  ArrowForwardIcon,
  DeleteIcon,
  EditIcon,
  ExternalLinkIcon
} from "@chakra-ui/icons";
import {
  Badge,
  Button,
  Container,
  Divider,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure
} from "@chakra-ui/react";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  setDoc,
  updateDoc
} from "firebase/firestore";
import { deleteObject, ref } from "firebase/storage";
import { useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { db, storage } from "../../../api/firebaseApi";
import { formatDate } from "../../../helpers/date_helpers";
import {
  copyDocumentToNewCollection,
  getDocumentExhibitById,
  getDocumentTypesByTypeVisaCategory,
  getSubExhibitNumberArray,
  openFilePreview
} from "../../../helpers/helpers";
import { getShortString } from "../../../helpers/string_helpers";
import useFileActions from "../../../hooks/useFileActions";
import {
  DocumentReducer,
  deleteSubDocument,
  setSubDocuments
} from "../../../redux/documents/documentSlice";
import { documentSelectors } from "../../../redux/documents/selectors";
import { DATABASE, DataDocs } from "../../../types/tables-data";
import { UploadFilesPlaceholder } from "../../UploadFilesPlaceholder";
import { Dropdown } from "../../common/Dropdown";
import { FileExtensionThumbnail } from "../../common/Filethumbnail";
import { TargetBox } from "../../inputDnD/TargetBox";
import { SpecialHeading } from "../PanelLayout";
import { VisaDocumentType } from "../../../redux/documents/types";
import { useTypedDispatch } from "../../../redux/store";
import { generateDocumentUrls } from "../../../redux/documents/documentsThunk";
import { visaDocuments } from "../../../consts/strings";

export const EditStandardDocument = () => {
  const { docId, id: uid, visaType } = useParams();
  const documentTypes = useSelector(documentSelectors.documentTypes);

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const foundDocument = useSelector((state: DocumentReducer) =>
    documentSelectors.selectStandardDocById(state, docId!)
  );
  const standardExhibitMap = useSelector(documentSelectors.standardExhibitMap);
  const [document, setDocument] = useState(foundDocument);
  const { handleFileActions } = useFileActions();
  const [subDocument, setSubDocument] = useState<DataDocs | null>(null);
  const [expanded, setExpanded] = useState(true);
  const { type } = document!;

  const mainDocumentExhibit = getDocumentExhibitById(
    standardExhibitMap!,
    document?.id ?? ""
  );
  const subDocumentsExhibitArray = getSubExhibitNumberArray(
    mainDocumentExhibit,
    document?.sub_documents?.length ?? 0
  );
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isDocumentEditOpen,
    onClose: onDocumentEditClose,
    onOpen: onDocumentEditOpen
  } = useDisclosure();
  const {
    isOpen: isSubEditOpen,
    onClose: onSubEditClose,
    onOpen: onSubEditOpen
  } = useDisclosure();
  const {
    isOpen: isSubOpen,
    onClose: onSubClose,
    onOpen: onSubOpen
  } = useDisclosure();
  const [isDeleting, setIsDeleting] = useState(false);
  const mainDocRef = doc(db, document?.docRef ?? "");

  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  useEffect(() => {
    if (document) {
      const fetchSubDocuments = async () => {
        const collectionRef = collection(
          db,
          `${document.docRef}/sub_documents`
        );
        const subDocuments: DataDocs[] = [];
        const subDocumentsSnapshot = await getDocs(collectionRef);

        subDocumentsSnapshot.forEach((doc) => {
          const subDocument = doc.data() as DataDocs;
          subDocuments.push({ id: doc.id, ...subDocument });
        });
        dispatch(
          setSubDocuments({
            data: { documents: subDocuments, id: docId ?? "" },
            type: VisaDocumentType.Standard
          })
        );
        dispatch(
          generateDocumentUrls({
            documents: subDocuments.map((doc) => ({
              id: doc.id as string,
              filePath: doc.filePath as string
            })),
            type: VisaDocumentType.Standard,
            mainDocId: docId
          })
        );
      };

      fetchSubDocuments();
    }
  }, []);

  useEffect(() => {
    if (foundDocument) setDocument(foundDocument);
  }, [foundDocument?.sub_documents]);

  // const deleteFilePromises = document?.sub_documents?.map((subDoc) => {
  //   const fileRef = ref(storage, subDoc.filePath);
  //   return deleteObject(fileRef);
  // });

  return (
    <Container py={2}>
      <SpecialHeading
        title={`Editing document: ${document?.documentTitle}`}
        backNav
      />

      <div className="flex flex-col gap-4">
        <DndProvider backend={HTML5Backend} context={window}>
          <TargetBox
            onDrop={(item) => {
              if (document) {
                const path = `${DATABASE.INDIVIDUALS}/documents/${uid!}`;

                const { files } = item;
                const filesAsArray = Array.from(files!);
                handleFileActions({
                  files: filesAsArray,
                  path,
                  uid: uid!,
                  mainDocumentId: document.id ?? "",
                  type: VisaDocumentType.Standard,
                  visaDocumentType: VisaDocumentType.Standard
                });
              }
            }}
          >
            <TableContainer whiteSpace="pre-wrap">
              <Table>
                <Thead>
                  <Tr>
                    <Th>Exhibit</Th>
                    <Th>Document</Th>
                    <Th>Type</Th>
                    <Th>Date</Th>
                    <Th>Actions</Th>
                    <Th />
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr className="shadow-sm">
                    <Td>{mainDocumentExhibit}</Td>
                    <Td className="">
                      <div className="inline-flex items-center gap-2">
                        <IconButton
                          variant="filledIconButton"
                          size="sm"
                          aria-label=""
                          icon={
                            expanded ? <ArrowDownIcon /> : <ArrowForwardIcon />
                          }
                          onClick={() => {
                            setExpanded(!expanded);
                          }}
                        />{" "}
                        <div className="inline-flex gap-2 ">
                          <FileExtensionThumbnail doc={document!} />
                          <Tooltip label={document?.docNames}>
                            <span> {getShortString(document?.docNames)}</span>
                          </Tooltip>

                          <Badge bg="primary.blue" textColor="white" ml={2}>
                            main doc
                          </Badge>
                          <ExternalLinkIcon
                            className="cursor-pointer"
                            onClick={() => openFilePreview(document?.docUrl)}
                            ml={2}
                          />
                        </div>
                      </div>
                    </Td>
                    <Td>
                      {document?.type && typeof document?.type === "object"
                        ? (document.type as any).title
                        : document?.type}
                    </Td>
                    <Td>{formatDate(document?.uploadDate)}</Td>
                    <Td className="flex gap-2">
                      <IconButton
                        variant="destructiveIconButton"
                        icon={<DeleteIcon />}
                        onClick={() => {
                          onOpen();
                        }}
                        aria-label=""
                      />
                      <IconButton
                        variant="filledIconButton"
                        icon={<EditIcon />}
                        onClick={() => {
                          onDocumentEditOpen();
                        }}
                        aria-label=""
                      />
                    </Td>
                  </Tr>
                  {expanded &&
                    document?.sub_documents?.map((doc, indx) => {
                      return (
                        <Tr key={doc.id}>
                          <Td>{subDocumentsExhibitArray[indx]}</Td>
                          <Tooltip label={doc?.docNames}>
                            <Td className="">
                              <div className="inline-flex items-start gap-2">
                                <FileExtensionThumbnail doc={doc} />
                                <span>{getShortString(doc?.docNames)}</span>
                              </div>
                              <ExternalLinkIcon
                                className="cursor-pointer"
                                onClick={() => openFilePreview(doc?.docUrl)}
                                ml={2}
                              />
                            </Td>
                          </Tooltip>
                          <Td>-</Td>
                          <Td>{formatDate(doc.uploadDate)}</Td>
                          <Td>
                            <Flex flexGrow="0" gap="15px">
                              <IconButton
                                variant="destructiveIconButton"
                                icon={<DeleteIcon />}
                                onClick={() => {
                                  setSubDocument(doc);
                                  onSubOpen();
                                }}
                                aria-label=""
                              />
                              <IconButton
                                variant="filledIconButton"
                                icon={<EditIcon />}
                                onClick={() => {
                                  setSubDocument(doc);
                                  onSubEditOpen();
                                }}
                                aria-label=""
                              />
                            </Flex>
                          </Td>
                        </Tr>
                      );
                    })}
                  {expanded && document && (
                    <Tr>
                      <Td colSpan={5}>
                        <UploadFilesPlaceholder
                        allowUploadByCategory
                        tableRef="standard"
                          styleIndex={
                          1
                          }
                          title="Drag and drop additional files to bundle with this document here"
                          subTitle="Click Here to Upload"
                          onUpload={(files) => {
                            const filesAsArray = Array.from(files!);
                            const path = `${
                              DATABASE.INDIVIDUALS
                            }/documents/${uid!}`;

                            handleFileActions({
                              files: filesAsArray,
                              path,
                              uid: uid!,
                              mainDocumentId: document.id ?? "",
                              type: VisaDocumentType.Standard,
                              visaDocumentType: VisaDocumentType.Standard
                            });
                          }}
                        />
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </TargetBox>
          <Flex flexDirection="row" columnGap={4} justifyContent="center">
            <Button
              my={4}
              variant="secondaryOutline"
              onClick={() =>
                navigate(
                  `/individual/${uid}/${visaType}/documents/standard-documents`
                )
              }
              width="fit-content"
            >
              Back
            </Button>
          </Flex>
        </DndProvider>
        {/* mainDoc delete modal */}
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirmation</ModalHeader>
            <ModalCloseButton
              onClick={() => {
                onClose();
              }}
            />
            <ModalBody pb={6}>
              Are you sure you want to delete the document? <br />
              <small>
                This action will permanently delete all associated
                sub-documents.
              </small>
            </ModalBody>

            <ModalFooter>
              <Button
                variant="secondaryOutline"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="destructiveFilled"
                ml={3}
                // isLoading={isDeleting}
                onClick={async () => {
                  setIsDeleting(true);
                  await updateDoc(mainDocRef, { isDeleted: true });
                  setIsDeleting(false);
                  onClose();
                  navigate(
                    `/individual/${uid}/${visaType}/documents/standard-documents`
                  );
                }}
              >
                Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* SubDocument delete modal */}
        <Modal
          closeOnOverlayClick={false}
          isOpen={isSubOpen}
          onClose={onSubClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirmation</ModalHeader>
            <ModalCloseButton
              onClick={() => {
                onClose();
              }}
            />
            <ModalBody pb={6}>
              Are you sure you want to delete this document?
            </ModalBody>

            <ModalFooter>
              <Button
                onClick={() => {
                  onSubClose();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="destructiveFilled"
                ml={3}
                isLoading={isDeleting}
                onClick={async () => {
                  const subDocFileRef = ref(storage, subDocument?.filePath);

                  const subDocRef = subDocument
                    ? doc(
                        db,
                        `${mainDocRef.path.concat(
                          "/sub_documents/",
                          subDocument.id!
                        )}`
                      )
                    : null;
                  if (subDocRef && subDocument) {
                    await deleteObject(subDocFileRef);

                    setIsDeleting(true);
                    await deleteDoc(subDocRef);
                    dispatch(
                      deleteSubDocument({
                        data: { document: subDocument, id: document?.id ?? "" },
                        type: VisaDocumentType.Standard
                      })
                    );
                    setIsDeleting(false);
                    onSubClose();
                  } else {
                    onSubClose();
                  }
                }}
              >
                Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* Edit Main document */}
        <Modal
          closeOnOverlayClick={false}
          isOpen={isDocumentEditOpen}
          onClose={onDocumentEditClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Editing Main Document</ModalHeader>
            <ModalCloseButton
              onClick={() => {
                onDocumentEditClose();
              }}
            />
            <ModalBody pb={6}>
              {document && documentTypes && (
                <>
                  <Text>Category</Text>
                  <Dropdown
                    title="Category"
                    selectedValue={document.criterion ? document.criterion : ""}
                    list={Object.keys(
                      getDocumentTypesByTypeVisaCategory(
                        documentTypes,
                        VisaDocumentType.Standard
                      )
                    )}
                    onItemSelect={(value) => {
                      setDocument((document) =>
                        document
                          ? { ...document, criterion: value, type: undefined }
                          : undefined
                      );
                    }}
                  />
                  <Text>Type</Text>
                  <Dropdown
                    title="Type"
                    selectedValue={document.type ? document.type : ""}
                    list={
                      document.criterion
                        ? getDocumentTypesByTypeVisaCategory(
                            documentTypes,
                            VisaDocumentType.Standard,
                            null,
                            document.criterion
                          )
                        : [""]
                    }
                    onItemSelect={(value) => {
                      setDocument((document) =>
                        document ? { ...document, type: value } : undefined
                      );
                    }}
                  />
                </>
              )}
              <Divider my={5} />
              <div className="inline-flex gap-2">
                <div className="w-4 h-4 ">
                  <FileExtensionThumbnail doc={document!} />
                </div>
                <span>{document?.documentTitle}</span>
              </div>
              <br />
              <small>you can change the category of the document</small>
            </ModalBody>

            <ModalFooter>
              <Button
                variant="secondaryOutline"
                onClick={() => {
                  setDocument(foundDocument);
                  onDocumentEditClose();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primaryFilled"
                ml={3}
                isLoading={isDeleting}
                onClick={async () => {
                  const dataPayload: DataDocs = {
                    ...document,
                    uploadBy: "",
                    uploadDate: 0,
                    documentTitle: (document?.type as any) ?? "",
                    isDeleted: false
                  };
                  delete dataPayload.sub_documents;
                  await setDoc(mainDocRef, dataPayload);
                  navigate(
                    `/individual/${uid}/${visaType}/documents/standard-documents`
                  );
                }}
              >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* Sub-Document modify Modal */}
        <Modal
          closeOnOverlayClick={false}
          isOpen={isSubEditOpen}
          onClose={onSubEditClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Editing sub-document</ModalHeader>
            <ModalCloseButton
              onClick={() => {
                onSubEditClose();
              }}
            />
            <ModalBody pb={6}>
              <Text>Category</Text>
              <Dropdown
                title="Category"
                selectedValue={subDocument?.type}
                list={visaDocuments[visaType!]}
                onItemSelect={(value) => {
                  setSubDocument((subDoc) =>
                    subDoc ? { ...subDoc, type: value } : null
                  );
                }}
              />
              <Text>Type</Text>
              <Dropdown
                title="Category"
                selectedValue={subDocument?.type}
                list={visaDocuments[visaType!]}
                onItemSelect={(value) => {
                  setSubDocument((subDoc) =>
                    subDoc ? { ...subDoc, type: value } : null
                  );
                }}
              />
              <Divider my={5} />
              <div className="inline-flex gap-2">
                <div className="w-4 h-4 ">
                  <FileExtensionThumbnail doc={subDocument!} />
                </div>
                <span>{subDocument?.documentTitle}</span>
              </div>
              <br />
              <small>you can change the category of the document</small>
            </ModalBody>

            <ModalFooter>
              <Button
                variant="secondaryOutline"
                onClick={() => {
                  onSubEditClose();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primaryFilled"
                ml={3}
                isLoading={isDeleting}
                onClick={async () => {
                  const subDocPath = `${mainDocRef.path.concat(
                    "/sub_documents/",
                    subDocument?.id ?? ""
                  )}`;
                  const { originalDocRef } = await copyDocumentToNewCollection(
                    subDocPath,
                    mainDocRef.parent.path
                  );
                  if (originalDocRef !== null && subDocument) {
                    await deleteDoc(originalDocRef);
                    dispatch(
                      deleteSubDocument({
                        data: {
                          document: subDocument,
                          id: document?.id ?? ""
                        },
                        type: VisaDocumentType.Standard
                      })
                    );
                    setIsDeleting(false);
                    onSubClose();
                  } else {
                    onSubClose();
                  }
                }}
              >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    </Container>
  );
};
