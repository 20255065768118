import {
  DocumentData,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  writeBatch
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { db } from "../api/firebaseApi";
import { DATABASE } from "../types/tables-data";
import { individualSelectors } from "../redux/individual/selectors";

interface ProngsData {
  [key: string]: any;
}

export interface Source {
  id?: string;
  type: string;
  description: string;
  date: string;
  exhibit: number;
  link: string;
}

export type ProngData = {
  areaOfExpertise?: string;
  specialization?: string;
  summary?: string;
  description?: string;
  qualifications?: string;
  pastAchievements?: string;
  impactOnField?: string;
  workUtilization?: string;
  futurePlans?: string;
  laborWaiverExplanation?: string;
};

const useEvidenceProngs = (uid: string) => {
  const email = useSelector(individualSelectors.selectEmail);
  const [prongs, setProngs] = useState<ProngsData | null>(null);
  const [loading, setLoading] = useState(true);
  const [addSourcesLoading, setAddSourcesLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [deleteError, setDeleteError] = useState<any>(null);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!uid) {
      console.log("UID is not defined.");
      return; // Early return if no UID
    }

    // References to the main document and subcollection
    const prongsRef = doc(db, DATABASE.QUESTIONNAIRES, `${uid}`);
    const prong1CollectionRef = collection(
      db,
      DATABASE.QUESTIONNAIRES,
      `${uid}`,
      "prong_1_sources"
    );
    const orderedProng1CollectionRef = query(
      prong1CollectionRef,
      orderBy("exhibit", "asc")
    );

    // Listener for the main document
    const unsubscribeMainDoc = onSnapshot(
      prongsRef,
      (docSnap) => {
        if (docSnap.exists()) {
          const prongsData = docSnap.data() as ProngsData;

          // Listener for the subcollection
          const unsubscribeSubCollection = onSnapshot(
            orderedProng1CollectionRef,
            (prong1QuerySnapshot) => {
              // Initialize an empty array to store subcollection data
              const subCollectionArray: DocumentData[] = [];

              prong1QuerySnapshot.forEach((doc) => {
                const sourceData = doc.data();
                sourceData.id = doc.id; // Add the 'id' property with the Firestore document ID
                subCollectionArray.push(sourceData);
              });

              // Update the 'prongs.prong1.sources' field with subcollection data
              if ("prong1Data" in prongsData)
                prongsData.prong1Data.sources = subCollectionArray;

              // Set the updated data
              setProngs(prongsData);
            },
            (error) => {
              console.error("Error fetching subcollection:", error);
              setError(error as Error);
            }
          );
        }
        console.log("No such document!");
      },
      (error) => {
        console.error("Error fetching the main document:", error);
        setError(error as Error);
      }
    );
  }, [uid]);

  const saveData = async (fieldPath: string, data: any) => {
    setLoading(true);
    const prongsRef = doc(db, fieldPath);

    try {
      await setDoc(prongsRef, data, { merge: true });
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  };

  const updateField = async (fieldPath: string, newValue: any) => {
    const prongsRef = doc(db, "prongsCollection", "yourDocumentId"); // Replace with your document ID

    try {
      await updateDoc(prongsRef, {
        [fieldPath]: newValue,
        last_updated_by: email
      });
    } catch (err) {
      setError(err as Error);
    }
  };

  const addSourcesToProng1 = async (sourceDataArray: any[]) => {
    setAddSourcesLoading(true); // Set loading state to true

    try {
      // Reference to the subcollection
      const prong1CollectionRef = collection(
        db,
        DATABASE.QUESTIONNAIRES,
        uid,
        "prong_1_sources"
      );

      // Create an array of promises for each source addition
      const addPromises = sourceDataArray.map(async (sourceData) => {
        await addDoc(prong1CollectionRef, sourceData);
      });

      // Use Promise.all to execute all additions concurrently
      await Promise.all(addPromises);

      console.log("Sources added successfully!");
    } catch (err) {
      console.error("Error adding sources:", err);
    } finally {
      setAddSourcesLoading(false); // Set loading state to false when done
    }
  };

  const getLargestIndex = async () => {
    try {
      // Define the collection path
      const collectionPath = collection(
        db,
        DATABASE.QUESTIONNAIRES,
        `${uid}`,
        "prong_1_sources"
      );

      // Create a query to fetch the last document based on the index in descending order
      const q = query(collectionPath, orderBy("exhibit", "desc"), limit(1));

      // Execute the query
      const querySnapshot = await getDocs(q);

      // If there are no documents, return 0 (as the starting index)
      if (querySnapshot.empty) {
        return 0;
      }

      // Extract the index from the first document (which has the highest index)
      const document = querySnapshot.docs[0];
      const data = document.data();

      return data.exhibit;
    } catch (error) {
      console.error("Error fetching the largest index:", error);
      throw error; // Throw error to be handled by the caller
    }
  };

  const deleteSource = async (sourceId: string) => {
    setDeleteLoading(true);
    const collectionPath = collection(
      db,
      DATABASE.QUESTIONNAIRES,
      `${uid}`,
      "prong_1_sources"
    );

    try {
      // Delete the specified source document first
      const sourceToDeleteRef = doc(
        db,
        DATABASE.QUESTIONNAIRES,
        `${uid}`,
        "prong_1_sources",
        `${sourceId}`
      );
      await deleteDoc(sourceToDeleteRef);

      // Start a batched write to update the remaining source documents
      const batch = writeBatch(db);

      // Get all remaining source documents after deletion
      const sourcesRef = collection(
        db,
        DATABASE.QUESTIONNAIRES,
        `${uid}`,
        "prong_1_sources"
      );
      const queryRef = query(sourcesRef, orderBy("exhibit", "asc"));
      const querySnapshot = await getDocs(queryRef);

      // Adjust exhibits and prepare updates
      let exhibitCounter = 1; // Start exhibits at 1
      querySnapshot.forEach((docSnapshot) => {
        if (docSnapshot.exists()) {
          const docRef = doc(
            db,
            DATABASE.QUESTIONNAIRES,
            `${uid}`,
            "prong_1_sources",
            docSnapshot.id
          );
          batch.update(docRef, { exhibit: exhibitCounter });
          exhibitCounter += 1;
        }
      });

      // Commit the batched writes to update the source documents
      await batch.commit();

      // Update any additional data or perform other actions as needed
    } catch (e) {
      console.error(
        "Error deleting source document and updating exhibits: ",
        e
      );
    } finally {
      setDeleteLoading(false);
    }
  };

  return {
    deleteLoading,
    deleteError,
    deleteSource,
    getLargestIndex,
    addSourcesToProng1,
    addSourcesLoading,
    prongs,
    saveData,
    updateField,
    loading,
    error
  };
};

export default useEvidenceProngs;
