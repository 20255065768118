import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormLabel,
  Icon,
  Input,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  SkeletonText,
  Stack,
  Text,
  Textarea,
  Tooltip,
  useToast
} from "@chakra-ui/react";
import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { db } from "../../../api/firebaseApi";
import useFirestoreDocument from "../../../hooks/useFirestoreDocument";
import useVisaQuestions, { Question } from "../../../hooks/useVisaQuestions";
import { individualSelectors } from "../../../redux/individual/selectors";
import { DATABASE, VISAVALUE } from "../../../types/tables-data";
import { SaveAnswersDialog } from "./evidenceQuestions/SaveAnswersDialog";

interface FormData {
  [key: string]: any;
}

interface Props {
  visaType: string;
  isAutoGenerating?: boolean;
  aiExtractionMsg?: JSX.Element;
}

export const VisaQuestionsForm: React.FC<Props> = ({
  visaType,
  isAutoGenerating = false,
  aiExtractionMsg
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const uid = id ?? useSelector(individualSelectors.selectUid);
  const { questions, loading, error, save } = useVisaQuestions(visaType, uid);
  const toast = useToast();
  const [formData, setFormData] = useState<FormData>({});
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [petitionerType, setPetitionerType] = useState<string>("");
  const [peerGroup, setPeerGroup] = useState<string>("");
  const [errors, setErrors] = useState<FormData>({});
  const [questionnaireData, setQuestionnaireData] = useState<FormData>({});

  const refs = useRef<{
    [key: string]:
      | HTMLInputElement
      | HTMLTextAreaElement
      | HTMLSelectElement
      | null;
  }>({});

  const handleInputChange = (variableName: string, value: any) => {
    setFormData({ ...formData, [variableName]: value });
    if (variableName === "petitionerType") {
      setPetitionerType(value);
    }
    if (variableName === "peerGroup") {
      setPeerGroup(value);
    }
  };

  // const {
  //   document: questionnaireData1,
  //   loading: fetchLoading,
  //   error: fetchError,
  //   fetchDocument: fetchData
  // } = useFirestoreDocument<FormData>(DATABASE.QUESTIONNAIRES, uid, true);

  useEffect(() => {
    if (!uid) return; // Early return if uid is not provided

    const docRef = doc(db, DATABASE.QUESTIONNAIRES, `${uid}`);
    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          setQuestionnaireData(data); // Dispatch action to set the fetched data
        } else {
          console.log("No such document!");
        }
      },
      (error) => {
        console.error("Error fetching the document: ", error);
      }
    );

    // // Cleanup function to unsubscribe on unmount
    // return () => unsubscribe();
  }, [uid, dispatch]);

  useEffect(() => {
    if (Object.keys(questionnaireData).length > 0) {
      setFormData(questionnaireData);
      // Initialize petitionerType and peerGroup from fetched data if present
      setPetitionerType(questionnaireData.petitionerType || "");
      setPeerGroup(questionnaireData.peerGroup || "");
    } else if (questions.length > 0) {
      const defaultFormData = questions.reduce<FormData>((acc, question) => {
        acc[question.variableName] =
          question.fieldType === "checkbox" ? [] : "";
        return acc;
      }, {});
      setFormData(defaultFormData);
    }
  }, [questionnaireData, questions]);

  const isFieldVisible = (question: Question) => {
    if (
      (visaType === VISAVALUE.EB1A &&
        question.variableName === "employerName" &&
        petitionerType !== "Employer") ||
      (question.variableName === "peerGroupName" &&
        peerGroup.toLocaleLowerCase() === "no")
    ) {
      return false;
    }
    if (
      visaType === VISAVALUE.O1B &&
      question.variableName === "laborOrganizationName" &&
      peerGroup.toLocaleLowerCase() !== "yes"
    ) {
      return false;
    }
    return true;
  };

  const scrollToElement = (key: never) => {
    const element = refs.current[key];
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        window.scrollBy(0, -140);
      }, 300);
    }
  };

  const validateForm = () => {
    const newErrors: FormData = {};
    let firstErrorKey: string | null = null;

    questions.forEach((question) => {
      if (
        question.isRequired &&
        isFieldVisible(question) &&
        !formData[question.variableName]
      ) {
        newErrors[question.variableName] = "This field is required";
        if (!firstErrorKey) {
          firstErrorKey = question.variableName;
        }
      }
    });

    setErrors(newErrors);

    if (firstErrorKey && refs.current[firstErrorKey]) {
      // refs.current[firstErrorKey]?.scrollIntoView({ behavior: "smooth" });
      scrollToElement(firstErrorKey);
    }

    return Object.keys(newErrors).length === 0;
  };

  const renderQuestionInput = (question: Question) => {
    const {
      fieldType,
      variableName,
      questionText,
      options,
      isRequired,
      tooltipText
    } = question;

    if (!isFieldVisible(question)) {
      return null;
    }

    const setRef = (
      el: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | null
    ) => {
      refs.current[variableName] = el;
    };

    return (
      <Box key={variableName} mb="4">
        <FormLabel mb={2} fontWeight={700}>
          {questionText}
          {tooltipText && (
            <Tooltip label={tooltipText} aria-label="Question tooltip">
              <Icon as={InfoOutlineIcon} ml={2} cursor="pointer" />
            </Tooltip>
          )}
        </FormLabel>
        {(() => {
          switch (fieldType) {
            case "text":
              return !isAutoGenerating ? (
                <Input
                  placeholder=""
                  value={formData[variableName] || ""}
                  onChange={(e) =>
                    handleInputChange(variableName, e.target.value)
                  }
                  mb={4}
                  isRequired={isRequired}
                  isInvalid={!!errors[variableName]}
                  ref={setRef}
                />
              ) : (
                <Skeleton height="40px" mb={4} />
              );
            case "textarea":
              return !isAutoGenerating ? (
                <Textarea
                  placeholder=""
                  value={formData[variableName] || ""}
                  onChange={(e) =>
                    handleInputChange(variableName, e.target.value)
                  }
                  mb={10}
                  isRequired={isRequired}
                  isInvalid={!!errors[variableName]}
                  ref={setRef}
                />
              ) : (
                <SkeletonText />
              );

            case "radio":
              return !isAutoGenerating ? (
                <RadioGroup
                  value={formData[variableName] || ""}
                  onChange={(value) => handleInputChange(variableName, value)}
                  mb={10}
                  ref={setRef as unknown as React.RefObject<HTMLDivElement>}
                >
                  <Stack direction="column">
                    {options?.map((option, index) => {
                      const value =
                        typeof option === "string" ? option : option.value;
                      const optionText =
                        typeof option === "string" ? option : option.optionText;
                      const optionTooltip =
                        typeof option === "string"
                          ? undefined
                          : option.tooltipText;

                      return (
                        <Box key={index} display="flex" alignItems="center">
                          <Radio value={value}>{optionText}</Radio>
                          {optionTooltip && (
                            <Tooltip
                              label={optionTooltip}
                              aria-label="Option tooltip"
                            >
                              <Icon
                                as={InfoOutlineIcon}
                                ml={2}
                                cursor="pointer"
                              />
                            </Tooltip>
                          )}
                        </Box>
                      );
                    })}
                  </Stack>
                </RadioGroup>
              ) : (
                <Skeleton height="40px" mb={10} />
              );
            case "checkbox":
              return !isAutoGenerating ? (
                <CheckboxGroup
                  value={formData[variableName] || []}
                  onChange={(values) => handleInputChange(variableName, values)}
                >
                  <Stack direction="column">
                    {options?.map((option, index) => {
                      const value =
                        typeof option === "string" ? option : option.value;
                      const optionText =
                        typeof option === "string" ? option : option.optionText;
                      const optionTooltip =
                        typeof option === "string"
                          ? undefined
                          : option.tooltipText;

                      return (
                        <Box key={index} display="flex" alignItems="center">
                          <Checkbox
                            value={value}
                            ref={index === 0 ? setRef : undefined}
                          >
                            {optionText}
                          </Checkbox>
                          {optionTooltip && (
                            <Tooltip
                              label={optionTooltip}
                              aria-label="Option tooltip"
                            >
                              <Icon
                                as={InfoOutlineIcon}
                                ml={2}
                                cursor="pointer"
                              />
                            </Tooltip>
                          )}
                        </Box>
                      );
                    })}
                  </Stack>
                </CheckboxGroup>
              ) : (
                <Skeleton height="40px" mb={10} />
              );
            case "select":
              return !isAutoGenerating ? (
                <Select
                  placeholder=""
                  value={formData[variableName] || ""}
                  onChange={(e) =>
                    handleInputChange(variableName, e.target.value)
                  }
                  mb={10}
                  isRequired={isRequired}
                  isInvalid={!!errors[variableName]}
                  ref={setRef}
                >
                  {options?.map((option, index) => {
                    const value =
                      typeof option === "string" ? option : option.value;
                    const optionText =
                      typeof option === "string" ? option : option.optionText;
                    return (
                      <option key={index} value={value}>
                        {optionText}
                      </option>
                    );
                  })}
                </Select>
              ) : (
                <Skeleton height="40px" mb={10} />
              );
            default:
              return null;
          }
        })()}
        {errors[variableName] && (
          <Text color="red.500" fontSize="sm">
            {errors[variableName]}
          </Text>
        )}
      </Box>
    );
  };

  const handleSaveClick = () => {
    if (!validateForm()) {
      toast({
        title: "Validation Error",
        description: "Please fill all required fields",
        status: "error",
        duration: 3000,
        isClosable: true
      });
      setShowConfirmation(false);
      return;
    }

    setShowConfirmation(true);
  };

  const handleSubmit = async () => {
    try {
      setShowConfirmation(false);
      await save(formData);
      // await fetchData();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box p={10}>
      {loading && <Text>Loading questions...</Text>}
      {error && <Text color="red.500">Error: {error.message}</Text>}
      {isAutoGenerating && aiExtractionMsg && (
        <Box mb={4}>{aiExtractionMsg}</Box>
      )}
      {!loading &&
        questions.map((question: Question) => renderQuestionInput(question))}
      <Flex justifyContent="center" my="6">
        <Button
          onClick={handleSaveClick}
          isDisabled={isAutoGenerating}
          my={4}
          variant="primaryFilled"
          mx="15px"
          type="button"
        >
          Save
        </Button>
      </Flex>
      <SaveAnswersDialog
        isOpen={showConfirmation}
        onConfirm={handleSubmit}
        onClose={() => setShowConfirmation(false)}
      />
    </Box>
  );
};
