import { LawyerReducer } from "./lawyerSlice";

const selectAll = (state: LawyerReducer) => state.lawyer;

const selectFirstName = (state: LawyerReducer) => selectAll(state).firstName;
const selectLastName = (state: LawyerReducer) => selectAll(state).lastName;
const selectPronouns = (state: LawyerReducer) => selectAll(state).pronouns;
const selectTitle = (state: LawyerReducer) => selectAll(state).title;
const selectEmail = (state: LawyerReducer) => selectAll(state).email;
const selectAddress = (state: LawyerReducer) => selectAll(state).address;
const selectPracticeName = (state: LawyerReducer) =>
  selectAll(state).practiceName;
const selectWebsite = (state: LawyerReducer) => selectAll(state).website;
const selectAvatarSrc = (state: LawyerReducer) => selectAll(state).avatarSrc;
const selectAvatarName = (state: LawyerReducer) => selectAll(state).avatarName;
const selectUid = (state: LawyerReducer) => selectAll(state).uid;
const selectRole = (state: LawyerReducer) => selectAll(state).role;
const selectHasAcceptedTerms = (state: LawyerReducer) => selectAll(state).hasAcceptedTerms;
const selectHideQualityReview = (state: LawyerReducer) => selectAll(state).hideQualityReview;

export const lawyerSelectors = {
  selectAll,
  selectFirstName,
  selectLastName,
  selectPronouns,
  selectTitle,
  selectEmail,
  selectAddress,
  selectPracticeName,
  selectWebsite,
  selectAvatarSrc,
  selectAvatarName,
  selectUid,
  selectRole,
  selectHasAcceptedTerms,
  selectHideQualityReview
};
