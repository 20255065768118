import { extendTheme } from "@chakra-ui/react";
import { StepsTheme as Steps } from "chakra-ui-steps";
import { buttonTheme } from "./styles/chakra/button.style";
import { containerTheme } from "./styles/chakra/container.style";
import { formTheme } from "./styles/chakra/form.style";
import { headingTheme } from "./styles/chakra/heading.style";
import { inputTheme } from "./styles/chakra/input.style";
import { selectTheme } from "./styles/chakra/select.style";
import { tablesTheme } from "./styles/chakra/table.style";
import { tabsTheme } from "./styles/chakra/tabs.style";
import { textAreaTheme } from "./styles/chakra/textarea.style";
import { menuTheme } from "./styles/chakra/menu.style";
import { dividerTheme } from "./styles/chakra/divider.style";
import { checkboxTheme } from "./styles/chakra/checkbox.style";
import { accordionTheme } from "./styles/chakra/accordion.style";

const theme = extendTheme({
  fontSizes: {
    sm: "14px",
    md: "16px",
    lg: "18px"
  },
  lineHeights: {
    body: "1.45"
  },
  styles: {
    global: (props: any) => ({
      "html, body": {
        bg: "primary.backgroundLight",
        fontFamily: "Inter",
        overflow: "hidden"
      }
    })
  },
  colors: {
    primary: {
      blue: "#00008B",
      red: "#D30638",
      backgroundLight: "#EEF2F6",
      backgroundDark: "#1A202C",
      lightGray: "#B8C0C9",
      focusOutline: "#DFF1FB",
      hoverOpacity: "rgba(0, 0, 0, 0.12)"
    },
    accent: {
      emerald: "#307F8D",
      lapis: "#4A6ABB"
    },
    errors: {
      red1: "#E53E3E",
      fireEngineRed: "#B80003",
      red2: "#FFE5E5"
    },
    background: {
      white: "#FFFFFF",
      darkWhite: "#2D3748",
      cloud: "#F5F8FF",
      solitude: "#EEF2F6",
      misty: "#E2E8F0"
    },
    stroke: {
      stroke1Light: "#C1C1C9",
      stroke1Dark: "#4A5568",
      stroke2: "#ECECEC",
      stroke3: "#E2E8F0"
    },
    text: {
      color: "#00004D",
      display: {
        light: "#00004D", // Light mode display color (Navy)
        dark: "#F7FAFC" // Dark mode display color
      },
      grayPrimary: {
        charcoal: "#111928" // Gray primary (Charcoal)
      },
      graySecondary: {
        smog: "#757575" // Gray secondary (Smog)
      }
    }
  },
  components: {
    CheckBox: checkboxTheme,
    Menu: menuTheme,
    Container: containerTheme,
    Textarea: textAreaTheme,
    Tabs: tabsTheme,
    Table: tablesTheme,
    Button: buttonTheme,
    Steps,
    Input: inputTheme,
    Select: selectTheme,
    Form: formTheme,
    Heading: headingTheme,
    Divider: dividerTheme,
    Accordion: accordionTheme
    // IconButton: iconButtonTheme
  }
});

export default theme;
