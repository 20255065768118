import {
  Box,
  Button,
  Divider,
  Flex,
  FormLabel,
  Input,
  Text,
  Textarea
} from "@chakra-ui/react";
import { doc, updateDoc } from "firebase/firestore";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { db } from "../../../api/firebaseApi";
import {
  getDocumentCategories,
  getDocumentCategoryTypes,
  getDocumentExhibitById,
  mapVisaTypeToPath
} from "../../../helpers/helpers";
import { useGetSubDocuments } from "../../../hooks/useGetSubDocuments";
import { updateDocument } from "../../../redux/documents/documentSlice";
import { documentSelectors } from "../../../redux/documents/selectors";
import { VisaDocumentType } from "../../../redux/documents/types";
import { ToastManager } from "../../../services/toast/toastManager";
import { DATABASE, DataDocs } from "../../../types/tables-data";
import { Dropdown } from "../../common/Dropdown";

interface Props {
  document: DataDocs | DataDocs | undefined;
  visaDocumentType: VisaDocumentType;
}

const DocumentInformation: React.FC<Props> = ({
  document,
  visaDocumentType
}) => {
  if (document === undefined) return null;
  const { visaType } = useParams();
  const documentTypes = useSelector(documentSelectors.documentTypes);
  const mappedVisaType = mapVisaTypeToPath(visaType!);
  const [staleDocument, setStaleDocument] = useState(document);
  const [currentDocument, setCurrentDocument] = useState<DataDocs | undefined>(
    document
  );
  const dispatch = useDispatch();
  const [error, setError] = useState<string | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const documentChanged = !_.isEqual(staleDocument, currentDocument);

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      if (documentChanged) {
        e.preventDefault();
        return true;
      }
      return false;
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [documentChanged]);

  const validate = (): boolean => {
    if (currentDocument?.type === undefined) {
      setError("Document type is required");
      return false;
    }
    setError(undefined);
    return true;
  };
  useEffect(() => {
    validate();
  }, [currentDocument]);

  const handleInputChange = (key: any, value: any) => {
    setCurrentDocument((prevState: any) => {
      // Otherwise, spread the previous state and update the specific key-value pair
      return {
        ...prevState,
        [key]: value,
        // resetting type dropdown
        ...(key === "criterion" && {
          type: undefined
        })
      };
    });
  };

  const handleSubmit = async () => {
    if (!currentDocument?.docRef || !currentDocument || validate() === false) {
      return;
    }

    setIsSubmitting(true);

    const dataPayload: DataDocs = {
      ...currentDocument,
      uploadBy: "",
      uploadDate: Date.now(),
      documentTitle: currentDocument?.documentTitle ?? ""
    };

    const docRef = doc(db, currentDocument.docRef);
    // await setDoc(docRef, dataPayload);
    await updateDoc(docRef, dataPayload);
    dispatch(updateDocument({ type: visaDocumentType, data: dataPayload }));
    setIsSubmitting(false);
    ToastManager.getInstance().showToast(
      DATABASE.DOCUMENTS,
      "success",
      "updated"
    );
  };

  useGetSubDocuments(currentDocument!, visaDocumentType);

  return (
    <>
      <Flex flexDirection="column" gap={4}>
        {visaDocumentType !== VisaDocumentType.SignedExpertLetters && (
          <Box>
            <FormLabel>Category</FormLabel>
            <Dropdown
              selectedValue={currentDocument?.criterion}
              title="Category"
              onItemSelect={(value) => handleInputChange("criterion", value)}
              list={getDocumentCategories(
                documentTypes,
                visaDocumentType,
                mappedVisaType
              )}
            />
          </Box>
        )}

        <Box>
          <FormLabel>Document Type</FormLabel>
          <Dropdown
            selectedValue={currentDocument?.type}
            title="Type"
            onItemSelect={(value) => {
              handleInputChange("type", value);
            }}
            list={getDocumentCategoryTypes(
              documentTypes,
              visaDocumentType,
              mappedVisaType,
              currentDocument?.criterion ?? ""
            )}
          />
        </Box>
        {currentDocument?.type === "Other" && (
          <Input
            placeholder="Document title"
            value={currentDocument?.documentTitle ?? ""}
            onChange={(e) => {
              handleInputChange("documentTitle", e.target.value);
            }}
          />
        )}
        {error && <Text color="red">{error}</Text>}
        <Box flexDirection="column">
          <FormLabel htmlFor="description">Additional Description</FormLabel>
          <Textarea
            id="description"
            size="lg"
            resize="vertical"
            placeholder="Please explain why this document is relevant and how it showcases the applicant's accomplishments."
            value={currentDocument?.description ?? ""}
            onChange={(e) => handleInputChange("description", e.target.value)}
          />
        </Box>
        {currentDocument?.autoTitle !== undefined && (
          <Box>
            <FormLabel htmlFor="ai-title">Document Title</FormLabel>
            <Textarea
              size="lg"
              resize="vertical"
              value={currentDocument?.autoTitle ?? ""}
              onChange={(e) => handleInputChange("autoTitle", e.target.value)}
              name="ai-title"
              id="ai-title"
            />
          </Box>
        )}
      </Flex>

      <Divider my={4} visibility="hidden" />

      <Box display="flex" justifyContent="end" gap={2}>
        <Button
          variant="secondaryOutline"
          onClick={() => {
            setCurrentDocument({ ...staleDocument });
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primaryFilled"
          isLoading={isSubmitting}
          onClick={handleSubmit}
          isDisabled={!documentChanged}
        >
          Save
        </Button>
      </Box>
    </>
  );
};

export default DocumentInformation;
