import { defineStyle } from "@chakra-ui/react";


export const dividerTheme = defineStyle({
  baseStyle: {
    borderColor: "stroke.stroke1Light", // Using the light gray color for the divider
    opacity: 0.5, // Ensure full opacity for a visible divider
  },
  sizes: {
    sm: {
      borderWidth: "1px", // Small divider
    },
    md: {
      borderWidth: "2px", // Medium divider
    },
    lg: {
      borderWidth: "3px", // Large divider
    },
  },
  variants: {
    solid: {
      borderStyle: "solid", // Solid divider style
    },
    dashed: {
      borderStyle: "dashed", // Dashed line style for the divider
    },
  },
  defaultProps: {
    size: "sm", // Default size of the divider
    variant: "solid", // Default variant as solid
  },
});
